import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LockedIcon, VisionrLogo, CloseIcon } from 'src/assets/icons';
import { ENV } from 'src/utils/env';
import {
  StyledWrapper,
  StyledSpinner,
  StyledIcon,
  StyledText,
  StyledEmblem,
  StyledUnauthenticatedIcon,
} from './AuthenticatingState.styled';

export const AuthenticatingState: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <StyledIcon>
        <LockedIcon />
      </StyledIcon>
      <StyledSpinner>&nbsp;</StyledSpinner>

      <StyledText>
        {t('uiLabel.authenticating')}
        <br /> {t('uiLabel.authenticating.description')}
      </StyledText>
      <StyledEmblem>
        <VisionrLogo />
      </StyledEmblem>
    </StyledWrapper>
  );
};

export const UnAuthenticatedState: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [count, setCount] = React.useState(3);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // handle redirect on countdown completion
  React.useEffect(() => {
    if (count === 0) {
      // TODO: ask backend to redirect
      window.location.href = `${ENV.API_URL}/admin/login/?next=https://dev.management.consensusadvertising.com/`;
    }
  }, [count]);

  return (
    <StyledWrapper>
      <StyledUnauthenticatedIcon>
        <CloseIcon />
      </StyledUnauthenticatedIcon>
      <StyledText>
        {t('uiLabel.unAuthenticated')} <br />{' '}
        {t('uiLabel.unAuthenticated.redirecting', {
          seconds: count,
        })}
      </StyledText>
      <StyledEmblem>
        <VisionrLogo />
      </StyledEmblem>
    </StyledWrapper>
  );
};
