import styled from 'styled-components';
import { CUSTOM_TYPOGRAPHY_CSS, type CustomTypographyProps } from './custom-typography';

export const H2 = styled.h2<CustomTypographyProps>`
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;

  ${CUSTOM_TYPOGRAPHY_CSS};
`;
