import ky from 'ky';

import { z } from 'zod';
import { ENV } from 'src/utils/env';

const getZoneLastUpdatedMetaParamSchema = z.object({
  data: z.object({
    detection: z
      .object({
        last_updated: z.string(),
        last_updated_by: z.string(),
      })
      .optional(),
    presence: z
      .object({
        last_updated: z.string(),
        last_updated_by: z.string(),
      })
      .optional(),
  }),

  error: z.string().optional().nullable(),
});

export const getZoneLastUpdatedMetaPromise = async (display_id: string) => {
  const res = await ky
    .post(`${ENV.FRONTEND_API_URL}/zone_last_updated_meta`, {
      body: display_id,
    })
    .json();

  const result = getZoneLastUpdatedMetaParamSchema.safeParse(res);

  if (!result.success) {
    return {};
  }

  return result.data?.data;
};
