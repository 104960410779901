import * as React from 'react';

export interface VisionrEmblemProps {
  size?: number;
}
export const VisionrEmblem: React.FunctionComponent<VisionrEmblemProps> = ({ size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9624 6.43133L6.42881 39.978C2.81725 43.591 2.81725 49.4488 6.42882 53.0618L39.9624 86.6085C43.574 90.2215 49.4295 90.2215 53.0411 86.6085L86.5747 53.0618C90.1863 49.4488 90.1863 43.591 86.5747 39.978L53.0411 6.43133C49.4295 2.81835 43.574 2.81835 39.9624 6.43133ZM3.79828 37.3465C-1.2661 42.4128 -1.26609 50.627 3.79828 55.6934L37.3319 89.2401C42.3963 94.3064 50.6073 94.3064 55.6716 89.2401L89.2052 55.6934C94.2696 50.627 94.2696 42.4128 89.2052 37.3465L55.6716 3.79976C50.6072 -1.26659 42.3963 -1.26659 37.3319 3.79977L3.79828 37.3465Z"
        fill="url(#linear_510_541)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1992 53.033C40.8684 53.033 40.5625 53.2087 40.3958 53.4946L36.0557 60.9378C35.8867 61.2275 35.8867 61.5857 36.0557 61.8754L40.3958 69.3186C40.5625 69.6044 40.8684 69.7802 41.1992 69.7802H49.9462C50.277 69.7802 50.5829 69.6044 50.7495 69.3186L55.0897 61.8754C55.2586 61.5857 55.2586 61.2275 55.0897 60.9378L50.7495 53.4946C50.5829 53.2087 50.277 53.033 49.9462 53.033H41.1992ZM52.3521 50.7034C52.1854 50.4175 51.8795 50.2418 51.5487 50.2418H39.5966C39.2658 50.2418 38.9599 50.4175 38.7933 50.7034L32.8255 60.9378C32.6566 61.2275 32.6566 61.5857 32.8255 61.8754L38.7933 72.1097C38.9599 72.3956 39.2658 72.5713 39.5966 72.5713H51.5487C51.8795 72.5713 52.1854 72.3956 52.3521 72.1097L58.3198 61.8754C58.4887 61.5857 58.4887 61.2275 58.3198 60.9378L52.3521 50.7034Z"
        fill="url(#linear_510_541)"
      />
      <path
        d="M35.3423 60.5692C35.3423 60.0554 34.9259 59.6388 34.4123 59.6388H13.0214C11.9942 59.6388 11.1614 60.4719 11.1614 61.4996V62.0578C11.1614 62.5717 11.5778 62.9882 12.0914 62.9882H34.4123C34.9259 62.9882 35.3423 62.5717 35.3423 62.0578V60.5692Z"
        fill="url(#linear_510_541)"
      />
      <path
        d="M56.7331 30.9826C56.7331 30.4687 57.1495 30.0522 57.6631 30.0522H79.0539C80.0812 30.0522 80.914 30.8853 80.914 31.913V32.4712C80.914 32.9851 80.4976 33.4016 79.984 33.4016H57.6631C57.1495 33.4016 56.7331 32.9851 56.7331 32.4712V30.9826Z"
        fill="url(#linear_510_541)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1992 23.2602C40.8684 23.2602 40.5625 23.436 40.3958 23.7218L36.0557 31.165C35.8867 31.4547 35.8867 31.8129 36.0557 32.1026L40.3958 39.5458C40.5625 39.8316 40.8684 40.0074 41.1992 40.0074H49.9462C50.277 40.0074 50.5829 39.8316 50.7495 39.5458L55.0897 32.1026C55.2586 31.8129 55.2586 31.4547 55.0897 31.165L50.7495 23.7218C50.5829 23.436 50.277 23.2602 49.9462 23.2602H41.1992ZM52.3521 20.9306C52.1854 20.6448 51.8795 20.469 51.5487 20.469H39.5966C39.2658 20.469 38.9599 20.6448 38.7933 20.9306L32.8255 31.165C32.6566 31.4547 32.6566 31.8129 32.8255 32.1026L38.7933 42.337C38.9599 42.6228 39.2658 42.7986 39.5966 42.7986H51.5487C51.8795 42.7986 52.1854 42.6228 52.3521 42.337L58.3198 32.1026C58.4887 31.8129 58.4887 31.4547 58.3198 31.165L52.3521 20.9306Z"
        fill="url(#linear_510_541)"
      />
      <path
        d="M33.4822 45.7759C33.4822 45.262 33.8986 44.8455 34.4123 44.8455H56.7331C57.2467 44.8455 57.6631 45.262 57.6631 45.7759V47.2645C57.6631 47.7784 57.2467 48.1949 56.7331 48.1949H34.4123C33.8986 48.1949 33.4822 47.7784 33.4822 47.2645V45.7759Z"
        fill="url(#linear_510_541)"
      />
      <path
        d="M60.1875 38.6252C60.3515 38.3298 60.6627 38.1466 61.0005 38.1466H69.2063C69.5441 38.1466 69.8553 38.3298 70.0194 38.6252L74.1528 46.0683C74.3089 46.3493 74.3089 46.691 74.1528 46.972L70.0194 54.4152C69.8553 54.7106 69.5441 54.8938 69.2063 54.8938H61.0005C60.6627 54.8938 60.3515 54.7106 60.1875 54.4152L56.054 46.972C55.8979 46.691 55.8979 46.3493 56.054 46.0683L60.1875 38.6252Z"
        fill="url(#linear_510_541)"
      />
      <path
        d="M21.126 38.6252C21.29 38.3298 21.6012 38.1466 21.939 38.1466H30.1449C30.4826 38.1466 30.7938 38.3298 30.9579 38.6252L35.0914 46.0683C35.2474 46.3493 35.2474 46.691 35.0914 46.972L30.9579 54.4152C30.7938 54.7106 30.4826 54.8938 30.1449 54.8938H21.939C21.6012 54.8938 21.29 54.7106 21.126 54.4152L16.9925 46.972C16.8365 46.691 16.8365 46.3493 16.9925 46.0683L21.126 38.6252Z"
        fill="url(#linear_510_541)"
      />
      <defs>
        <linearGradient
          id="linear_510_541"
          x1="46.5018"
          y1="0"
          x2="46.5018"
          y2="93.0398"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BC380" />
          <stop offset="1" stopColor="#39C747" />
        </linearGradient>
      </defs>
    </svg>
  );
};

VisionrEmblem.defaultProps = {
  size: 94,
};
