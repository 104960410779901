import ky from 'ky';
import { z } from 'zod';

export const REQUEST_TYPE_MAP = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
} as const;

export type RequestMethodTypes = (typeof REQUEST_TYPE_MAP)[keyof typeof REQUEST_TYPE_MAP];

export const requestTypeSchema = z.enum([
  REQUEST_TYPE_MAP.GET,
  REQUEST_TYPE_MAP.POST,
  REQUEST_TYPE_MAP.PATCH,
  REQUEST_TYPE_MAP.PUT,
]);

/** Used to return the correct requestFn when dealing with an endpoint which can accept multiple request types */
export const getRequestTypeFn = (requestType: RequestMethodTypes) => {
  switch (requestType) {
    case REQUEST_TYPE_MAP.GET:
      return ky.get;
    case REQUEST_TYPE_MAP.POST:
      return ky.post;
    case REQUEST_TYPE_MAP.PATCH:
      return ky.patch;
    case REQUEST_TYPE_MAP.PUT:
      return ky.put;
    default:
      return ky.get;
  }
};
