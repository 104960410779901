import { Theme } from './theme-types';

export type Colors = Theme['colors'];

export const COLORS: Colors = {
  primary: '#22B96B',
  primaryHover: '#5bc380',
  primaryDark: '#1EA660',

  textColor: '#131517',
  inputBorderColor: '#cccccc',
  borderColor: 'rgba(145, 158, 171, 0.15)',

  black: '#0c1228',
  white: '#FFFFFF',

  redwood: '#A23E48',

  warning: '#ED6C02',
  red: '#FF0800',

  transparent: 'rgba(0, 0, 0, 0)',

  appBackground: '#fff',
  pageContentColor: '#fafafa',

  backdropColor: 'rgba(145,158,171,0.7)',

  blue: '#4997D0',
  pink: '#FF91AF',
  beaver: '#9F8170',

  grey1: '#919EAB',
  grey2: '#5F6388',
  grey3: '#efefef',
  grey4: '#fbfbfb',
} as const;

// dark theme colors
export const COLORS_DARK = {
  appBackground: '#1E1F25',
  pageContentColor: '#131517',
  textColor: '#fff',
  inputBorderColor: '#919EAB',
  filterIconColor: '#0c1228',
  grey1: '#919EAB',
  grey2: '#758495',
  grey3: '#354556',
  grey4: '#fbfbfb',
  borderColor: '#354556',
  backdropColor: 'rgba(0,0,0,0.6)',
} as const;
