import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'src/constants/routes';
import { useGlobalStore } from 'src/hooks/use-app-wide-store';
import { PAGES_SHORTCUTS } from '../pages-shortcut';
import { SYSTEMS_SHORTCUTS_MAP, SYSTEMS_SHORTCUTS_LABEL_MAP } from '../systems-shortcuts';

export const useCommandMenu = () => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toggleDarkMode = useGlobalStore((state) => state.toggleDarkMode);

  const onPageSelect = React.useCallback(
    (value: string) => {
      const route = ROUTES.find((r) => t(r.label)?.toLocaleLowerCase() === value);

      if (route) {
        setOpen(false);
        navigate(route.path);
      }
    },
    [navigate, setOpen, t]
  );

  const handleSystemShortcut = React.useCallback(
    (key: string) => {
      switch (key) {
        case SYSTEMS_SHORTCUTS_MAP.TOGGLE_THEME:
          toggleDarkMode();
          break;
        default:
          break;
      }
    },
    [toggleDarkMode]
  );

  const onSystemsShortcutSelect = React.useCallback(
    (value: string) => {
      const id =
        Object.entries(SYSTEMS_SHORTCUTS_LABEL_MAP).find(
          ([, _value]) => t(_value)?.toLowerCase() === value?.toLowerCase()
        )?.[0] || '';

      const key = SYSTEMS_SHORTCUTS_MAP[id as keyof typeof SYSTEMS_SHORTCUTS_MAP];

      handleSystemShortcut(key);
    },
    [handleSystemShortcut, t]
  );

  // Toggle the menu when ⌘K is pressed
  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const route = Object.entries(PAGES_SHORTCUTS).find(([, value]) => value === e.key?.toLowerCase())?.[0] || '';

      switch (true) {
        // Open the menu when ⌘K is pressed
        case e.key?.toLowerCase() === 'k' && (e.metaKey || e.ctrlKey):
          e.preventDefault();
          setOpen((prevState) => !prevState);
          break;

        // Close the menu when escape is pressed
        case e.key?.toLowerCase() === 'escape':
          e.preventDefault();
          setOpen(false);
          break;

        // Focus on search input if it exists when ⌘F is pressed
        case e.key?.toLowerCase() === 'f' &&
          (e.metaKey || e.ctrlKey) &&
          Boolean(document.getElementById('search-filter')):
          e.preventDefault();
          document.getElementById('search-filter')?.focus?.();
          break;

        // Navigate to the selected page when the shiftKey is pressed with a key
        case e.shiftKey && (e.metaKey || e.ctrlKey) && Boolean(e.key) && e.key?.toLowerCase() !== 'shift':
          if (route) {
            e.preventDefault();
            setOpen(false);
            navigate(route);
          } else if (e.key) {
            e.preventDefault();
            setOpen(false);
            handleSystemShortcut(e.key?.toLowerCase());
          }
          break;

        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [navigate, setOpen, handleSystemShortcut]);

  return {
    open,
    setOpen,
    t,
    onPageSelect,
    onSystemsShortcutSelect,
  };
};
