import { useSnackbar, OptionsObject } from 'notistack';
import { SNACKBAR_TYPES } from 'src/constants/snackbar';

export type NotificationFunction = (message: string, options?: OptionsObject) => void;

export interface UseSnackbarResult {
  enqueueSuccessNotification: NotificationFunction;
  enqueueErrorNotification: NotificationFunction;
  enqueueInfoNotification: NotificationFunction;
  enqueueWarningNotification: NotificationFunction;
}

/** useNotification hook to show snackbars - returns a function for each snackbar types - default | error | info | success | warning */
export const useNotification = (): UseSnackbarResult => {
  const { enqueueSnackbar } = useSnackbar();

  // builder to create snackbar for each type
  const enqueueNotification = (variant: keyof typeof SNACKBAR_TYPES) => (message: string, options?: OptionsObject) => {
    enqueueSnackbar(message, {
      ...(options || {}),
      variant,
    });
  };

  /** Show success snackbar  */
  const enqueueSuccessNotification = enqueueNotification(SNACKBAR_TYPES.success);
  /** Show error snackbar  */
  const enqueueErrorNotification = enqueueNotification(SNACKBAR_TYPES.error);
  /** Show info snackbar  */
  const enqueueInfoNotification = enqueueNotification(SNACKBAR_TYPES.info);
  /** Show warning snackbar  */
  const enqueueWarningNotification = enqueueNotification(SNACKBAR_TYPES.warning);

  return {
    enqueueSuccessNotification,
    enqueueErrorNotification,
    enqueueInfoNotification,
    enqueueWarningNotification,
  };
};
