import { z } from 'zod';

const userMetadataSchema = z.object({
  total_pages: z.number(),
  total_users: z.number(),
});

const userSchema = z.object({
  uuid: z.string(),
  user_name: z.string(),
  user_real_name: z.string(),
  user_email: z.string(),
  demo_user: z.boolean(),
  expires_at: z.string().nullable().optional(),
  require_password_change: z.boolean(),
  product_description_fieldname: z.string(),
  value_fieldname: z.string(),
  stores: z.array(
    z.object({
      uuid: z.string(),
      store_name: z.string(),
      override_time: z.string().nullable(),
    })
  ),
  orgs: z.array(
    z.object({
      org_name: z.string(),
      org_id: z.string(),
      logo_url: z.string().url(),
    })
  ),
  role: z.array(
    z.object({
      user_role: z.string(),
    })
  ),
});

export const listUsersSchema = z.object({
  metadata: userMetadataSchema,
  data: z.array(userSchema),
});

export type ListUsersDataSchema = Array<z.infer<typeof userSchema>>;
export type ListUsersSchema = z.infer<typeof listUsersSchema>;
