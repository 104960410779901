import styled from 'styled-components';
import MuiPopover from '@mui/material/Popover';

export const StyledPopover = styled(MuiPopover)`
  & .MuiPaper-root {
    box-shadow: ${(props) => props.theme.boxShadow.small};
    background-color: ${(props) => props.theme.colors.appBackground};
    margin-top: 6px;
    border: 1px solid ${(props) => props.theme.colors.borderColor};
  }
`;
