import isEmpty from 'lodash/isEmpty';
import { type Orgs } from './types-and-schema';
import { getSearchAutoCompleteOptions } from './getSearchAutoCompleteOptions';
import { paginateOrgs } from './paginate-orgs';

type Org = Orgs[0];

interface SplitDevices {
  active: Array<
    Org & {
      active_device_count: number;
      inactive_device_count: number;
    }
  >;
  inactive: Array<
    Org & {
      active_device_count: number;
      inactive_device_count: number;
    }
  >;
}

export const transformDataFn = (orgs: Orgs) => {
  const paginatedAllDevices = paginateOrgs(orgs);

  const splitDevices = orgs.reduce(
    (acc, curr) => {
      const activeDevices = curr.devices.filter((device) => !device.is_status_image_old);
      const inActiveDevices = curr.devices.filter((device) => device.is_status_image_old);

      const updatedActiveOrg = {
        ...curr,
        active_device_count: activeDevices.length,
        inactive_device_count: inActiveDevices.length,
        devices: activeDevices,
      };

      const updatedInActiveOrg = {
        ...curr,
        active_device_count: activeDevices.length,
        inactive_device_count: inActiveDevices.length,
        devices: inActiveDevices,
      };

      if (!isEmpty(activeDevices)) {
        acc.active = [...(acc.active || []), updatedActiveOrg];
      }

      if (!isEmpty(inActiveDevices)) {
        acc.inactive = [...(acc.inactive || []), updatedInActiveOrg];
      }

      return acc;
    },
    { active: [], inactive: [] } as SplitDevices
  );

  const paginatedActiveDevices = paginateOrgs(splitDevices.active);
  const paginatedInactiveDevices = paginateOrgs(splitDevices.inactive);

  return {
    /* Counts */
    totalOrgsCount: orgs.length || 0,
    totalDevicesCount: orgs.reduce((acc, org) => acc + org.device_count, 0) || 0,
    totalActiveDevicesCount: splitDevices.active.reduce((acc, org) => acc + org.live_device_count, 0) || 0,
    totalInactiveDevicesCount:
      splitDevices.inactive.reduce((acc, org) => acc + org.device_count - org.live_device_count, 0) || 0,

    /* Lists */
    paginatedAllDevices,
    paginatedActiveDevices,
    paginatedInactiveDevices,

    /* Search */
    searchAutoComplete: getSearchAutoCompleteOptions(orgs),
  };
};
