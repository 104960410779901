import ky from 'ky';
import { z } from 'zod';
import { ZONE_TYPE_MAP } from 'src/components/ZoneEditor/constants';
import { ENV } from 'src/utils/env';

const VERSION_HISTORY_SCHEMA = z.object({
  id: z.string(),
  created_at: z.string(),
  username: z.string(),
  config: z.string(),
});

const DEVICE_ZONE_VERSION_HISTORY_SCHEMA = z.object({
  data: z.object({
    [ZONE_TYPE_MAP.detection]: z.array(VERSION_HISTORY_SCHEMA),
    [ZONE_TYPE_MAP.presence]: z.array(VERSION_HISTORY_SCHEMA),
    [ZONE_TYPE_MAP.exclusion]: z.array(VERSION_HISTORY_SCHEMA),
  }),
  error: z.string().optional().nullable(),
});

export type DeviceZoneVersionHistory = z.infer<typeof DEVICE_ZONE_VERSION_HISTORY_SCHEMA>;

export type VersionHistory = z.infer<typeof VERSION_HISTORY_SCHEMA>;

export const getDeviceZoneVersionHistory = async (deviceId: string) => {
  const res = await ky
    .post(`${ENV.FRONTEND_API_URL}/get_device_zone_version_history`, {
      body: deviceId,
    })
    .json();

  const result = DEVICE_ZONE_VERSION_HISTORY_SCHEMA.safeParse(res);

  if (!result.success) {
    // eslint-disable-next-line no-console
    console.error(result.error);
    return {
      [ZONE_TYPE_MAP.detection]: [] as Array<VersionHistory>,
      [ZONE_TYPE_MAP.presence]: [] as Array<VersionHistory>,
      [ZONE_TYPE_MAP.exclusion]: [] as Array<VersionHistory>,
    };
  }

  return result.data?.data;
};
