import styled from 'styled-components';
import MuiButton from '@mui/material/Button';
import { hexToRgba } from 'src/utils';
import { type To } from 'react-router-dom';
import { SNACKBAR_COLORS } from '../Snackbar/constant';

export interface ButtonProps {
  $padding?: string;
  to?: To;
}

export const Button = styled(MuiButton).attrs({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  'data-cy': 'button',
})<ButtonProps>`
  font-weight: 500;
  font-size: 16px;
  text-transform: unset;
  letter-spacing: unset;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  padding: ${(props) => props.$padding};
  border: 1px solid ${(props) => props.theme?.colors.transparent};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &.MuiButton-sizeLarge {
    min-height: 56px;
  }

  &.MuiButton-sizeSmall {
    font-size: 14px;
    min-height: 36px;
    padding: 0 12px;
  }

  &.MuiButton-containedPrimary {
    background-color: ${(props) => props.theme?.colors.primary};
    color: ${(props) => props.theme?.colors.white};

    &:hover {
      background-color: ${(props) => props.theme?.colors.primaryHover};
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    &:active {
      background-color: ${(props) => props.theme?.colors.primary};
    }
    &.Mui-disabled {
      background-color: ${(props) => props.theme?.colors.primaryHover};
      opacity: 0.8;
      color: ${(props) => props.theme?.colors.white};
    }
    &.Mui-focusVisible {
      border: 1.5px solid rgba(0, 0, 0, 0.25);
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    }
  }

  &.MuiButton-containedSecondary {
    background-color: ${(props) =>
      props.theme?.isDarkTheme ? props.theme?.colors.appBackground : props.theme?.colors.grey3};
    color: ${(props) => props.theme?.colors.textColor};
    box-shadow: none;
    border: 1px solid ${(props) => props.theme?.colors.borderColor};

    &:hover {
      background-color: ${(props) => hexToRgba(props.theme?.colors.primary || '', 0.25)};
      box-shadow: none;
      color: ${(props) => props.theme?.colors.primaryDark};
      border-color: ${(props) => props.theme?.colors.primaryDark};
    }
    &:active {
      background-color: ${(props) => hexToRgba(props.theme?.colors.primary || '', 0.32)};
      color: ${(props) => props.theme?.colors.primary};
    }
    &.Mui-disabled {
      background-color: ${(props) => hexToRgba(props.theme?.colors.grey3 || '', 0.8)} !important;
      color: ${(props) => hexToRgba(props.theme?.colors.textColor || '', 0.8)};
    }
    &.Mui-focusVisible {
      border: 1.5px solid ${(props) => hexToRgba(props.theme?.colors.primary || '', 0.4)};
      box-shadow: none;
      color: ${(props) => props.theme?.colors.primary};
    }
  }

  &.MuiButton-outlinedError {
    border: 1px solid ${(props) => props.theme?.colors.red};
    color: ${(props) => props.theme?.colors.red};
  }

  &.MuiButton-outlinedWarning {
    border: 1px solid ${SNACKBAR_COLORS.warning};
    color: ${SNACKBAR_COLORS.warning};
  }
`;

Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
  $padding: '8px 30px',
};
