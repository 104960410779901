import moment from 'moment';
import ky from 'ky';
import isEmpty from 'lodash/isEmpty';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const STATUS_IMAGE_SCHEMA = z.object({
  url: z.string(),
  timestamp: z.string().or(z.number()),
});

const PULL_STATUS_IMAGE_SCHEMA = z.object({
  details: z.array(STATUS_IMAGE_SCHEMA),
});

export type PullStatusImageSchema = z.infer<typeof PULL_STATUS_IMAGE_SCHEMA>;

export interface GetStatusImagePromiseParams {
  display_id: string;
  surrounding_count?: number;
  timestamp?: string;
}

export const getStatusImagePromise = async ({
  display_id,
  surrounding_count,
  timestamp,
}: GetStatusImagePromiseParams) => {
  const response = await ky
    .post(`${ENV.FRONTEND_API_URL}/pull_status_image`, {
      body: JSON.stringify({ display_id: display_id.toString(), surrounding_count, timestamp }),
    })
    .json();

  const result = PULL_STATUS_IMAGE_SCHEMA.safeParse(response);

  if (!result.success) {
    return [
      {
        url: `${window.location.origin}/image-placeholder.jpg`,
        timestamp: Date.now(),
      },
    ];
  }

  let res = result.data.details;

  if (timestamp) {
    const passedTime = new Date(Number(timestamp) * 1000);

    const filterByTimestamp = result.data.details?.filter((item) => {
      const currentTime = new Date(Number(item?.timestamp) * 1000);
      return moment(passedTime).isSame(currentTime, 'day') || moment(passedTime).isAfter(currentTime, 'day');
    });

    if (!isEmpty(filterByTimestamp)) {
      res = filterByTimestamp;
    }
  }

  return res?.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));
};

export type PullStatusImageResult = Awaited<ReturnType<typeof getStatusImagePromise>>;
