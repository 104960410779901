import { ENV } from 'src/utils/env';
import { z } from 'zod';
import ky from 'ky';

const STORE_LOCATION_SCHEMA = z.object({
  id: z.string(),
  label: z.string(),
  country: z.string(),
  county: z.string(),
  lat: z.string(),
  lon: z.string(),
});

const GET_STORE_LOCATION_SCHEMA = z.object({
  data: z.array(STORE_LOCATION_SCHEMA),
  error: z.string().optional().nullable(),
});

export const getStoreLocationOptions = async (query?: string) => {
  try {
    const res = await ky
      .post(`${ENV.FRONTEND_API_URL}/get_location_options`, {
        ...(query ? { body: query } : {}),
      })
      .json();

    const validationResult = GET_STORE_LOCATION_SCHEMA.safeParse(res);

    if (validationResult.success) {
      return validationResult.data.data.map((location) => ({
        ...location,
        label: `${location.label}, ${location.country}`,
      }));
    }

    // eslint-disable-next-line no-console
    console.error(validationResult.error);
    return [];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);

    return [];
  }
};
