import * as React from 'react';
import moment, { Moment } from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { P16 } from 'src/components/atoms';
import { SNACKBAR_COLORS } from 'src/components/atoms/Snackbar/constant';
import { MOMENT_CALENDAR_OPTIONS } from 'src/constants/date-utils/moment-calendar-options.ts';
import { usePopover } from 'src/hooks';
import { COLORS } from 'src/constants/theme';
import { StyledTextFields } from '../Inputs/common';
import { Popover } from '../Popover';
import { StyledDateAndTimePicker } from './DateAndTimePicker.styled.ts';

const ID = 'date-and-time-picker';

interface DateAndTimePickerProps {
  onChange: (date: Moment | null) => void;
  value: string;
  label: string;
  isDirty?: boolean;
  helperText?: string;
}

export const DateAndTimePicker: React.FunctionComponent<DateAndTimePickerProps> = ({
  onChange,
  value,
  isDirty,
  label,
  helperText,
}) => {
  const { handleOpen, ...popoverHelper } = usePopover(ID);

  const isEmptyValue = !value?.length;

  const displayValue = isEmptyValue ? '' : moment(value).calendar(undefined, MOMENT_CALENDAR_OPTIONS);

  const pickerValue = isEmptyValue ? null : moment(value);

  return (
    <div>
      <P16
        as="label"
        htmlFor={ID}
        $margin="0 0 6px 0"
        $display="block"
        $fontWeight={500}
        $color={isDirty ? SNACKBAR_COLORS.warning : COLORS.grey1}
      >
        {label}
      </P16>
      <StyledTextFields
        name={ID}
        onClick={handleOpen}
        value={displayValue}
        size="small"
        $isDirty={isDirty}
        helperText={helperText}
        fullWidth
      />
      <Popover {...popoverHelper}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StyledDateAndTimePicker>
            <StaticDateTimePicker
              orientation="landscape"
              onAccept={onChange}
              onClose={popoverHelper.handleClose}
              value={pickerValue}
              ampm={false}
            />
          </StyledDateAndTimePicker>
        </LocalizationProvider>
      </Popover>
    </div>
  );
};

DateAndTimePicker.defaultProps = {
  isDirty: false,
  helperText: undefined,
};
