import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';
import isEmpty from 'lodash/isEmpty';

const USER_SCHEMA = z.object({
  username: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email(),
  groups: z.array(z.string()),
});

export type GetUserSchema = z.infer<typeof USER_SCHEMA>;

export const getUserPromise = async () => {
  const response = await ky.post(`${ENV.FRONTEND_API_URL}/users`).json();

  const result = USER_SCHEMA.safeParse(response);

  if (!result.success) {
    throw new Error('Invalid response');
  }

  return {
    userDetails: result.data,
    isLoggedIn:
      !isEmpty(result.data?.email) &&
      !isEmpty(result.data?.username) &&
      result.data?.username?.toLowerCase() !== 'anonymous_user',
  };
};
