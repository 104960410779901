import { InterpolationOptions } from 'i18next';
import { Language } from './languages';

export const i18nConfig = {
  fallbackLng: 'en' as Language,
  customLanguage: 'custom',
  interpolationOptions: {
    escapeValue: false,
  } as InterpolationOptions,
};

export default i18nConfig;
