import { useMutation, useQueryClient } from '@tanstack/react-query';
import { clearShapesFn } from 'src/components/ZoneEditor/context';
import { QUERY_KEY_MAP } from 'src/constants';
import { useNotification, useSearch } from 'src/hooks';
import { removeDetectionZonesPromise } from 'src/utils/api/edit-detection-zone/removeDetectionZonePromise';
import { z } from 'zod';

export const REMOVE_DETECTION_ZONES_SCHEMA = z.object({
  display_id: z.string(),
});

export type RemoveDetectionZonesSchema = z.infer<typeof REMOVE_DETECTION_ZONES_SCHEMA>;

export const useRemoveDetectionZones = () => {
  const queryClient = useQueryClient();
  const { enqueueSuccessNotification } = useNotification();
  const [searchParams] = useSearch();
  const deviceId = searchParams?.device?.id || '';

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationFn: removeDetectionZonesPromise,
  });

  const removeDetectionZonesAsync = async (params: RemoveDetectionZonesSchema) => {
    try {
      await Promise.all([mutateAsync(params)]);

      clearShapesFn();
      enqueueSuccessNotification('Zones removed');

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_MAP.useGetZoneLastUpdatedMeta, deviceId],
        }),

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_MAP.useGetDetectionZoneData, deviceId],
        }),
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_MAP.useGetDeviceZoneVersionHistory, deviceId],
        }),
      ]);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return { removeDetectionZonesAsync, isLoading: isPending, isError, error };
};
