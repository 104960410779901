import styled from 'styled-components';

export interface StyledWrapperProps {
  $color: string;
  $bgColor: string;
}
export const StyledWrapper = styled.div<StyledWrapperProps>`
  background-color: ${(props) => props.$bgColor};
  border: 1px solid ${(props) => props.$color};
  width: 370px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 6px;
  border-radius: 8px;
  height: auto;
  box-shadow: ${(props) => props.theme.boxShadow.small};
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;

    background-color: ${(props) => props.theme.colors.pageContentColor};
  }
  &::after {
    background-color: ${(props) => props.$bgColor};
  }

  & .MuiIconButton-root {
    position: relative;
    z-index: 10;
  }
`;

export const StyledSnackbarContent = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledSnackbarIcon = styled.div<Pick<StyledWrapperProps, '$color'>>`
  display: block;
  width: 5;
  border-radius: 2px;
  height: 80%;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background-color: ${(props) => props.$color};
  z-index: 10;
`;

export const StyledSnackbarMessage = styled.div`
  font-size: 16px;
  margin: 6px 10px 4px 16px;
  position: relative;
  z-index: 10;
`;
