import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorIcon } from 'src/assets/icons';
import { Button } from '../../atoms/Button';
import { P18 } from '../../atoms/Typography';
import { StyledWrapper, StyledFallbackUiWrapper } from './FallbackUi.styled';

export interface FallbackUiProps {
  handleTryAgain: () => void;
}

export const FallbackUi: React.FunctionComponent<FallbackUiProps> = ({ handleTryAgain }) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <StyledFallbackUiWrapper>
        <ErrorIcon size={56} />
        <P18>{t('uiLabel.errorBoundaryDescription')}</P18>
        <Button type="button" size="medium" onClick={handleTryAgain} fullWidth>
          {t('button.tryAgain')}
        </Button>
      </StyledFallbackUiWrapper>
    </StyledWrapper>
  );
};
