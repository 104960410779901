import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { type SalesData } from 'src/utils';

export interface IActiveUploadItem {
  id: string;
  epos_data: SalesData;
  preview: SalesData;
  size: string;
  /**
   * - IN_PROGRESS: file is being uploaded (used to trigger API calls)
   * - FAILED: file failed to upload; can be to to validation error or API error
   * - SUCCESS: file was successfully uploaded
   */
  status: 'IN_PROGRESS' | 'FAILED' | 'SUCCESS';
  number_of_items: number;
  file_name: string;
  tz?: string;
  start_time: number; // unix timestamp - used to create a facade progress value
  errors: { [line: string | number]: Array<string> };
  storeCodes: Array<string>;
}

interface EposUploadStore {
  activeUploads: Array<IActiveUploadItem>;
}

const useEposActiveUploadStore = createWithEqualityFn<EposUploadStore>(
  () => ({
    activeUploads: [],
  }),
  shallow
);

export const deleteAllActiveUploadItems = () => {
  useEposActiveUploadStore.setState(() => ({
    activeUploads: [],
  }));
};
export const useListUploads = () =>
  useEposActiveUploadStore((state) => state.activeUploads.sort((a, b) => b.start_time - a.start_time));

export const useListActiveUploads = () =>
  useEposActiveUploadStore((state) => state.activeUploads.filter((item) => item.status === 'IN_PROGRESS'));

export const addActiveUploadFn = (upload: IActiveUploadItem) =>
  useEposActiveUploadStore.setState((state) => ({
    activeUploads: [...state.activeUploads, upload],
  }));

export const updateActiveUploadItem = (id: string, upload: Partial<IActiveUploadItem>) =>
  useEposActiveUploadStore.setState((state) => ({
    activeUploads: state.activeUploads.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          ...upload,
        };
      }

      return item;
    }),
  }));

export const onUploadSuccessCallback = (id: string) =>
  useEposActiveUploadStore.setState((state) => ({
    activeUploads: state.activeUploads.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          epos_data: [],
          status: 'SUCCESS',
        };
      }
      return item;
    }),
  }));

export const onUploadFailedCallback = (id: string, errors: Array<string>) =>
  useEposActiveUploadStore.setState((state) => ({
    activeUploads: state.activeUploads.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          epos_data: [],
          status: 'FAILED',
          errors: {
            API: errors,
          },
        };
      }
      return item;
    }),
  }));
