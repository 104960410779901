import { Theme } from './theme-types';

export const WIDTH_UTILS: Theme['width'] = {
  drawerWidthWide: '260px',
  drawerWidthNarrow: '74px',

  /** app header is fixed so the content that follows it need an offset  */
  appContentOffset: '65px',

  zoneEditorOptionsDrawerWidth: '380px',
};
