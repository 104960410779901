import * as React from 'react';

export const HomeIcon: React.FunctionComponent = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 18.998V13.998C13 12.3412 11.6569 10.998 10 10.998C8.34315 10.998 7 12.3412 7 13.998V18.998M5.60561 3.64829L3.00561 6.10385C2.26632 6.80207 1.89667 7.15118 1.63191 7.56446C1.39721 7.9308 1.22385 8.33298 1.11866 8.75515C1 9.2314 1 9.73985 1 10.7567V12.598C1 14.8382 1 15.9584 1.43597 16.814C1.81947 17.5666 2.43139 18.1786 3.18404 18.5621C4.03968 18.998 5.15979 18.998 7.4 18.998H12.6C14.8402 18.998 15.9603 18.998 16.816 18.5621C17.5686 18.1786 18.1805 17.5666 18.564 16.814C19 15.9584 19 14.8382 19 12.598V10.7567C19 9.73985 19 9.2314 18.8813 8.75515C18.7761 8.33298 18.6028 7.9308 18.3681 7.56446C18.1033 7.15118 17.7337 6.80207 16.9944 6.10385L14.3944 3.64829C12.8479 2.18771 12.0746 1.45741 11.1925 1.1819C10.416 0.939367 9.584 0.939367 8.80748 1.1819C7.92537 1.45741 7.15211 2.18771 5.60561 3.64829Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
