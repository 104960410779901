import { Resource } from 'i18next';
import { en } from './languages';

export const i18nResources: Resource = {
  en: {
    translation: en,
  },
};

export default i18nResources;
