import * as React from 'react';

export interface DarkModeIconProps {
  fill?: string;
}

export const DarkModeIcon: React.FunctionComponent<DarkModeIconProps> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path
        d="M12 21.1q-3.825 0-6.462-2.625Q2.9 15.85 2.9 12q0-3.35 2.125-5.888Q7.15 3.575 10.6 3.05q.75-.15 1.188.375.437.525.137 1.2-.35.625-.537 1.287-.188.663-.188 1.388 0 2.3 1.612 3.9 1.613 1.6 3.913 1.6.7 0 1.4-.175T19.4 12.1q.625-.275 1.162.1.538.375.388 1.175-.45 3.35-3.012 5.538Q15.375 21.1 12 21.1Zm0-2.275q2.05 0 3.7-1.1 1.65-1.1 2.45-2.875-.425.1-.862.162-.438.063-.888.038-3.05-.1-5.187-2.225Q9.075 10.7 8.95 7.6q0-.45.05-.888.05-.437.15-.862-1.75.8-2.85 2.45Q5.2 9.95 5.2 12q0 2.825 1.988 4.825 1.987 2 4.812 2Zm-.325-6.5Z"
        fill={fill}
      />
    </svg>
  );
};
DarkModeIcon.defaultProps = {
  fill: '#000',
};
