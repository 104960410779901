import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import debounce from 'lodash/debounce';
import type { BoundingBoxConfig, NoteData } from 'src/types';
import { generateVRID, getRandomNumber } from 'src/utils/helpers';
import { getRelativeShapeProps } from '../utils';
import { useActiveZoneEditorType } from '../hooks';
import { BOX_COLORS } from '../atoms/ZoneCanvas/colors';

interface UseZoneEditorStore {
  /**
   * Newly added shapes by the user
   */
  shapes: Array<BoundingBoxConfig>;
  /**
   * Previous shapes from the API
   */
  previousShapes: Array<BoundingBoxConfig>;

  /**
   * Previous shapes from the API being previewed for a potential rollback - Version History
   */
  rollbackPreviewShapes: Array<BoundingBoxConfig>;

  // selectedShapeId: string | null;
  selectedShapeId: string | null;

  // shapes that a hidden from the ui
  hiddenShapes: Array<string>;

  // zone notes updated by the user, will be saved along with the zone config
  notes?: NoteData | null;

  canUndo: boolean;
  canRedo: boolean;
}

const useZoneEditorStore = createWithEqualityFn<UseZoneEditorStore>(
  () => ({
    shapes: [],
    previousShapes: [],
    rollbackPreviewShapes: [],
    selectedShapeId: null,
    hiddenShapes: [],
    notes: null,
    canUndo: false,
    canRedo: false,
  }),
  shallow
);

export const useGetAddedShapes = () => useZoneEditorStore((state) => state.shapes, shallow);

export const useGetRollbackPreviewShapes = () => useZoneEditorStore((state) => state.rollbackPreviewShapes, shallow);

export const setRollbackPreviewShapesFn = (rollbackPreviewShapes: Array<BoundingBoxConfig>) =>
  useZoneEditorStore.setState({ rollbackPreviewShapes });

export const clearRollbackPreviewShapesFn = () => useZoneEditorStore.setState({ rollbackPreviewShapes: [] });

export const useIsSelectedShape = (shapeId: string) => useZoneEditorStore((state) => state.selectedShapeId === shapeId);

export const setSelectedShapeIdFn = (selectedShapeId?: string | null) =>
  useZoneEditorStore.setState({ selectedShapeId });

export const clearShapesFn = () =>
  useZoneEditorStore.setState({
    shapes: [],
    previousShapes: [],
    selectedShapeId: null,
    notes: null,
    hiddenShapes: [],
    rollbackPreviewShapes: [],
  });

export const useNewShapeCount = () => useZoneEditorStore((state) => state.shapes.length);

export const setPreviousShapesFn = (previousShapes: Array<BoundingBoxConfig>) =>
  useZoneEditorStore.setState({ previousShapes });

export const useSelectedShape = (shapeId?: string) => {
  const stateSelectedId = useZoneEditorStore((state) => state.selectedShapeId);
  const selectedId = shapeId || stateSelectedId;
  const shapes = useZoneEditorStore(
    (state) => [...(state.previousShapes || []), ...(state.shapes || []), ...(state.rollbackPreviewShapes || [])],
    shallow
  );

  const selectedShape = shapes?.find((shape) => shape.id === selectedId);

  return selectedShape;
};

export const useIsDrawing = () => {
  const { isPresenceZoneEditor, isExclusionZoneEditor } = useActiveZoneEditorType();
  const hasActiveDrawingShape = useZoneEditorStore((state) => Boolean(state.shapes.find((shape) => shape.isDrawing)));

  return (isPresenceZoneEditor || isExclusionZoneEditor) && hasActiveDrawingShape;
};

export const useShapeIsHidden = (shapeId: string) =>
  useZoneEditorStore((state) => state.hiddenShapes.includes(shapeId));

export const toggleHideShapeFn = (shapeId: string) => {
  useZoneEditorStore.setState((state) => {
    const hiddenShapes = state.hiddenShapes.includes(shapeId)
      ? state.hiddenShapes.filter((id) => id !== shapeId)
      : [...state.hiddenShapes, shapeId];

    return { hiddenShapes };
  });
};

export const getNotesFn = () => useZoneEditorStore.getState().notes;

export const updateNoteFn = debounce((notes?: NoteData) => useZoneEditorStore.setState({ notes }), 1000);

interface AddNewShapeFnParams {
  stageWidth: number;
  stageHeight: number;
}

export const cloneAndUpdatePreviousShapesFn = (newShape: BoundingBoxConfig) => {
  const newId = generateVRID();

  const newObj = {
    ...newShape,
    id: newId,
    name: newShape?.name || newId,
    isDrawing: false,
    isPrevious: false,
    fill: BOX_COLORS.new,
  };

  useZoneEditorStore.setState((state) => ({
    shapes: [...(state.shapes || []), newObj],
    selectedShapeId: newId,
  }));
};

/**
 * addNewShapeFn
 * - Add new shape to the canvas
 */

export const addNewShapeFn = ({ stageWidth, stageHeight }: AddNewShapeFnParams) => {
  const x = getRandomNumber(100, 600);
  const y = getRandomNumber(100, 300);

  const width = getRandomNumber(90, 200);
  const height = getRandomNumber(90, 200);

  const newShape = {
    ...getRelativeShapeProps({
      x,
      y,
      width,
      height,
      stageWidth,
      stageHeight,
      id: generateVRID(),
      dzone_direction: 1,
      detection_area: true,
      direction_angle_threshold: 50,
      direction_angle: 0,
      name: '',
      min_time: 2,
      epos_filters: [],
      points: [],
      isDrawing: true,
      category: 'NC',
      checkout_id: undefined,
    }),
  };

  useZoneEditorStore.setState((state) => ({
    shapes: [...(state.shapes || []), newShape],
    selectedShapeId: newShape.id,
  }));
};

export const updateRedoFn = (canRedo: boolean) => useZoneEditorStore.setState({ canRedo });

export const updateUndoFn = (canUndo: boolean) => useZoneEditorStore.setState({ canUndo });

interface UpdateShapeFnParams {
  shapeId: string;
  newShapeObj: BoundingBoxConfig;
  canUndo?: boolean;
  canRedo?: boolean;
}

/**
 * updateShapeFn
 * - Update shape on the canvas
 */
export const updateShapeFn = ({ shapeId, newShapeObj, canUndo, canRedo }: UpdateShapeFnParams) => {
  useZoneEditorStore.setState((state) => ({
    shapes: state.shapes.map((shape) => (shape.id === shapeId ? newShapeObj : shape)),
    ...(typeof canUndo === 'boolean' && { canUndo }),
    ...(typeof canRedo === 'boolean' && { canRedo }),
  }));
};

export const deleteShapeFn = (shapeId?: string) => {
  useZoneEditorStore.setState((state) => ({
    shapes: state.shapes.filter((shape) => shape.id !== (shapeId || state.selectedShapeId)),
    selectedShapeId: null,
  }));
};

export const useRedoUndoState = () => {
  const canUndo = useZoneEditorStore((state) => state.canUndo);
  const canRedo = useZoneEditorStore((state) => state.canRedo);

  return { canUndo, canRedo };
};

export const useGetAllShapes = () => {
  const shapes = useZoneEditorStore((state) => state.shapes);
  return shapes;
};
