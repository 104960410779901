import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const PRE_INIT_DEVICES_SCHEMA = z.object({
  id: z.string(),
  image_url: z.string().optional(),
  created_at: z.string(),
});

const PRE_INIT_DEVICES_RESPONSE_SCHEMA = z.array(PRE_INIT_DEVICES_SCHEMA);

export type PreInitDevicesSchema = z.infer<typeof PRE_INIT_DEVICES_SCHEMA>;

export type PreInitDevicesResponseSchema = z.infer<typeof PRE_INIT_DEVICES_RESPONSE_SCHEMA>;

export const getPreInitDevices = async () => {
  const response = await ky.get(`${ENV.FRONTEND_API_URL}/get_pre_init_devices`).json();

  const result = PRE_INIT_DEVICES_RESPONSE_SCHEMA.safeParse(response);

  if (!result.success) {
    throw new Error('Invalid response');
  }

  return (result.data || []).sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime() > 0 ? 1 : -1;
  });
};

export type PreInitDevice = Awaited<ReturnType<typeof getPreInitDevices>>[number];
