import * as React from 'react';

export const ZoneEditorIcon: React.FunctionComponent = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0038 6.49854V11.5017M4.49719 2.87891L13.5028 4.68003M2.99634 13.5029V4.49731M13.5028 13.3201L4.49719 15.1212M2.05836 1.49536H3.93423C4.24517 1.49536 4.49723 1.74743 4.49723 2.05836V3.93423C4.49723 4.24517 4.24517 4.49723 3.93423 4.49723H2.05836C1.74743 4.49723 1.49536 4.24517 1.49536 3.93423V2.05836C1.49536 1.74743 1.74743 1.49536 2.05836 1.49536ZM14.0658 3.49658H15.9417C16.2526 3.49658 16.5047 3.74864 16.5047 4.05958V5.93545C16.5047 6.24639 16.2526 6.49845 15.9417 6.49845H14.0658C13.7549 6.49845 13.5028 6.24639 13.5028 5.93545V4.05958C13.5028 3.74864 13.7549 3.49658 14.0658 3.49658ZM14.0658 11.5017H15.9417C16.2526 11.5017 16.5047 11.7538 16.5047 12.0647V13.9406C16.5047 14.2515 16.2526 14.5036 15.9417 14.5036H14.0658C13.7549 14.5036 13.5028 14.2515 13.5028 13.9406V12.0647C13.5028 11.7538 13.7549 11.5017 14.0658 11.5017ZM2.05836 13.5029H3.93423C4.24517 13.5029 4.49723 13.755 4.49723 14.0659V15.9418C4.49723 16.2527 4.24517 16.5048 3.93423 16.5048H2.05836C1.74743 16.5048 1.49536 16.2527 1.49536 15.9418V14.0659C1.49536 13.755 1.74743 13.5029 2.05836 13.5029Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
