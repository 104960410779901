import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

export const ZONE_BLOCK_DATA_SCHEMA = z.object({
  author: z.string(),
  last_updated: z.string(),
  block_data: z.object({
    time: z.number(),
    blocks: z.array(z.any()),
    version: z.string(),
  }),
});
export type ZoneBlockDataSchema = z.infer<typeof ZONE_BLOCK_DATA_SCHEMA>;

const ZONE_NOTE_SCHEMA = z.object({
  data: z.array(
    z.object({
      display_id: z.string(),
      created_at: z.string(),
      presence_zone: z.string().or(z.null()),
      detection_zone: z.string().or(z.null()),
    })
  ),
  error: z.string().or(z.null()).optional(),
});

export const getZoneNotesPromise = async (display_id: string) => {
  const res = await ky
    .post(`${ENV.FRONTEND_API_URL}/get_zone_notes`, {
      body: display_id,
    })
    .json();

  const result = ZONE_NOTE_SCHEMA.safeParse(res);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  return {
    ...(result.data?.data?.[0] || {}),
    presence_zone: JSON.parse(result.data?.data?.[0]?.presence_zone || '{}') as ZoneBlockDataSchema,

    detection_zone: JSON.parse(result.data?.data?.[0]?.detection_zone || '{}') as ZoneBlockDataSchema,
  };
};
