import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const WELCOME_EXPERIENCE_RESPONSE_SCHEMA = z.object({
  data: z.boolean(),
  error: z.string().nullable(),
});

export const addWelcomeExperienceFlagPromise = async (id: string) => {
  if (typeof id !== 'string') {
    return false;
  }

  try {
    const res = await ky
      .post(`${ENV.FRONTEND_API_URL}/add_show_welcome_experience_flag`, {
        body: id,
      })
      .json();

    const validatedRes = WELCOME_EXPERIENCE_RESPONSE_SCHEMA.safeParse(res);

    if (!validatedRes.success) {
      return false;
    }

    return validatedRes.data.data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('addWelcomeExperienceFlagPromise', e);

    return false;
  }
};

export const removeWelcomeExperienceFlagPromise = async (id: string) => {
  if (typeof id !== 'string') {
    return false;
  }

  try {
    const res = await ky.post(`${ENV.FRONTEND_API_URL}/remove_show_welcome_experience_flag`, {
      body: id,
    });

    if (!res.ok) {
      return false;
    }

    return true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('removeWelcomeExperienceFlagPromise', e);

    return false;
  }
};

export const retrieveWelcomeExperienceFlagPromise = async (id: string) => {
  if (typeof id !== 'string') {
    return false;
  }

  try {
    const res = await ky
      .post(`${ENV.FRONTEND_API_URL}/retrieve_show_welcome_experience_flag`, {
        body: id,
      })
      .json();

    const validatedRes = WELCOME_EXPERIENCE_RESPONSE_SCHEMA.safeParse(res);

    if (!validatedRes.success) {
      return false;
    }

    return validatedRes.data.data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('retrieveWelcomeExperienceFlagPromise', e);

    return false;
  }
};
