import styled, { css } from 'styled-components';
import { hexToRgba } from 'src/utils';

export interface DarkModeSwitcherProps {
  $darkMode?: boolean;
}

export const StyledToggleSlot = styled.button<DarkModeSwitcherProps>`
  width: 60px;
  height: 30px;
  border-radius: 4px;
  border: 1.5px solid ${({ theme }) => hexToRgba(theme.colors.grey1, 0.3)};
  position: relative;
  padding: 2px 2.5px;
  user-select: none;
  outline: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.transparent};
`;
export const StyledToggle = styled.div<DarkModeSwitcherProps>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: ${({ theme, $darkMode }) => ($darkMode ? hexToRgba(theme.colors.grey1, 0.4) : '#ffeccf')};
  border: 1.5px solid ${({ theme, $darkMode }) => ($darkMode ? theme.colors.grey1 : '#ffbb52')};
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: ${(props) => (props.$darkMode ? 'translate(0, -50%)' : 'translate(160%, -50%)')};
  transition:
    background-color 250ms,
    border-color 250ms,
    transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
`;

const COMMON_STYLES = css<DarkModeSwitcherProps>`
  top: 50%;
  transform: ${(props) => (props.$darkMode ? 'translateY(-50%) rotate(0deg)' : 'translateY(-50%) rotate(40deg)')};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transform: scale(0.9);
    path {
      fill: ${({ theme }) => theme.colors.grey1};
    }
  }

  transform-origin: 50% 50%;
  transition:
    opacity 150ms,
    transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
`;

export const StyledSunIcon = styled.div<DarkModeSwitcherProps>`
  position: absolute;
  ${COMMON_STYLES};
  opacity: ${(props) => (props.$darkMode ? 0 : 1)};

  svg {
    path {
      fill: #ffbb52;
    }
  }
`;

export const StyledMoonIcon = styled.div<DarkModeSwitcherProps>`
  position: absolute;
  ${COMMON_STYLES};
  right: 0;
  opacity: ${(props) => (props.$darkMode ? 1 : 0)};
`;
