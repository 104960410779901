import { USERS_PER_PAGE } from './constant';
import { ListUsersDataSchema } from './types-and-schema';

export const paginateUsers = (users: ListUsersDataSchema) => {
  if (!Array.isArray(users)) {
    return {
      users: [],
      totalPages: 0,
    };
  }

  const numberOfPages = Math.ceil(users.length / USERS_PER_PAGE);

  const paginated = Array.from(Array(numberOfPages).keys()).map((i) => {
    const start = i * USERS_PER_PAGE;
    return users.slice(start, start + USERS_PER_PAGE);
  });

  return {
    users: paginated,
    totalPages: numberOfPages,
  };
};

export type PaginatedUsers = ReturnType<typeof paginateUsers>;
