import { z } from 'zod';
import { requestTypeSchema } from '../../helpers/get-request-type/getRequestTypeFn';
import { MANAGEMENT_STORES_SCHEMA } from '../get-management-stores/schema';

export const STORES_CONFIG_SCHEMA = z.object({
  request_type: requestTypeSchema,
  search_params: z.record(z.string(), z.unknown()).optional(), // searchparams should go in here i.e for GET request for pagination.
  store_id: z.string().optional(), // this will be used as the pk to put in the url
  // fields associated with store object.
  uuid: z.string().optional(),
  ui_label: z.string().optional(),
  store_name: z.string().optional(),
  created_time: z.string().optional(),
  override_time: z.string().optional(),
  devices: z.array(z.object({ uuid: z.string(), device_type: z.string(), device_key: z.string() })).optional(),
  weather_location: z
    .object({ label: z.string(), lat: z.number().or(z.string()), lon: z.number().or(z.string()) })
    .optional(),
});

export type StoreConfigSchema = z.infer<typeof STORES_CONFIG_SCHEMA>;

export const STORES_CONFIG_GET_ALL_RESPONSE_SCHEMA = z.object({
  data: z.object({
    metadata: z.object({
      total_stores: z.number(),
      total_pages: z.number(),
    }),
    data: MANAGEMENT_STORES_SCHEMA,
  }),
});

export const STORES_CONFIG_GET_RESPONSE_SCHEMA = z.object({
  data: MANAGEMENT_STORES_SCHEMA,
});

export const STORES_CONFIG_POST_RESPONSE_SCHEMA = z.object({
  data: z.object({ message: z.string() }),
});
