import styled, { css } from 'styled-components';
import MuiDrawer from '@mui/material/Drawer';
import { hexToRgba } from 'src/utils';

export const StyledDrawerWrapper = styled.div`
  background-color: ${(props) => props.theme?.colors.appBackground};
`;

export interface StyledDrawerProps {
  $menuIsOpen?: boolean;
  $prefersWideDashboardMenu?: boolean;
}

export const StyledDrawer = styled(MuiDrawer)<StyledDrawerProps>`
  &,
  & .MuiPaper-root {
    overflow-x: hidden;
    flex-shrink: 0;
    white-space: nowrap;
    box-sizing: border-box;
    transition: width
      ${(props) => `${props.theme?.animation.duration.fast} ${props.theme?.animation.cubicBezier.animateOutFast}`};
    background-color: ${(props) => props.theme?.colors.appBackground};

    @media screen and (max-width: ${(props) => props.theme?.viewport.tablet}) {
      width: ${(props) => props.theme?.width.drawerWidthWide} !important;
    }
  }
  &.MuiDrawer-root {
    background-color: ${(props) => props.theme?.colors.transparent};
  }
  .MuiPaper-root {
    padding: 0px 10px 28px 10px;
    border-color: ${(props) => props.theme?.colors.borderColor};
    width: ${(props) => (props.open ? props.theme?.width.drawerWidthWide : props.theme?.width.drawerWidthNarrow)};
    box-shadow: none;

    ${(props) =>
      !props.$prefersWideDashboardMenu &&
      css`
        background-color: ${props.theme?.colors.appBackground};

        @supports (backdrop-filter: blur(6px)) {
          background-color: ${hexToRgba(props.theme?.colors.appBackground || '', 0.8)};
          backdrop-filter: blur(6px);
        }
      `};
  }

  .MuiBackdrop-root {
    background-color: ${(props) => props.theme?.colors.backdropColor};
  }
`;

export const StyledDrawerHeader = styled.div<StyledDrawerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 6px;

  .MuiButtonBase-root {
    position: relative;
    transform: ${(props) => (props.$prefersWideDashboardMenu ? 'rotate(180deg)' : 'rotate(0deg)')};
    opacity: ${(props) => (props.$menuIsOpen ? 1 : 0)};
    transition: transform
      ${(props) => `${props.theme?.animation.duration.fast} ${props.theme?.animation.cubicBezier.animateOutFast}`};
    transition: opacity
      ${(props) => `${props.theme?.animation.duration.fast} ${props.theme?.animation.cubicBezier.animateOutFast}`};
  }
`;

export const StyledLogo = styled.div<StyledDrawerProps>`
  height: 51px;
  overflow-y: hidden;
  flex-shrink: 0;
  display: flex;
  align-items: center;

  img {
    height: 42px;
    max-width: ${(props) => (props.$menuIsOpen ? '92px' : '42px')};
    border-radius: 4px;
    margin-left: 5px;
    transition: max-width
      ${(props) => `${props.theme?.animation.duration.fast} ${props.theme?.animation.cubicBezier.animateOutFast}`};
  }

  p {
    color: ${(props) => props.theme?.colors.grey1};
    font-size: 18px;
    font-weight: 500;
    margin-left: 8px;
    opacity: ${(props) => (props.$menuIsOpen ? 1 : 0)};
    transition: opacity
      ${(props) => `${props.theme?.animation.duration.fast} ${props.theme?.animation.cubicBezier.animateOutFast}`};
    text-transform: capitalize;
  }

  svg {
    margin-left: 8px;
    width: 36px;
    height: 36px;
  }
`;

export interface StyledMainProps {
  $prefersWideDashboardMenu?: boolean;
}
export const StyledMain = styled.main.attrs({
  id: 'main',
})<StyledMainProps>`
  height: 100vh;
  position: relative;
  margin-left: auto;
  overflow-x: hidden;
  width: ${(props) =>
    props.$prefersWideDashboardMenu
      ? `calc(100% - ${props.theme?.width.drawerWidthWide})`
      : `calc(100% - ${props.theme?.width.drawerWidthNarrow})`};
  transition: width
    ${(props) => `${props.theme?.animation.duration.fast} ${props.theme?.animation.cubicBezier.animateOutFast}`};
  /* border-bottom: 1px solid ${(props) => props.theme?.colors.borderColor}; */

  scroll-behavior: smooth;
  @media screen and (max-width: ${(props) => props.theme?.viewport.tablet}) {
    width: 100%;
  }
`;

export const StyledLinksSeparator = styled.div`
  border-bottom: 1px solid ${(props) => props.theme?.colors.borderColor};
  margin-top: auto;
  margin-bottom: 20px;
  user-select: none;
`;

export const StyledCommandShortcut = styled.div`
  margin-top: auto;
  margin-bottom: 20px;
  margin-left: 3px;

  kbd {
    color: ${(props) => props.theme?.colors.grey1} !important;
    font-weight: 600;
    display: block;
    white-space: nowrap;
    font-size: 14px;
    min-width: 20px;
    padding: 4px;
    height: 20px;
    border-radius: 4px;
    background-color: ${(props) =>
      props.theme?.isDarkTheme
        ? hexToRgba(props.theme?.colors.pageContentColor || '', 1)
        : hexToRgba(props.theme?.colors.inputBorderColor || '', 0.2)};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }
`;
