import { UI_ROUTES, type UiRoutes } from 'src/constants/routes';

export const PAGES_SHORTCUTS: Record<
  Exclude<
    UiRoutes,
    | '/user-management/create-new-user'
    | '/store-management/create-new-store'
    | '/view_as'
    | '/epos-upload/sign-in'
    | '/device-management/device-status'
    | '/device-management/installs'
    | '/device-management/new-install'
  >,
  string
> = {
  [UI_ROUTES.HOME]: 'h',
  [UI_ROUTES.ZONE_EDITOR]: 'z',
  [UI_ROUTES.ORG_BROWSER]: 'o',
  [UI_ROUTES.USER_MANAGEMENT]: 'u',
  [UI_ROUTES.AUDIT_LOGS]: 'a',
  [UI_ROUTES.TASKS]: 't',
  [UI_ROUTES.STORE_MANAGEMENT]: 's',
  [UI_ROUTES.EPOS_UPLOAD]: 'e',
  [UI_ROUTES.REPORT]: 'r',
  [UI_ROUTES.ZONE_SANDBOX]: 'x',
  [UI_ROUTES.DEVICE_MANAGEMENT]: 'd',
} as const;
