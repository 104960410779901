import { DefaultTheme as DefaultThemeType } from 'styled-components';
import { Theme } from './theme-types';
import { COLORS, COLORS_DARK } from './colors';
import { BOX_SHADOW, BOX_SHADOW_DARK } from './box-shadows';
import { VIEWPORT } from './viewport';
import { CUBIC_BEZIER, ANIMATION_DURATION } from './animation-utils';
import { WIDTH_UTILS } from './width-utils';

interface DefaultTheme extends DefaultThemeType, Theme {}

export const getTheme = (isDarkTheme: boolean): DefaultTheme => ({
  isDarkTheme,
  colors: {
    ...COLORS,
    ...(isDarkTheme && COLORS_DARK),
  },
  boxShadow: isDarkTheme ? BOX_SHADOW_DARK : BOX_SHADOW,
  viewport: VIEWPORT,
  animation: {
    duration: ANIMATION_DURATION,
    cubicBezier: CUBIC_BEZIER,
  },
  width: WIDTH_UTILS,
});
