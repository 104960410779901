import * as React from 'react';

interface ZoneDimensionContextValues {
  width: number;
  height: number;
}

const ZoneDimensionContext = React.createContext<ZoneDimensionContextValues>({
  width: 0,
  height: 0,
});

export const ZoneDimensionContextProvider = ZoneDimensionContext.Provider;

export const useZoneDimensionContext = () => React.useContext(ZoneDimensionContext);
