import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const RECENTLY_VIEWED_DEVICES_SCHEMA = z.object({
  created_at: z.string(),
  username: z.string(),
  devices: z.array(z.string()),
});

const RECENTLY_VIEWED_ORGS_SCHEMA = z.object({
  created_at: z.string(),
  username: z.string(),
  orgs: z.array(z.string()),
});

const RECENTLY_VIEWED_DEVICES_RESPONSE_SCHEMA = z.object({
  data: z.array(RECENTLY_VIEWED_DEVICES_SCHEMA),
  error: z.null(),
});

const RECENTLY_VIEWED_ORGS_RESPONSE_SCHEMA = z.object({
  data: z.array(RECENTLY_VIEWED_ORGS_SCHEMA),
  error: z.null(),
});

export const getRecentlyViewedDevices = async () => {
  const response = await ky.get(`${ENV.FRONTEND_API_URL}/get_recently_viewed_devices`).json();

  const result = RECENTLY_VIEWED_DEVICES_RESPONSE_SCHEMA.safeParse(response);

  if (!result.success) {
    throw new Error(`Failed to parse response: ${result.error.message}`);
  }

  return result.data.data?.[0]?.devices ?? [];
};

export const getRecentlyViewedOrgs = async () => {
  const response = await ky.get(`${ENV.FRONTEND_API_URL}/get_recently_viewed_orgs`).json();

  const result = RECENTLY_VIEWED_ORGS_RESPONSE_SCHEMA.safeParse(response);

  if (!result.success) {
    throw new Error(`Failed to parse response: ${result.error.message}`);
  }

  return result.data?.data?.[0]?.orgs ?? [];
};
