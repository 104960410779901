import styled from 'styled-components';
import { CUSTOM_TYPOGRAPHY_CSS, type CustomTypographyProps } from './custom-typography';

export const H4 = styled.h4<CustomTypographyProps>`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;

  ${CUSTOM_TYPOGRAPHY_CSS};
`;
