import styled, { css, keyframes } from 'styled-components';

const EMBLEM_ANIMATION_FRAME = keyframes`
  0% {
    transform: rotate(0deg);
      
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const EMBLEM_ANIMATION_FRAME_CSS = css`
  animation: ${EMBLEM_ANIMATION_FRAME} 2s linear infinite;
`;

export const StyledSuspenseFallbackComponent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    ${EMBLEM_ANIMATION_FRAME_CSS}

    svg {
      path {
        fill: ${(props) => props.theme.colors.grey2};
      }
    }
  }
`;
