/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import { Command } from 'cmdk';
import { useTranslation } from 'react-i18next';
import { ROUTES, ROUTE_ICONS } from 'src/constants/routes';
import { PAGES_SHORTCUTS } from './pages-shortcut';

export interface CommandMenuPagesGroupProps {
  onPageSelect: (value: string) => void;
}

export const CommandMenuPagesGroup: React.FunctionComponent<CommandMenuPagesGroupProps> = ({ onPageSelect }) => {
  const { t } = useTranslation();

  return (
    <Command.Group heading={t('uiLabel.pages')}>
      {ROUTES.map((route) => {
        const shortcuts = ['⌘', '⇧', PAGES_SHORTCUTS[route.path]];
        const label = t(route.label);
        const Icon = ROUTE_ICONS[route.path];
        return (
          <Command.Item key={route.path} onSelect={onPageSelect} value={label}>
            <Icon /> {label}
            <div cmdk-shortcuts="">
              {shortcuts.map((key) => {
                return <kbd key={key}>{key}</kbd>;
              })}
            </div>
          </Command.Item>
        );
      })}
    </Command.Group>
  );
};
