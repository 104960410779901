import { type Orgs } from './types-and-schema';

const ORGS_PER_PAGE = 15;

export const paginateOrgs = (orgs: Orgs) => {
  const numberOfPages = Math.ceil(orgs.length / ORGS_PER_PAGE);

  const paginated = Array.from(Array(numberOfPages).keys()).map((i) => {
    const start = i * ORGS_PER_PAGE;
    return orgs.slice(start, start + ORGS_PER_PAGE);
  });

  return {
    orgs: paginated,
    totalPages: numberOfPages,
  };
};

export type PaginatedOrgs = ReturnType<typeof paginateOrgs>;
