import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const GET_RETRY_TASK_CONFIG_ID_SCHEMA = z.object({
  data: z
    .object({
      config_id: z.string(),
    })
    .catchall(z.unknown()),
  error: z.unknown(),
});

export const getRetryTaskConfigIdPromise = async (taskId: string) => {
  if (typeof taskId !== 'string') {
    return null;
  }

  const response = await ky
    .post(`${ENV.FRONTEND_API_URL}/get_retry_config_id`, {
      body: taskId,
    })
    .json();

  const result = GET_RETRY_TASK_CONFIG_ID_SCHEMA.safeParse(response);

  if (!result.success) {
    return null;
  }

  return result.data.data.config_id;
};
