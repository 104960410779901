import * as React from 'react';

export const ReportsIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="reports-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.959756 1.5015C1.46767 0.993593 2.15654 0.708252 2.87484 0.708252H9.70817H9.70819C9.98627 0.708252 10.2378 0.821757 10.4191 1.00497L15.5365 6.12237C15.7197 6.30362 15.8332 6.55518 15.8332 6.83325L15.8332 6.83965V17.0833C15.8332 17.8015 15.5478 18.4904 15.0399 18.9983C14.532 19.5062 13.8431 19.7916 13.1248 19.7916H2.87484C2.15654 19.7916 1.46767 19.5062 0.959756 18.9983C0.451845 18.4904 0.166504 17.8015 0.166504 17.0833V3.41659C0.166504 2.69829 0.451845 2.00942 0.959756 1.5015ZM2.87484 2.70825H8.70819V6.83325C8.70819 7.38554 9.15591 7.83325 9.70819 7.83325H13.8332V17.0833C13.8332 17.2711 13.7585 17.4513 13.6257 17.5841C13.4929 17.717 13.3127 17.7916 13.1248 17.7916H2.87484C2.68698 17.7916 2.50681 17.717 2.37397 17.5841C2.24113 17.4513 2.1665 17.2711 2.1665 17.0833V3.41659C2.1665 3.22872 2.24113 3.04856 2.37397 2.91572C2.50681 2.78288 2.68698 2.70825 2.87484 2.70825ZM10.7082 4.12249L12.419 5.83325H10.7082V4.12249ZM4.58319 10.1042C4.03091 10.1042 3.58319 10.552 3.58319 11.1042C3.58319 11.6565 4.03091 12.1042 4.58319 12.1042H11.4165C11.9688 12.1042 12.4165 11.6565 12.4165 11.1042C12.4165 10.552 11.9688 10.1042 11.4165 10.1042H4.58319ZM3.58319 14.5208C3.58319 13.9685 4.03091 13.5208 4.58319 13.5208H11.4165C11.9688 13.5208 12.4165 13.9685 12.4165 14.5208C12.4165 15.073 11.9688 15.5208 11.4165 15.5208H4.58319C4.03091 15.5208 3.58319 15.073 3.58319 14.5208ZM4.58319 6.6875C4.03091 6.6875 3.58319 7.13522 3.58319 7.6875C3.58319 8.23978 4.03091 8.6875 4.58319 8.6875H6.29152C6.84381 8.6875 7.29152 8.23978 7.29152 7.6875C7.29152 7.13522 6.84381 6.6875 6.29152 6.6875H4.58319Z"
        fill="#919EAB"
      />
    </svg>
  );
};
