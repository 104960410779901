import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';
import { ZoneBlockDataSchema } from './getZoneNotesPromise';

export type InstallerBlockDataSchema = ZoneBlockDataSchema;

const INSTALLER_NOTE_SCHEMA = z.object({
  data: z.array(z.object({ zone_note_data: z.string().or(z.null()) })),

  error: z.string().or(z.null()).optional(),
});

export const getInstallerNotesPromise = async (display_id: string): Promise<InstallerBlockDataSchema> => {
  const res = await ky
    .post(`${ENV.FRONTEND_API_URL}/get_installer_notes`, {
      body: display_id,
    })
    .json();

  const result = INSTALLER_NOTE_SCHEMA.safeParse(res);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  return (JSON.parse(result.data?.data?.[0]?.zone_note_data || '{}') || {}) as InstallerBlockDataSchema;
};
