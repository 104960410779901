import styled from 'styled-components';
import { hexToRgba } from 'src/utils';

export const StyledDateAndTimePicker = styled.div`
  .MuiPickersLayout-root {
    background-color: ${(props) => props.theme.colors.appBackground};
    color: ${(props) => props.theme.colors.textColor};
    min-height: 437px;
  }

  .MuiPickersDay-root {
    border-radius: 4px;
    color: ${(props) => props.theme.colors.grey1};
    font-size: 15px;
    border-color: ${(props) => props.theme.colors.borderColor};

    &.Mui-selected {
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    }

    &:hover {
      background: ${(props) => hexToRgba(props.theme.colors.primary, 0.15)};
      border: none;
      color: ${(props) => props.theme.colors.textColor};
    }
  }

  .MuiPickersToolbar-root {
    width: 100%;
    max-width: 240px;
  }

  .MuiTabs-indicator,
  .MuiClock-pin,
  .MuiClockPointer-root {
    background-color: ${(props) => props.theme.colors.primary};
  }

  .MuiClockPointer-thumb {
    border-color: ${(props) => props.theme.colors.primary};
  }

  .MuiButtonBase-root,
  .MuiTypography-root {
    color: ${(props) => props.theme.colors.textColor};
  }

  .MuiTimeClock-root {
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .MuiClock-clock {
    background-color: ${(props) => props.theme.colors.pageContentColor};
  }

  .MuiClockNumber-root {
    color: ${(props) => props.theme.colors.grey1};

    &.Mui-selected {
      color: ${(props) => props.theme.colors.white};
    }
  }

  .MuiDialogActions-root .MuiButton-textPrimary {
    text-transform: capitalize !important;

    &:first-child {
      background-color: ${(props) =>
        props?.theme.isDarkTheme ? props.theme.colors.appBackground : props.theme.colors.grey3};
      color: ${(props) => props.theme.colors.textColor};
    }

    &:last-child {
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.white};
    }
  }

  .MuiPickersToolbarText-root.Mui-selected {
    color: ${(props) => props.theme.colors.primary};
  }
`;
