import { z } from 'zod';

export const SET_STORE_SCHEMA = z.object({
  uuid: z.string().optional(),
  store_name: z.string().optional(),
  ui_label: z.string().optional(),
  created_time: z.string().optional(),
  override_time: z.string().optional(),
  devices: z.array(z.object({ uuid: z.string(), device_type: z.string(), device_key: z.string() })).optional(),
  weather_location: z
    .object({ label: z.string(), lat: z.number().or(z.string()), lon: z.number().or(z.string()) })
    .optional(),
});

export type SetStoreSchema = z.infer<typeof SET_STORE_SCHEMA>;

export const SET_STORE_RESPONSE_SCHEMA = z.object({
  message: z.string(),
});
