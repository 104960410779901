import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';
import { RESINGESTOR_BASE_SCHEMA } from '../_common/base-schema';
import { TASK_ACTION_MAP } from '../../list-tasks';

const CREATE_VIDEO_SCHEMA = RESINGESTOR_BASE_SCHEMA.pick({
  index1: true,
  earliest1: true,
  latest1: true,
  email_address: true,
  blur: true,
});

const CREATE_VIDEO_RESPONSE_SCHEMA = z.object({
  success: z.boolean(),
  task_id: z.string(),
});

export type CreateVideoSchema = z.infer<typeof CREATE_VIDEO_SCHEMA>;

export const reingestorCreateVideoPromise = async (param: CreateVideoSchema) => {
  const validatedParam = CREATE_VIDEO_SCHEMA.safeParse(param);

  if (!validatedParam.success) {
    // eslint-disable-next-line no-console
    console.error(validatedParam.error);
    throw new Error('Invalid param');
  }

  const response = await ky
    .post(`${ENV.FRONTEND_API_URL}/reingestor`, {
      body: JSON.stringify({
        params: {
          ...validatedParam.data,
          action_type: TASK_ACTION_MAP.video,
        },
      }),
    })
    .json();

  const validatedResponse = CREATE_VIDEO_RESPONSE_SCHEMA.safeParse(response);

  if (!validatedResponse.success) {
    // eslint-disable-next-line no-console
    console.error(validatedResponse.error);
    return false;
  }

  return validatedResponse.data?.success;
};
