import * as React from 'react';
import { UsePopoverResult } from 'src/hooks';
import { DateRangePickerComponentProps } from './DateRangePickerComponent';
import { Popover } from '../Popover';
import { ErrorBoundary } from '../../ErrorBoundary/ErrorBoundary';
import { SuspenseFallback } from './SuspenseFallback';

// lazy load the date picker component
export const LazyDateRangePicker = React.lazy(() => import('./DateRangePickerComponent'));

export interface DatePickerProps extends UsePopoverResult, DateRangePickerComponentProps {}

export const DateRangePicker: React.FunctionComponent<DatePickerProps> = ({
  onChange,
  startDate,
  endDate,
  id,
  open,
  anchorEl,
  handleClose,
  isOutsideRange,
  singleDatePicker,
}) => {
  return (
    <Popover id={id} open={open} anchorEl={anchorEl} handleClose={handleClose}>
      <ErrorBoundary componentId="DateRangePicker-Suspense">
        <React.Suspense fallback={<SuspenseFallback />}>
          <LazyDateRangePicker
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            isOutsideRange={isOutsideRange}
            handleClose={handleClose}
            singleDatePicker={singleDatePicker}
          />
        </React.Suspense>
      </ErrorBoundary>
    </Popover>
  );
};
