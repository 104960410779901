import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';
import { ZoneSchema } from '../get-detection-zone/getDetectionZonePromise';

const EDIT_ZONE_SCHEMA = z.object({
  message: z.enum(['Detection zone successfully edited.']),
});
export type EditZoneSchema = z.infer<typeof EDIT_ZONE_SCHEMA>;
interface ApiDetectionZoneObj {
  id?: string;
  detection_zone: `${number}-${number}-${number}-${number}`;
  dzone_direction: number;
  detection_area: boolean;
  direction_angle: number;
  direction_angle_threshold: number;
  epos_filters: ZoneSchema['epos_filters'];
}

export interface EditDetectionZonePromiseParam {
  display_id: string;
  zones: Array<ApiDetectionZoneObj>;
}

export const editDetectionZonePromise = async (param: EditDetectionZonePromiseParam) => {
  const response = await ky.post(`${ENV.FRONTEND_API_URL}/edit_detection_zone`, { body: JSON.stringify(param) }).json();

  const result = EDIT_ZONE_SCHEMA.safeParse(response);

  if (!result.success) {
    throw new Error('Invalid response');
  }

  return true;
};
