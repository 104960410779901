import styled from 'styled-components';
import MuiAppBar from '@mui/material/AppBar';
import { IconButton } from '../IconButton';

export interface AppBarProps {
  $prefersWideDashboardMenu?: boolean;
}

export const AppBar = styled(MuiAppBar)<AppBarProps>`
  &.MuiAppBar-root {
    background-color: ${(props) => props.theme.colors.appBackground};
    position: fixed;
    top: 0;
    right: 0;
    box-sizing: border-box;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    box-shadow: none;
    width: ${(props) =>
      props.$prefersWideDashboardMenu
        ? `calc(100% - ${props.theme.width.drawerWidthWide})`
        : `calc(100% - ${props.theme.width.drawerWidthNarrow})`};
    transition: width
      ${(props) => `${props.theme.animation.duration.fast} ${props.theme.animation.cubicBezier.animateOutFast}`};

    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};

    .header-children {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }

    @media screen and (max-width: ${(props) => props.theme.viewport.tablet}) {
      width: 100%;
    }

    @media screen and (max-width: ${(props) => props.theme.viewport.mobile}) {
      height: auto;
    }
  }
`;

export const StyledAppBarTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  h1 {
    @media screen and (max-width: ${(props) => props.theme.viewport.mobile}) {
      //ellipsis
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 160px;
    }
  }
`;

export interface StyledIconButtonProps {
  $mobileNavOpen?: boolean;
}
export const StyledIconButton = styled(IconButton)<StyledIconButtonProps>`
  &.MuiIconButton-root {
    svg {
      transform: ${(props) =>
        props.$mobileNavOpen ? 'translateY(-2px) rotate(180deg)' : 'translateY(-2px) rotate(0deg)'};
      transition: transform
        ${(props) => `${props.theme.animation.duration.fast} ${props.theme.animation.cubicBezier.animateOutFast}`};
    }

    display: none;
    visibility: hidden;
    opacity: 0;

    @media screen and (max-width: ${(props) => props.theme.viewport.tablet}) {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
`;
