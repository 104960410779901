import { useSearch } from 'src/hooks';
import { ZONE_TYPE_MAP, type ZoneTypeMap } from '../../constants';

export const useActiveZoneEditorType = () => {
  const [searchParams] = useSearch();

  const isDetectionZoneEditor = Boolean(searchParams?.zone_type === ZONE_TYPE_MAP.detection);

  const isPresenceZoneEditor = Boolean(searchParams?.zone_type === ZONE_TYPE_MAP.presence);

  const isExclusionZoneEditor = Boolean(searchParams?.zone_type === ZONE_TYPE_MAP.exclusion);

  return {
    isDetectionZoneEditor,
    isPresenceZoneEditor,
    isExclusionZoneEditor,
    activeZoneType: searchParams?.zone_type as ZoneTypeMap,
  };
};
