import styled from 'styled-components';
import { CUSTOM_TYPOGRAPHY_CSS, type CustomTypographyProps } from './custom-typography';

export const H1 = styled.h1<CustomTypographyProps>`
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;

  ${CUSTOM_TYPOGRAPHY_CSS};
`;
