import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { useReducedMotion, Globals } from '@react-spring/web';
import { QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { initI18n, getTheme, MUI_THEME, QUERY_CLIENT } from 'src/constants';
import { useGlobalStore, useEnableDarkModeOnDocumentBody } from 'src/hooks';
import { SNACKBAR_PROVIDE_PROPS } from './snackbar-provider-options';

/** Switch these to a controller */
const LANGUAGE = 'en';
export interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FunctionComponent<AppProviderProps> = ({ children }) => {
  const isDarkTheme = useGlobalStore((state) => state.darkMode);
  const currentI18n = React.useMemo(() => initI18n(LANGUAGE), []);
  const currentTheme = React.useMemo(() => getTheme(isDarkTheme), [isDarkTheme]);
  const prefersReducedMotion = useReducedMotion();
  useEnableDarkModeOnDocumentBody();

  /** Disable react-spring animation for users that have reduce motion accessibility setting on */
  React.useEffect(() => {
    Globals.assign({
      skipAnimation: Boolean(prefersReducedMotion),
    });
  }, [prefersReducedMotion]);

  return (
    <>
      <CssBaseline />
      <I18nextProvider i18n={currentI18n}>
        <MuiThemeProvider theme={MUI_THEME}>
          <ThemeProvider theme={currentTheme}>
            <StyledEngineProvider injectFirst>
              <QueryClientProvider client={QUERY_CLIENT}>
                <SnackbarProvider {...SNACKBAR_PROVIDE_PROPS}>{children}</SnackbarProvider>
                <ReactQueryDevtools buttonPosition="bottom-left" />
              </QueryClientProvider>
            </StyledEngineProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </I18nextProvider>
    </>
  );
};
