import * as React from 'react';
import { UsePopoverResult } from 'src/hooks/use-popover';
import { StyledPopover } from './Popover.styled';

export interface PopoverProps extends Omit<UsePopoverResult, 'handleOpen'> {
  children: React.ReactNode;
}

export const Popover: React.FunctionComponent<PopoverProps> = ({ children, id, open, anchorEl, handleClose }) => {
  return (
    <StyledPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {children}
    </StyledPopover>
  );
};
