import ky from 'ky';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

export const TASK_STATUS_MAP = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  ERROR: 'ERROR',
  CANCELLED: 'CANCELLED',
  COMPLETE: 'COMPLETE',
} as const;

export const TASK_ACTION_MAP = {
  video: 'video',
  timeshift: 'timeshift',
  move: 'move',
  copy: 'copy',
  reingest: 'reingest',
  delete: 'delete',
  delete_and_reingest: 'deleteandreingest',
  pop_upload: 'pop_upload',
} as const;

export type TaskTypes = (typeof TASK_ACTION_MAP)[keyof typeof TASK_ACTION_MAP];

export type TaskStatus = (typeof TASK_STATUS_MAP)[keyof typeof TASK_STATUS_MAP];

export const TASK_SCHEMA = z.object({
  id: z.string(),
  status: z.enum(['PENDING', 'RUNNING', 'ERROR', 'CANCELLED', 'COMPLETE']),
  message: z.string(),
  author: z.string(),
  action_type: z.enum(['video', 'timeshift', 'move', 'copy', 'reingest', 'delete', 'deleteandreingest', 'pop_upload']),
  start_time: z.string(),
  last_modified: z.string(),
  config: z
    .object({
      index1: z.string().or(z.number()),
      index2: z.string().or(z.number()).optional().nullable(),
      earliest1: z.number().optional().nullable(),
      latest1: z.number().optional().nullable(),
    })
    .catchall(z.string().or(z.number()).or(z.boolean()).nullable()),
});

const LIST_TASKS_SCHEMA = z.array(TASK_SCHEMA);

export type Task = z.infer<typeof TASK_SCHEMA>;

export type ListTasksSchema = z.infer<typeof LIST_TASKS_SCHEMA>;

export const listTasksPromise = async () => {
  const res = await ky.get(`${ENV.FRONTEND_API_URL}/list_tasks`).json();

  const result = LIST_TASKS_SCHEMA.safeParse(res);

  if (!result.success) {
    // eslint-disable-next-line no-console
    console.error(result.error);
    return {
      total: 0,
      totalActive: 0,
      rawData: [],
      tasks: {} as Record<TaskStatus, Task[]>,
    };
  }

  // filter out tasks that are older than 3 day
  const rawData = result.data?.filter((el) => moment().diff(moment(el.last_modified), 'days') < 3);

  return {
    totalActive: rawData?.filter((el) => el.status === TASK_STATUS_MAP.PENDING || el.status === TASK_STATUS_MAP.RUNNING)
      .length,

    total: rawData.length,

    tasks: groupBy(rawData, (el) => el.status) as Record<TaskStatus, Task[]>,

    rawData,

    raw_unfiltered: result.data,
  };
};

export type ListTasks = Awaited<ReturnType<typeof listTasksPromise>>;
