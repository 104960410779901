import styled, { css } from 'styled-components';
import { hexToRgba } from 'src/utils';
import { IconButton } from '../IconButton';

const COMMON_CSS = css`
  .DayPicker {
    box-shadow: none;
    border-radius: 4px;
  }

  & .DayPicker,
  .CalendarMonthGrid,
  .CalendarMonth {
    background-color: ${(props) => props.theme.colors.appBackground};
  }

  .DayPicker_weekHeader {
    top: 78px;
  }

  .DayPicker_weekHeader_li {
    color: ${(props) => props.theme.colors.grey1};
    font-size: 15px;
  }

  .CalendarDay {
    border: none;
    color: ${(props) => props.theme.colors.textColor};
    border-radius: 2px;
    background: ${(props) => props.theme.colors.appBackground};

    transition: all 0.25s ease-out;

    &.CalendarDay__selected_start,
    &.CalendarDay__selected_end,
    &.CalendarDay__selected {
      background: ${(props) => props.theme.colors.primary};
      color: #fff;
    }

    &.CalendarDay__selected_span,
    &.CalendarDay__hovered_span,
    &:hover {
      background: ${(props) => hexToRgba(props.theme.colors.primary, 0.15)};
      border: none;
      color: ${(props) => props.theme.colors.textColor};
    }

    &.CalendarDay__before_hovered_end.CalendarDay__hovered_span {
      background: ${(props) => props.theme.colors.appBackground};
    }

    &.CalendarDay__blocked_out_of_range {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .CalendarMonth {
    /* we switch the orientation of the date picker to vertical when the viewport is tablet and margin is needed */
    @media screen and (max-width: ${(props) => props.theme.viewport.tablet}) {
      margin-bottom: 40px;
    }
  }

  .CalendarMonth_caption {
    color: ${(props) => props.theme.colors.textColor};
    transform: translateY(-30px);
  }

  .DayPicker_transitionContainer {
    min-height: 354px;
    padding-top: 32px;
  }
`;

export const StyledDateRangePicker = styled.div`
  ${COMMON_CSS};
`;

export interface StyledNextPrevButtonProps {
  $isNext: boolean;
}
export const StyledNextPrevButton = styled(IconButton)<StyledNextPrevButtonProps>`
  position: absolute;
  top: 18px;
  ${(props) =>
    props.$isNext
      ? 'right: 22px;'
      : css`
          left: 22px;
          &.MuiIconButton-root {
            transform: rotate(180deg);
          }
        `}
`;

export const StyledSuspenseFallback = styled.div`
  width: 618px;
  height: 300px;
`;
