import styled from 'styled-components';
import { hexToRgba } from 'src/utils/helpers';
import { Button } from '../../../Button';

export const StyledFilterButton = styled(Button)`
  text-transform: capitalize;
  justify-content: flex-start;

  padding-left: 34px;

  @media screen and (max-width: ${(props) => props.theme.viewport.tablet}) {
    padding-left: 40px;
  }

  & .MuiButton-startIcon {
    transform: translate(-4px, -10px);

    @media screen and (max-width: ${(props) => props.theme.viewport.tablet}) {
      transform: translate(-10px, -10px);
    }
  }

  &:hover {
    background-color: ${(props) => hexToRgba(props.theme.colors.primary, 0.15)} !important;
    color: ${(props) => props.theme.colors.textColor} !important;
  }

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
  }

  &:focus {
    border-width: 2px;
  }
`;
