import styled from 'styled-components';
import MuiChip from '@mui/material/Chip';
import { hexToRgba } from 'src/utils';

interface ChipProps {
  $color: string;
}

export const Chip = styled(MuiChip)<ChipProps>`
  background-color: ${(props) => hexToRgba(props.$color, 0.06)};
  border-radius: 4px;
  text-transform: capitalize;
  border: 1px solid ${(props) => props.theme?.colors.transparent};
  transition: all 0.3s ease-out;

  & .MuiChip-label.MuiChip-labelMedium {
    color: ${(props) => props.$color};
    font-size: 15px;
    font-weight: 800;
  }

  & .MuiChip-label.MuiChip-labelSmall {
    color: ${(props) => props.$color};
    font-size: 12px;
    font-weight: 600;
  }

  &.MuiChip-clickable {
    &:hover {
      border: 1px solid ${(props) => props.$color};
      background-color: ${(props) => hexToRgba(props.$color, 0.06)};
    }
  }
`;
