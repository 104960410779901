import styled from 'styled-components';
import { hexToRgba } from 'src/utils';

export const StyledAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  user-select: none;

  background-color: ${(props) => hexToRgba(props.theme.colors.grey1, 0.1)};
  p {
    color: ${(props) => props.theme.colors.grey1};
    font-weight: 500;
  }
`;
