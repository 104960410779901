import styled from 'styled-components';
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';

export interface IconButtonProps extends MuiIconButtonProps {
  $color?: string;
  $iconTransform?: string;
  $border?: string;
}

export const IconButton = styled(MuiIconButton)<IconButtonProps>`
  &.MuiIconButton-root {
    border-radius: 4px;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-4px);
    color: ${(props) => props.$color || props.theme.colors.textColor};
    border: ${(props) => props.$border};

    & svg {
      transform: ${(props) => props.$iconTransform};
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.grey3};
    }
  }
`;

IconButton.defaultProps = {
  $iconTransform: 'translateX(1px)',
};
