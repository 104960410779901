import * as React from 'react';

export const OrgBrowserIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        // eslint-disable-next-line no-inline-styles/no-inline-styles
        marginLeft: '-2px',
      }}
    >
      <path
        d="M20 5.7V18.1312C20 19.7156 16.4183 21 12 21C7.58172 21 4 19.7156 4 18.1312V5.7M20 5.7C20 7.19117 16.4183 8.4 12 8.4C7.58172 8.4 4 7.19117 4 5.7M20 5.7C20 4.20883 16.4183 3 12 3C7.58172 3 4 4.20883 4 5.7M20 12C20 13.4912 16.4183 14.7 12 14.7C7.58172 14.7 4 13.4912 4 12M16.001 17H16M16.001 11H16"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
