import styled, { css } from 'styled-components';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { hexToRgba } from 'src/utils';

export interface StyledMenuProps {
  $minWidth?: string;
}
export const StyledMenu = styled(Menu)<StyledMenuProps>`
  .MuiPopover-paper {
    background-color: ${(props) => props.theme.colors.appBackground};
    border-radius: 4px;
    box-shadow: ${(props) => props.theme.boxShadow.large};
    border: 1px solid ${(props) => hexToRgba(props.theme.colors.grey1, 0.1)};
    padding: 0 8px;
    border: 1px solid ${(props) => props.theme.colors.borderColor};
  }
  .MuiList-root {
    min-width: ${(props) => props.$minWidth};
  }
`;
StyledMenu.defaultProps = {
  $minWidth: '200px',
};

export interface StyledMenuItemProps {
  $danger?: boolean;
  $warning?: boolean;
  $isMultiple?: boolean;
}

export const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>`
  &.MuiMenuItem-root {
    width: 100%;
    border-radius: 4px;
    font-size: 15px;
    padding: 10px 12px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin-right: 4px;
        padding: 2px 4px;
        background-color: ${(props) => hexToRgba(props.theme.colors.grey1, 0.1)};
        color: ${(props) => props.theme.colors.grey1};
        border-radius: 2px;
        font-size: 14px;
        margin-bottom: 4px;
      }
    }

    // ellipsis
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: auto;
    }

    color: ${(props) => {
      if (props.$danger) return props.theme.colors.red;

      if (props.$warning) return props.theme.colors.warning;

      return props.theme.colors.textColor;
    }};

    &:not(:last-child) {
      margin-bottom: 2px;
    }

    svg {
      flex-shrink: 0;
      transform: translateY(1px) scale(0.8);
      display: none;
      margin-left: 6px;
    }

    &.Mui-focusVisible {
      background-color: ${(props) => props.theme.colors.transparent};
    }

    &:hover,
    &.Mui-selected {
      background-color: ${(props) => hexToRgba(props.theme.colors.primary, 0.15)};
      color: ${(props) => (props.$danger ? props.theme.colors.red : props.theme.colors.primaryDark)};
    }

    ${(props) =>
      props.$isMultiple &&
      css`
        svg {
          display: block;

          path {
            fill: ${hexToRgba(props.theme.colors.grey2, 0.3)};
          }
        }
      `}

    &.Mui-selected {
      svg {
        display: block;

        path {
          fill: ${(props) => props.theme.colors.primary};
        }
      }
    }
  }
`;
