import * as React from 'react';

export interface CloseIconProps {
  fill?: string;
}

export const CloseIcon: React.FunctionComponent<CloseIconProps> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path
        d="m12 13.6-4.8 4.8q-.325.325-.8.325-.475 0-.8-.325-.325-.325-.325-.8 0-.475.325-.8l4.8-4.8-4.8-4.8q-.325-.325-.325-.8 0-.475.325-.8.325-.325.8-.325.475 0 .8.325l4.8 4.8 4.8-4.8q.325-.325.8-.325.475 0 .8.325.325.325.325.8 0 .475-.325.8L13.6 12l4.8 4.8q.325.325.325.8 0 .475-.325.8-.325.325-.8.325-.475 0-.8-.325Z"
        fill={fill}
      />
    </svg>
  );
};
CloseIcon.defaultProps = {
  fill: '#000',
};
