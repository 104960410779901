import styled from 'styled-components';
import MuiCircularProgress from '@mui/material/CircularProgress';

export interface CircularProgressProps {
  $color?: string;
  $margin?: string;
}

export const CircularProgress = styled(MuiCircularProgress)<CircularProgressProps>`
  margin: ${(props) => props.$margin};
  svg {
    color: ${(props) => props.$color};
  }
`;
