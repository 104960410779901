import { useQuery } from '@tanstack/react-query';
import { getOrgsPromise } from 'src/utils/api';
import { QUERY_KEY_MAP } from 'src/constants';

export const useGetOrgsData = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: [QUERY_KEY_MAP.useGetOrgsData],
    queryFn: getOrgsPromise,
    refetchInterval: 60 * 4 * 1000, // 4 minutes to renew status image signed url, which expires on 5 minutes
  });

  return {
    data,
    isLoading,
    isError,
    error,
  };
};
