import styled from 'styled-components';
import MuiPaper from '@mui/material/Paper';
import { hexToRgba } from 'src/utils';
import Autocomplete from '@mui/material/Autocomplete';

export const StyledAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-clearIndicator {
    svg {
      color: ${(props) => props.theme.colors.grey1};

      path {
        fill: currentColor;
      }
    }
  }
`;

export const StyledAutoCompletePaper = styled(MuiPaper)`
  &.MuiPaper-root {
    background-color: ${(props) => props.theme.colors.appBackground};
    border-radius: 4px;
    box-shadow: ${(props) => props.theme.boxShadow.large};
    border: 1px solid ${(props) => hexToRgba(props.theme.colors.grey1, 0.1)};
    padding: 8px 10px;
    border: 1px solid ${(props) => props.theme.colors.borderColor};
    min-width: 200px;
    margin-top: 6px;
    overflow: hidden;

    .MuiAutocomplete-listbox {
      overflow: auto;
      padding: 10px 16px 10px 10px;
    }

    .autocomplete-group {
      font-size: 16px;
      font-weight: 700;
      text-transform: capitalize;
      padding: 10px 0;
      color: ${(props) => props.theme.colors.grey2};
    }
    .MuiAutocomplete-option {
      border-radius: 4px;
      font-size: 15px;
      padding: 10px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      color: ${(props) => props.theme.colors.textColor};
      transition: all 0.3s ease-out;

      &:hover,
      &.Mui-selected {
        background-color: ${(props) => hexToRgba(props.theme.colors.primary, 0.15)};
        color: ${(props) => props.theme.colors.primaryDark};
      }
    }

    .MuiAutocomplete-loading {
      color: ${(props) => props.theme.colors.textColor};
    }
  }
`;
