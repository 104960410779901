import styled, { type DefaultTheme } from 'styled-components';
import muiStyled from '@mui/material/styles/styled';
import Tooltip, { type TooltipProps as MuiTooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const StyledWrapper = styled.div`
  color: ${({ theme }) => theme.colors.grey1};
  padding: 0 10px;
  transform: translateX(10px);
  height: 24px;
  cursor: pointer;
`;

interface TooltipProps extends MuiTooltipProps {
  $scTheme: DefaultTheme;
}

export const StyledTooltip = muiStyled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ $scTheme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: $scTheme.colors.grey1,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: $scTheme?.isDarkTheme ? $scTheme.colors.pageContentColor : $scTheme.colors.grey3,
    color: $scTheme.colors.textColor,
    fontSize: 14,
    boxShadow: $scTheme.boxShadow.small,
    border: `1px solid ${$scTheme.colors.borderColor}`,

    [`& code`]: {
      backgroundColor: $scTheme.isDarkTheme ? $scTheme.colors.grey3 : $scTheme.colors.grey1,
      padding: '0 2px',
    },
  },
}));
