/* eslint-disable react-refresh/only-export-components */
import * as React from 'react';
import { Navigate, RouteObject, Outlet } from 'react-router-dom';
import { Layout } from 'src/components/Layout';
import { UI_ROUTES } from 'src/constants/routes';
import { RouteSuspense } from './RouteSuspense';

const HomePage = React.lazy(() => import('src/pages/HomePage/HomePage'));

const ZoneEditorPage = React.lazy(() => import('src/pages/ZoneEditorPage/ZoneEditorPage'));

const OrgBrowserPage = React.lazy(() => import('src/pages/OrgBrowserPage/OrgBrowserPage'));

const OrgAndDeviceList = React.lazy(
  () => import('src/pages/OrgBrowserPage/Subpages/OrgAndDeviceList/OrgAndDeviceList')
);

const OrgViewer = React.lazy(() => import('src/pages/OrgBrowserPage/Subpages/OrgViewerPage/OrgViewerPage'));

const DeviceViewerPage = React.lazy(
  () => import('src/pages/OrgBrowserPage/Subpages/DeviceViewerPage/DeviceViewerPage')
);

const UserManagementPage = React.lazy(() => import('src/pages/UserManagementPage/UserManagementPage'));

const ListUsersPage = React.lazy(() => import('src/pages/UserManagementPage/Subpages/ListUsers/ListUsers'));

const UserDetailsPage = React.lazy(
  () => import('src/pages/UserManagementPage/Subpages/UserDetailsPage/UserDetailsPage')
);

const CreateNewUserPage = React.lazy(
  () => import('src/pages/UserManagementPage/Subpages/CreateNewUserPage/CreateNewUserPage')
);

const AuditLogsPage = React.lazy(() => import('src/pages/AuditLogsPage/AuditLogsPage'));

const TasksPage = React.lazy(() => import('src/pages/TasksPage/TasksPage'));

const StoreManagementPage = React.lazy(() => import('src/pages/StoreManagementPage/StoreManagementPage'));

const ListStorePage = React.lazy(() => import('src/pages/StoreManagementPage/SubPages/ListStorePage/ListStorePage'));

const StoreDetailsPage = React.lazy(
  () => import('src/pages/StoreManagementPage/SubPages/StoreDetailsPage/StoreDetailsPage')
);

const CreateNewStorePage = React.lazy(
  () => import('src/pages/StoreManagementPage/SubPages/CreateNewStorePage/CreateNewStorePage')
);

const ViewAsPortalPage = React.lazy(() => import('src/pages/ViewAsPortal/ViewAsPortal'));

const EposUploadPage = React.lazy(() => import('src/pages/EposUploadPage/EposUploadPage'));
// const UploadPage = React.lazy(() => import('src/pages/EposUploadPage/SubPages/UploadPage/UploadPage'));
// const EposSignInPage = React.lazy(() => import('src/pages/EposUploadPage/SubPages/EposSignInPage/EposSignInPage'));
const DeviceStatusPage = React.lazy(
  () => import('src/pages/DeviceManagementPage/Subpages/DeviceStatusPage/DeviceStatusPage')
);

const ReportPage = React.lazy(() => import('src/pages/ReportPage/ReportPage'));
const ListFailedReports = React.lazy(() => import('src/pages/ReportPage/Subpages/ListFailedReports'));
const FailedReportDetails = React.lazy(() => import('src/pages/ReportPage/Subpages/FailedReportDetailsPage'));

const ZoneEditorSandbox = React.lazy(() => import('src/pages/ZoneEditorSandbox/ZoneEditorSandbox'));

const ListSandboxDevicesPage = React.lazy(() => import('src/pages/ZoneEditorSandbox/sub-pages/ListSandboxDevicesPage'));

const SandboxEditor = React.lazy(() => import('src/pages/ZoneEditorSandbox/sub-pages/SandboxEditor'));

const DeviceManagementOverview = React.lazy(
  () => import('src/pages/DeviceManagementPage/Subpages/DeviceManagementOverview')
);
const DeviceManagementPage = React.lazy(() => import('src/pages/DeviceManagementPage/DeviceManagementPage'));

const DeviceInstallPage = React.lazy(() => import('src/pages/DeviceManagementPage/Subpages/DeviceInstalls'));

const DeviceInstallDetailsPage = React.lazy(
  () => import('src/pages/DeviceManagementPage/Subpages/DeviceInstallDetailsPage')
);

const CreateNewInstallPage = React.lazy(() => import('src/pages/DeviceManagementPage/Subpages/NewInstallPage'));

export const routeConfig: Array<RouteObject> = [
  {
    path: UI_ROUTES.HOME,
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <RouteSuspense id={UI_ROUTES.HOME}>
            <HomePage />
          </RouteSuspense>
        ),
      },
      {
        path: UI_ROUTES.ORG_BROWSER,

        element: (
          <RouteSuspense id={UI_ROUTES.ORG_BROWSER}>
            <OrgBrowserPage />
          </RouteSuspense>
        ),

        children: [
          {
            index: true,
            element: (
              <RouteSuspense id={`${UI_ROUTES.ORG_BROWSER}-list-org-and-devices`}>
                <OrgAndDeviceList />
              </RouteSuspense>
            ),
          },
          {
            path: ':orgId',
            element: <Outlet />,

            children: [
              {
                index: true,
                element: (
                  <RouteSuspense id={`${UI_ROUTES.ORG_BROWSER}-orgId`}>
                    <OrgViewer />
                  </RouteSuspense>
                ),
              },
              {
                path: ':deviceId',
                element: (
                  <RouteSuspense id={`${UI_ROUTES.ORG_BROWSER}-deviceId`}>
                    <DeviceViewerPage />
                  </RouteSuspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: UI_ROUTES.USER_MANAGEMENT,
        element: (
          <RouteSuspense id={UI_ROUTES.USER_MANAGEMENT}>
            <UserManagementPage />
          </RouteSuspense>
        ),
        children: [
          {
            index: true,
            element: (
              <RouteSuspense id={`${UI_ROUTES.USER_MANAGEMENT}-list-users`}>
                <ListUsersPage />
              </RouteSuspense>
            ),
          },
          {
            path: UI_ROUTES.CREATE_NEW_USER,
            element: (
              <RouteSuspense id={UI_ROUTES.CREATE_NEW_USER}>
                <CreateNewUserPage />
              </RouteSuspense>
            ),
          },
          {
            path: ':userId',
            element: (
              <RouteSuspense id={`${UI_ROUTES.USER_MANAGEMENT}-userId`}>
                <UserDetailsPage />
              </RouteSuspense>
            ),
          },
        ],
      },
      {
        path: UI_ROUTES.AUDIT_LOGS,
        element: (
          <RouteSuspense id={UI_ROUTES.AUDIT_LOGS}>
            <AuditLogsPage />
          </RouteSuspense>
        ),
      },
      {
        path: UI_ROUTES.TASKS,
        element: (
          <RouteSuspense id={UI_ROUTES.TASKS}>
            <TasksPage />
          </RouteSuspense>
        ),
      },
      {
        path: UI_ROUTES.REPORT,
        element: (
          <RouteSuspense id={UI_ROUTES.REPORT}>
            <ReportPage />
          </RouteSuspense>
        ),
        children: [
          {
            index: true,
            element: (
              <RouteSuspense id={`${UI_ROUTES.REPORT}-list-failed-reports`}>
                <ListFailedReports />
              </RouteSuspense>
            ),
          },
          {
            path: `${UI_ROUTES.REPORT}/:cache_key`,
            element: (
              <RouteSuspense id={`${UI_ROUTES.REPORT}-cache_key`}>
                <FailedReportDetails />
              </RouteSuspense>
            ),
          },
        ],
      },
      {
        path: UI_ROUTES.STORE_MANAGEMENT,
        element: (
          <RouteSuspense id={UI_ROUTES.STORE_MANAGEMENT}>
            <StoreManagementPage />
          </RouteSuspense>
        ),
        children: [
          {
            index: true,
            element: (
              <RouteSuspense id={`${UI_ROUTES.STORE_MANAGEMENT}-list-stores`}>
                <ListStorePage />
              </RouteSuspense>
            ),
          },
          {
            path: UI_ROUTES.CREATE_NEW_STORE,
            element: (
              <RouteSuspense id={UI_ROUTES.CREATE_NEW_STORE}>
                <CreateNewStorePage />
              </RouteSuspense>
            ),
          },
          {
            path: ':storeId',
            element: (
              <RouteSuspense id={`${UI_ROUTES.STORE_MANAGEMENT}-storeId`}>
                <StoreDetailsPage />
              </RouteSuspense>
            ),
          },
        ],
      },
      {
        path: UI_ROUTES.EPOS_UPLOAD,
        element: (
          <RouteSuspense id={UI_ROUTES.EPOS_UPLOAD}>
            <EposUploadPage />
          </RouteSuspense>
        ),
        // children: [
        //   {
        //     index: true,
        //     element: (
        //       <RouteSuspense id={`${UI_ROUTES.ZONE_EDITOR}-upload`}>
        //         <UploadPage />
        //       </RouteSuspense>
        //     ),
        //   },
        //   {
        //     path: UI_ROUTES.EPOS_UPLOAD_SIGN_IN,
        //     element: (
        //       <RouteSuspense id={UI_ROUTES.EPOS_UPLOAD_SIGN_IN}>
        //         <EposSignInPage />
        //       </RouteSuspense>
        //     ),
        //   },
        // ],
      },
      {
        path: UI_ROUTES.DEVICE_MANAGEMENT,
        element: (
          <RouteSuspense id={UI_ROUTES.DEVICE_MANAGEMENT}>
            <DeviceManagementPage />
          </RouteSuspense>
        ),
        children: [
          {
            index: true,
            element: (
              <RouteSuspense id={`${UI_ROUTES.DEVICE_MANAGEMENT}-overview`}>
                <DeviceManagementOverview />
              </RouteSuspense>
            ),
          },
          {
            path: UI_ROUTES.DEVICE_STATUS,
            element: (
              <RouteSuspense id={UI_ROUTES.DEVICE_STATUS}>
                <DeviceStatusPage />
              </RouteSuspense>
            ),
          },
          {
            path: UI_ROUTES.INSTALLS,
            element: (
              <RouteSuspense id={UI_ROUTES.INSTALLS}>
                <DeviceInstallPage />
              </RouteSuspense>
            ),
          },
          {
            path: `${UI_ROUTES.INSTALLS}/:installId`,
            element: (
              <RouteSuspense id={UI_ROUTES.INSTALLS}>
                <DeviceInstallDetailsPage />
              </RouteSuspense>
            ),
          },
          {
            path: UI_ROUTES.NEW_INSTALL,
            element: (
              <RouteSuspense id={UI_ROUTES.NEW_INSTALL}>
                <CreateNewInstallPage />
              </RouteSuspense>
            ),
          },
        ],
      },
      {
        path: UI_ROUTES.ZONE_SANDBOX,
        element: (
          <RouteSuspense id={UI_ROUTES.ZONE_SANDBOX}>
            <ZoneEditorSandbox />
          </RouteSuspense>
        ),
        children: [
          {
            index: true,
            element: (
              <RouteSuspense id={`${UI_ROUTES.ZONE_SANDBOX}-list-sandbox-devices`}>
                <ListSandboxDevicesPage />
              </RouteSuspense>
            ),
          },
        ],
      },
    ],
  },

  {
    path: UI_ROUTES.ZONE_EDITOR,
    element: (
      <RouteSuspense id={UI_ROUTES.ZONE_EDITOR}>
        <ZoneEditorPage />
      </RouteSuspense>
    ),
  },

  {
    path: `${UI_ROUTES.ZONE_SANDBOX}/editor`,
    element: (
      <RouteSuspense id={`${UI_ROUTES.ZONE_SANDBOX}-sandbox-editor`}>
        <SandboxEditor />
      </RouteSuspense>
    ),
  },

  {
    path: UI_ROUTES.VIEW_AS_PORTAL,
    element: (
      <RouteSuspense id={UI_ROUTES.VIEW_AS_PORTAL}>
        <ViewAsPortalPage />
      </RouteSuspense>
    ),
  },

  {
    path: '*',
    element: <Navigate to={UI_ROUTES.HOME} replace />,
  },
];
