import ky, { HTTPError } from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';
import { ZoneSchema } from '../get-detection-zone/getDetectionZonePromise';

const EDIT_PRESENCE_ZONE_SCHEMA = z.object({
  message: z.enum(['zones created']),
});
export type EditPresenceZoneSchema = z.infer<typeof EDIT_PRESENCE_ZONE_SCHEMA>;

interface ApiPresenceZoneObj {
  name: string;
  min_time: number;
  poly: Array<Array<number>>;
  epos_filters: ZoneSchema['epos_filters'];
  category: string;
}

export interface EditPresenceZonePromiseParam {
  display_id: string;
  zones: Array<ApiPresenceZoneObj>;
}

export const editPresenceZonePromise = async (param: EditPresenceZonePromiseParam) => {
  try {
    const response = await ky
      .post(`${ENV.FRONTEND_API_URL}/edit_presence_zone`, { body: JSON.stringify(param) })
      .json();

    const result = EDIT_PRESENCE_ZONE_SCHEMA.safeParse(response);

    if (!result.success) {
      throw new Error('Invalid response');
    }

    return true;
  } catch (error) {
    if (error instanceof HTTPError) {
      const errorRes = await error.response.json();
      throw new Error(
        (
          errorRes as {
            error: {
              message: string;
            };
          }
        )?.error.message
      );
    }

    throw new Error('Failed to edit presence zone');
  }
};
