import styled from 'styled-components';

export const StyledThresholdIndicator = styled.div<{
  $fromAngle: number;
  $endAngle: number;
  $offsetAngleOfCenter: number;
}>`
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 100%;

  .offsetsSegment {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    transform: translate(0px, -50%) rotate(90deg) rotate(196deg);
    transform-origin: 50% 100% 0px;
    &.left {
      transform: translate(0px, -50%) rotate(90deg) rotate(${(props) => props.$fromAngle}deg);
    }

    &.right {
      transform: translate(0px, -50%) rotate(90deg)
        rotate(${(props) => props.$offsetAngleOfCenter + props.$fromAngle}deg);
    }
  }

  .offsetsSegmentRemove {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 255, 0, 0.4);
    transform-origin: 50% 0%;
    transform: translate(0px, 100%) rotate(172deg);
    transform-origin: 50% 0% 0px;
    transform: translate(0px, 100%) rotate(${(props) => props.$offsetAngleOfCenter}deg);
  }
`;
