import * as React from 'react';
import styled from 'styled-components';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { DialogCloseButton } from './DialogCloseButton';

const StyledDialogTitle = styled(MuiDialogTitle)`
  &.MuiDialogTitle-root {
    font-size: 24px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.textColor};
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
    display: flex;
    align-items: center;
  }
`;

export interface DialogTitleProps {
  children: React.ReactNode;
  onClose?: null | (() => void);
  id: string;
}

export const DialogTitle: React.FunctionComponent<DialogTitleProps> = ({ children, onClose, id }) => {
  return (
    <StyledDialogTitle id={id}>
      {children}
      {typeof onClose === 'function' ? <DialogCloseButton onClick={onClose} /> : null}
    </StyledDialogTitle>
  );
};

DialogTitle.defaultProps = {
  onClose: null,
};
