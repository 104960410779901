export const getInitials = (fullName?: string) => {
  if (typeof fullName !== 'string') return '';

  return fullName
    .split(' ') // split string into array of words
    .map((el) => el.charAt(0)) // get first letter of each word
    .slice(0, 2) // get first two letters
    .join('') // join letters together
    .toUpperCase(); // convert to uppercase
};
