import * as React from 'react';
import {
  HomeIcon,
  ZoneEditorIcon,
  OrgBrowserIcon,
  UserManagementIcon,
  AuditLogsIcon,
  TasksIcon,
  StoreIcon,
  EposUploadIcon,
  ReportsIcon,
  ZoneEditorSandbox,
  DeviceManagement,
} from 'src/assets/icons/navigation';

import { UI_ROUTES } from './routes';

export type Routes = Extract<
  keyof typeof UI_ROUTES,
  | 'HOME'
  | 'ZONE_EDITOR'
  | 'ORG_BROWSER'
  | 'USER_MANAGEMENT'
  | 'AUDIT_LOGS'
  | 'TASKS'
  | 'STORE_MANAGEMENT'
  | 'EPOS_UPLOAD'
  | 'DEVICE_MANAGEMENT'
  | 'REPORT'
  | 'ZONE_SANDBOX'
>;

export type RoutesIcons = Record<(typeof UI_ROUTES)[Routes], React.FunctionComponent>;

export const ROUTE_ICONS: RoutesIcons = {
  [UI_ROUTES.HOME]: HomeIcon,
  [UI_ROUTES.ZONE_EDITOR]: ZoneEditorIcon,
  [UI_ROUTES.ORG_BROWSER]: OrgBrowserIcon,
  [UI_ROUTES.USER_MANAGEMENT]: UserManagementIcon,
  [UI_ROUTES.AUDIT_LOGS]: AuditLogsIcon,
  [UI_ROUTES.TASKS]: TasksIcon,
  [UI_ROUTES.STORE_MANAGEMENT]: StoreIcon,
  [UI_ROUTES.EPOS_UPLOAD]: EposUploadIcon,
  [UI_ROUTES.DEVICE_MANAGEMENT]: DeviceManagement,
  [UI_ROUTES.REPORT]: ReportsIcon,
  [UI_ROUTES.ZONE_SANDBOX]: ZoneEditorSandbox,
};
