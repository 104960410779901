import * as React from 'react';

export const StoreIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
      <path
        d="M4.292 17.729q-.834 0-1.407-.583-.573-.584-.573-1.396V9.333q-.604-.521-.77-1.291-.167-.771.062-1.5l.854-2.834q.167-.604.646-.989.479-.386 1.084-.386h11.645q.625 0 1.094.375.469.375.656 1l.834 2.834q.208.729.01 1.448-.198.718-.739 1.281v6.479q0 .812-.573 1.396-.573.583-1.407.583Zm7.541-9.5q.459 0 .74-.354.281-.354.219-.813l-.438-2.75h-1.416V7.25q0 .396.25.688.25.291.645.291Zm-3.729 0q.396 0 .677-.291.281-.292.281-.688V4.312H7.646l-.438 2.75q-.083.438.177.803.261.364.719.364Zm-3.708 0q.333 0 .573-.26.239-.261.302-.594l.479-3.063H4.312L3.542 7q-.146.458.125.844.271.385.729.385Zm11.208 0q.479 0 .75-.385.271-.386.125-.844l-.791-2.688H14.25l.479 3.063q.063.333.302.594.24.26.573.26ZM4.292 15.75h11.416v-5.562q-.02.02-.052.02h-.052q-.521 0-.958-.177-.438-.177-.854-.573-.396.334-.875.542-.479.208-1 .208-.563 0-1.052-.208-.49-.208-.865-.542-.333.334-.802.542-.469.208-1.01.208-.584 0-1.073-.208-.49-.208-.886-.542-.437.396-.875.573-.437.177-.937.177h-.063q-.042 0-.062-.02v5.562Zm11.416 0H4.292 15.708Z"
        fill="currentColor"
      />
    </svg>
  );
};
