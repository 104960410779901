import * as React from 'react';
import { VisionrEmblem, VisionrEmblemProps } from 'src/assets/icons';
import { StyledSuspenseFallbackComponent } from './SuspenseFallbackComponent.styled';

export type SuspenseFallbackComponentProps = VisionrEmblemProps;

export const SuspenseFallbackComponent: React.FunctionComponent<SuspenseFallbackComponentProps> = ({ size }) => {
  return (
    <StyledSuspenseFallbackComponent>
      <div>
        <VisionrEmblem size={size} />
      </div>
    </StyledSuspenseFallbackComponent>
  );
};
