import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const RETRY_REINGESTOR_RESPONSE_SCHEMA = z.object({
  success: z.boolean(),
  task_id: z.string(),
});

export interface RetryTaskPromiseParams {
  config_id: string;
  user_email: string;
}

export const retryTaskPromise = async (params: RetryTaskPromiseParams) => {
  if (typeof params.config_id !== 'string') {
    throw new Error('config_id is required');
  }

  const response = await ky
    .post(`${ENV.FRONTEND_API_URL}/retry_reingestor`, {
      body: JSON.stringify(params),
    })
    .json();

  const validatedResponse = RETRY_REINGESTOR_RESPONSE_SCHEMA.safeParse(response);

  if (!validatedResponse.success) {
    // eslint-disable-next-line no-console
    console.error(validatedResponse.error);
    return false;
  }

  return validatedResponse.data?.success;
};
