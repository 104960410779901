import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { hexToRgba } from 'src/utils';
import { StyledDrawerProps } from '../Layout.styled';

export const StyledList = styled.ul`
  margin: 0 2px;
`;

export interface StyledListItemProps {
  $empty?: boolean;
}

export const StyledListItem = styled.li<StyledListItemProps>`
  &:not(:last-child) {
    margin-bottom: 4px;
  }
  ${({ $empty }) => $empty && `user-select: none;`}
`;

export const StyledNavLinkIcon = styled.div<StyledDrawerProps>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px;
  transform: scale(0.9);
`;
export const StyledNavLinkLabel = styled.p<StyledDrawerProps>`
  opacity: ${(props) => (props.$menuIsOpen ? 1 : 0)};
  transition: opacity
    ${(props) => `${props.theme?.animation.duration.fast} ${props.theme?.animation.cubicBezier.animateOutFast}`};
  font-weight: 500;
  color: ${(props) => props.theme?.colors.grey1};
  font-size: 14px;
`;

export const StyledNavLink = styled(NavLink)`
  border-radius: 4px;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-out;
  outline: none;
  border: none;
  background-color: ${(props) => props.theme?.colors.transparent};
  cursor: pointer;

  svg {
    transform: translateX(2px);
    color: ${(props) => props.theme?.colors.grey1};
  }

  &:hover {
    background-color: ${(props) => hexToRgba(props.theme?.colors.grey1 || '', 0.1)};
  }

  &.active {
    background-color: ${(props) => hexToRgba(props.theme?.colors.primary || '', 0.15)};

    ${StyledNavLinkIcon} {
      svg {
        color: ${(props) => props.theme?.colors.primary};
      }
    }
    ${StyledNavLinkLabel} {
      color: ${(props) => props.theme?.colors.primary};
    }
  }
`;
