export const SANDBOX_DEVICES = [
  {
    id: 'vr-t3jd8uhd',
    name: 'VR Wood Entrance',
    status_image_url: '/sandbox/entrance.jpg',
    status_image_time_string: new Date().toISOString(),
    is_status_image_old: false,
    events_in_last_hour: 'string',
    org: 'wood',
  },
  {
    id: 'vr-s3jd8uhd',
    name: 'VR Wood Entrance 2',
    status_image_url: '/sandbox/entrance-2.jpg',
    status_image_time_string: new Date().toISOString(),
    is_status_image_old: false,
    events_in_last_hour: 'string',
    org: 'wood',
  },
  {
    id: 'vr-y3jd8uhd',
    name: 'VR Wood Deli',
    status_image_url: '/sandbox/deli-2.jpg',
    status_image_time_string: new Date().toISOString(),
    is_status_image_old: false,
    events_in_last_hour: 'string',
    org: 'wood',
  },
  {
    id: 'vr-u3jd8uhd',
    name: 'VR Wood Aisle',
    status_image_url: '/sandbox/in-store-1.jpg',
    status_image_time_string: new Date().toISOString(),
    is_status_image_old: false,
    events_in_last_hour: 'string',
    org: 'wood',
  },
  {
    id: 'vr-m3jd8uhd',
    name: 'VR Wood Off-license',
    status_image_url: '/sandbox/off-license.jpg',
    status_image_time_string: new Date().toISOString(),
    is_status_image_old: false,
    events_in_last_hour: 'string',
    org: 'wood',
  },
  {
    id: 'vr-pljd8uhd',
    name: 'VR Wood Till',
    status_image_url: '/sandbox/till-3.jpg',
    status_image_time_string: new Date().toISOString(),
    is_status_image_old: false,
    events_in_last_hour: 'string',
    org: 'wood',
  },
];
