import { z } from 'zod';
import { type ParseResult } from 'src/utils/helpers/csv-to-json';

export const salesDataSchema = z.array(
  z
    .object({
      timestamp: z.number(),
      store_code: z.string(),
      description: z.string(),
      transaction_id: z.string(),
      value: z.number(),
      quantity: z.number(),
      is_return: z.number(),

      // optional fields
      checkout_id: z.string().optional(),
      instore_area: z.string().optional(),
      barcode: z.string().optional(),
      category_1: z.string().optional(),
      category_2: z.string().optional(),
    })
    .catchall(z.string())
);

export type SalesData = z.infer<typeof salesDataSchema>;

const SALES_FIELDS = ['timestamp', 'store_code', 'description', 'transaction_id', 'value', 'quantity', 'is_return'];

export const getMissingSalesFields = (data: ParseResult['data']): string[] => {
  if (!Array.isArray(data) || data.length === 0) return [];

  const firstFive = data.slice(0, 5);
  const keys = firstFive.map((item) => Object.keys(item)).flat();
  const uniqueKeys = Array.from(new Set(keys));

  const missingFields = SALES_FIELDS.filter((field) => !uniqueKeys.includes(field));

  return missingFields;
};
