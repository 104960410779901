import styled from 'styled-components';
import MuiDialogContent from '@mui/material/DialogContent';

export const DialogContent = styled(MuiDialogContent)<{
  $padding?: string;
}>`
  &.MuiDialogContent-root {
    padding: ${(props) => props.$padding || '24px'};

    color: ${(props) => props.theme.colors.textColor};
    position: relative;
  }
`;
