/**
 *
 * @param hex - hex color to convert to rgba
 * @param a - alpha value
 * @param skipAlpha  - if true, alpha will be skipped (should only be used in test cases where alpha is not needed)
 * @returns string - rgba color
 */
export const hexToRgba = (hex: string, a = 1, skipAlpha = false): string => {
  if (typeof hex !== 'string' || hex.length < 4) return '';

  let r = '0';
  let g = '0';
  let b = '0';

  // 3 digits hex - starts with #
  if (hex.length === 4) {
    r = `0x${hex[1]}${hex[1]}`;
    g = `0x${hex[2]}${hex[2]}`;
    b = `0x${hex[3]}${hex[3]}`;

    // 6 digits
  } else if (hex.length === 7) {
    r = `0x${hex[1]}${hex[2]}`;
    g = `0x${hex[3]}${hex[4]}`;
    b = `0x${hex[5]}${hex[6]}`;
  }

  return skipAlpha ? `rgb(${+r}, ${+g}, ${+b})` : `rgba(${+r}, ${+g}, ${+b}, ${+a})`;
};
