export const UI_ROUTES = {
  HOME: '/',
  ZONE_EDITOR: '/zone-editor',
  ORG_BROWSER: '/org-browser',
  USER_MANAGEMENT: '/user-management',
  CREATE_NEW_USER: '/user-management/create-new-user',
  AUDIT_LOGS: '/audit-logs',
  TASKS: '/tasks',
  STORE_MANAGEMENT: '/store-management',
  CREATE_NEW_STORE: '/store-management/create-new-store',
  VIEW_AS_PORTAL: '/view_as',
  EPOS_UPLOAD: '/epos-upload',
  EPOS_UPLOAD_SIGN_IN: '/epos-upload/sign-in',
  DEVICE_MANAGEMENT: '/device-management',
  DEVICE_STATUS: '/device-management/device-status',
  INSTALLS: '/device-management/installs',
  NEW_INSTALL: '/device-management/new-install',
  REPORT: '/report',
  ZONE_SANDBOX: '/zone-sandbox',
} as const;

export type UiRoutes = (typeof UI_ROUTES)[keyof typeof UI_ROUTES];

/** Internalise all labels */
export const ROUTES = [
  {
    path: UI_ROUTES.HOME,
    label: 'navigation.home',
  },
  {
    path: UI_ROUTES.ORG_BROWSER,
    label: 'navigation.orgBrowser',
  },
  {
    path: UI_ROUTES.ZONE_EDITOR,
    label: 'navigation.zoneEditor',
  },
  {
    path: UI_ROUTES.TASKS,
    label: 'navigation.tasks',
  },
  {
    path: UI_ROUTES.REPORT,
    label: 'navigation.reports',
  },
  {
    path: UI_ROUTES.USER_MANAGEMENT,
    label: 'navigation.userManagement',
  },
  {
    path: UI_ROUTES.STORE_MANAGEMENT,
    label: 'navigation.storeManagement',
  },
  {
    path: UI_ROUTES.EPOS_UPLOAD,
    label: 'navigation.eposUpload',
    search: '?tz=Europe%2FDublin',
  },
  {
    path: UI_ROUTES.AUDIT_LOGS,
    label: 'navigation.auditLogs',
  },
  {
    path: UI_ROUTES.DEVICE_MANAGEMENT,
    label: 'navigation.deviceManagement',
  },
  {
    path: UI_ROUTES.ZONE_SANDBOX,
    label: 'navigation.zoneSandbox',
  },
];

export const EXTERNAL_LINK_PROPS = {
  target: '_blank',
  rel: 'noopener noreferrer',
};
