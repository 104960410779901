import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

interface ManageInternalUserFlagPromiseParams {
  action: 'RETRIEVE' | 'ADD' | 'DELETE';
  user_email: string;
}

const INTERNAL_USER_RESPONSE_SCHEMA = z.object({
  data: z.boolean(),
  error: z.string().nullable().optional(),
});

export const manageInternalUserFlagPromise = async (params: ManageInternalUserFlagPromiseParams) => {
  try {
    const res = await ky
      .post(`${ENV.FRONTEND_API_URL}/internal_user_management`, {
        body: JSON.stringify(params),
      })
      .json();

    const validatedRes = INTERNAL_USER_RESPONSE_SCHEMA.safeParse(res);

    if (!validatedRes.success) {
      return false;
    }

    return validatedRes.data.data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('manageInternalUserFlagPromise', e);

    return false;
  }
};
