import styled from 'styled-components';
import { hexToRgba } from 'src/utils';
import { Button } from '../Button';

export const StyledWrapper = styled.div`
  display: flex;

  & > :first-child {
    margin-right: 24px;
  }
`;

interface CommonStyledProps {
  $color?: string;
}

export const StyledIcon = styled.div<CommonStyledProps>`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.$color || props.theme.colors.textColor};
  border: 1px solid ${(props) => props.$color || props.theme.colors.borderColor};
  background-color: ${(props) => props.theme.colors.pageContentColor};
  margin-bottom: 18px;
`;

export const StyledButton = styled(Button)<CommonStyledProps>`
  &.MuiButton-containedPrimary {
    background-color: ${(props) => props.$color || props.theme.colors.primary};
    &:hover {
      background-color: ${(props) => hexToRgba(props.$color || props.theme.colors.primary, 0.6)};
    }
    &:active {
      background-color: ${(props) => props.$color || props.theme.colors.primary};
    }
    &.Mui-disabled {
      background-color: ${(props) => hexToRgba(props.$color || props.theme.colors.primary, 0.6)};
    }
  }
`;

export const StyledSecondaryButton = styled(Button)<CommonStyledProps>`
  &.MuiButton-containedSecondary {
    background-color: ${(props) =>
      props?.theme.isDarkTheme ? props.theme.colors.pageContentColor : props.theme.colors.grey3};

    &:hover {
      background-color: ${(props) =>
        hexToRgba(props?.theme.isDarkTheme ? props.theme.colors.pageContentColor : props.theme.colors.grey3, 0.9)};
      box-shadow: none;
      color: ${(props) => props.theme.colors.primaryDark};
    }
  }
`;
