import styled from 'styled-components';
import { Command } from 'cmdk';
import { hexToRgba } from 'src/utils';

export const CommandDialog = styled(Command.Dialog)`
  &[cmdk-root] {
    box-shadow: ${(props) => props.theme.boxShadow.small};
    border: 1px solid ${(props) => props.theme.colors.borderColor};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    max-width: 640px;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 100ms ease;
  }

  &[cmdk-root],
  [cmdk-input],
  [cmdk-footer] {
    background-color: ${(props) => props.theme.colors.appBackground};
  }
  [cmdk-empty],
  [cmdk-input],
  [cmdk-item] {
    color: ${(props) => props.theme.colors.textColor};
  }

  [cmdk-shortcuts] kbd,
  [cmdk-group-heading] {
    color: ${(props) => props.theme.colors.grey1} !important;
    display: block;
    white-space: nowrap;
  }

  [cmdk-input] {
    border: none;
    width: 100%;
    font-size: 15px;
    padding: 16px 16px 8px 16px;
    outline: none;

    &::placeholder {
      color: ${(props) => props.theme.colors.grey2};
    }
  }

  [cmdk-divider] {
    height: 0.5px;
    background-color: ${(props) => props.theme.colors.borderColor};
    margin: 4px 0;
  }

  [cmdk-group] {
    margin-top: 6px;
  }

  [cmdk-group-heading] {
    user-select: none;
    padding: 0 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background-color: ${(props) =>
      props.theme.isDarkTheme
        ? hexToRgba(props.theme.colors.pageContentColor, 1)
        : hexToRgba(props.theme.colors.inputBorderColor, 0.2)};
    display: inline-block;
    width: min-content;
    border-radius: 4px;
    margin-bottom: 6px;
  }

  [cmdk-item] {
    content-visibility: auto;
    cursor: pointer;
    height: 40px;
    border-radius: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 8px;
    user-select: none;
    will-change: background, color;
    transition: all 150ms ease;
    transition-property: none;

    &[data-selected='true'] {
      background: ${(props) =>
        props.theme.isDarkTheme
          ? hexToRgba(props.theme.colors.pageContentColor, 1)
          : hexToRgba(props.theme.colors.inputBorderColor, 0.2)};
    }

    &[data-disabled='true'] {
      cursor: not-allowed;
    }

    &:active,
    &:focus,
    &:hover {
      transition-property: background;
      background: ${(props) =>
        props.theme.isDarkTheme
          ? hexToRgba(props.theme.colors.pageContentColor, 1)
          : hexToRgba(props.theme.colors.inputBorderColor, 0.2)};
    }

    & + [cmdk-item] {
      margin-top: 1px;
    }

    svg {
      transform: scale(0.8);
      margin-right: 6px;
    }
  }

  [cmdk-list] {
    padding: 0 8px;
    height: 393px;
    overflow: auto;
    overscroll-behavior: contain;
    scroll-padding-block-end: 40px;
    transition: 100ms ease;
    transition-property: height;
    padding-bottom: 40px;
  }

  [cmdk-shortcuts] {
    display: flex;
    margin-left: auto;
    gap: 8px;

    kbd {
      font-size: 12px;
      min-width: 20px;
      padding: 4px;
      height: 20px;
      border-radius: 4px;
      background-color: ${(props) =>
        props.theme.isDarkTheme
          ? hexToRgba(props.theme.colors.pageContentColor, 1)
          : hexToRgba(props.theme.colors.inputBorderColor, 0.2)};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }

    span {
      color: ${(props) => props.theme.colors.grey1};
    }
  }

  [cmdk-footer] {
    display: flex;
    height: 40px;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 8px;
    border-top: 1px solid ${(props) => props.theme.colors.borderColor};
    border-radius: 0 0 12px 12px;

    svg {
      width: 20px;
      height: 20px;
      filter: grayscale(1);
      margin-right: auto;
    }
  }

  [cmdk-empty] {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    white-space: pre-wrap;
  }
`;

export const StyledLoadingState = styled.div`
  position: relative;
  height: 56px;
`;
