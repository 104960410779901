import * as React from 'react';
import { useGlobalStore } from 'src/hooks/use-app-wide-store';
import { useIsTablet } from 'src/hooks/use-viewport';

export interface UseMenuToggleResult {
  toggleMenu: () => void;
  menuIsOpen: boolean;
  handleMouseOver: () => void;
  handleMouseExist: () => void;
  drawerType: 'temporary' | 'permanent';
  handleCloseMobileMenu: () => void;
}

export const useMenuToggle = (): UseMenuToggleResult => {
  const prefersWideDashboardMenu = useGlobalStore((state) => state.prefersWideDashboardMenu);
  const setPrefersWideDashboardMenu = useGlobalStore((state) => state.setPrefersWideDashboardMenu);
  const isTablet = useIsTablet();

  // adjust drawer type based on screen size
  const drawerType = isTablet ? 'temporary' : 'permanent';

  // used to manage the drawer width on hover state only
  const [localMenuIsOpen, setLocalMenuIsOpen] = React.useState(prefersWideDashboardMenu);

  const handleDrawerMenuIsOpen = () => {
    setLocalMenuIsOpen(true);
  };

  const handleDrawerClose = () => {
    setLocalMenuIsOpen(false);
  };

  const toggleMenu = () => {
    /** Menu is closed by default but if the user wants it open, persist it */
    if (!prefersWideDashboardMenu) {
      setLocalMenuIsOpen(true);
      setPrefersWideDashboardMenu(true);
      return;
    }

    setLocalMenuIsOpen(false);
    setPrefersWideDashboardMenu(false);
  };

  const handleMouseOver = () => {
    if (!localMenuIsOpen) {
      handleDrawerMenuIsOpen();
    }
  };

  const handleMouseExist = () => {
    // only close the menu if the user does not prefersWideDashboardMenu
    if (prefersWideDashboardMenu) {
      return;
    }

    handleDrawerClose();
  };

  const menuIsOpen = isTablet ? prefersWideDashboardMenu : localMenuIsOpen;

  const handleCloseMobileMenu = () => {
    if (!isTablet) return;

    // only trigger when the menu is open on mobile and tablets
    setLocalMenuIsOpen(false);
    setPrefersWideDashboardMenu(false);
  };

  return {
    toggleMenu,
    menuIsOpen,
    handleMouseOver,
    handleMouseExist,
    drawerType,
    handleCloseMobileMenu,
  };
};
