import { VariantType } from 'notistack';

import { SNACKBAR_TYPES } from 'src/constants';

export const SNACKBAR_BG_COLORS_LIGHT: Record<VariantType, string> = {
  [SNACKBAR_TYPES.error]: '#FFEBEE',
  [SNACKBAR_TYPES.info]: '#C0E0FF',
  [SNACKBAR_TYPES.success]: '#E8F5E9',
  [SNACKBAR_TYPES.warning]: '#FFF3E0',
  [SNACKBAR_TYPES.default]: '#C0E0FF',
} as const;

export const SNACKBAR_BG_COLORS_DARK: Record<VariantType, string> = {
  [SNACKBAR_TYPES.error]: 'rgba(244, 67, 54, 0.15)',
  [SNACKBAR_TYPES.info]: 'rgba(0, 79, 255, 0.15)',
  [SNACKBAR_TYPES.success]: 'rgba(76, 175, 80, 0.15);',
  [SNACKBAR_TYPES.warning]: 'rgba(255, 152, 0, 0.15)',
  [SNACKBAR_TYPES.default]: 'rgba(0, 79, 255, 0.15);',
} as const;

export const SNACKBAR_COLORS: Record<VariantType, string> = {
  [SNACKBAR_TYPES.error]: '#F44336',
  [SNACKBAR_TYPES.info]: '#004FFF',
  [SNACKBAR_TYPES.success]: '#4CAF50',
  [SNACKBAR_TYPES.warning]: '#FF9800',
  [SNACKBAR_TYPES.default]: '#004FFF',
} as const;
