import { ListUsersDataSchema } from './types-and-schema';

export const getSearchAutoComplete = (users: ListUsersDataSchema) => {
  return users.map((user) => ({
    id: user.uuid,
    label: user.user_real_name,
    value: user.user_real_name,
    org: user.orgs[0]?.org_name,
    org_id: user.orgs[0]?.org_id,
    userName: user.user_name,
  }));
};
