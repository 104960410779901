/**
 * Source
 * https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid
 */
export const generateUUID = () => {
  // Public Domain/MIT
  let d = new Date().getTime();
  let d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-var
    let r = Math.random() * 16;
    if (d > 0) {
      // eslint-disable-next-line no-bitwise
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // eslint-disable-next-line no-bitwise
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
  });
  return uuid;
};

export const generateVRID = () => {
  // max of 6
  const uniqueId = generateUUID().substring(0, 6);

  // add VR prefix
  return `VR-${uniqueId}`;
};
