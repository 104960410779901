import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledFallbackUiWrapper = styled.div`
  margin: 40px auto;
  max-width: 400px;

  background-color: ${(props) => props.theme.colors.appBackground};
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & p {
    margin: 20px 0 40px 0;
    text-align: center;
    color: ${(props) => props.theme.colors.grey1};
  }
`;
