import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Command } from 'cmdk';
import uniqBy from 'lodash/uniqBy';
import { useTranslation } from 'react-i18next';
import capitalize from '@mui/material/utils/capitalize';
import { useListExternalUsers } from 'src/hooks';
import { SuspenseFallbackComponent } from 'src/components/atoms';
import { UI_ROUTES } from 'src/constants/routes';
import { StyledLoadingState } from './CommandMenu.styled';

export interface ViewUserDetailsGroupProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ViewUserDetailsGroup: React.FunctionComponent<ViewUserDetailsGroupProps> = ({ setOpen }) => {
  const { t } = useTranslation();
  const { data: externalUsersData, isLoading } = useListExternalUsers();
  const navigate = useNavigate();

  const externalUsers = uniqBy((externalUsersData?.paginatedData?.users || [])?.flat(), (user) =>
    user.user_real_name?.toLowerCase?.()?.trim?.()
  );

  const onExternalUserSelect = (value: string) => {
    const userId = (externalUsers || []).find((user) => user.user_name?.toLowerCase() === value?.toLowerCase())?.uuid;

    if (!userId) return;

    navigate(`${UI_ROUTES.USER_MANAGEMENT}/${userId}`);
    setOpen(false);
  };

  return (
    <Command.Group heading={t('navigation.userManagement')}>
      {isLoading ? (
        <StyledLoadingState>
          <SuspenseFallbackComponent size={30} />
        </StyledLoadingState>
      ) : null}
      {externalUsers.map((user) => {
        const label = capitalize(user?.user_real_name || '');
        return (
          <Command.Item key={user?.uuid} onSelect={onExternalUserSelect} value={user?.user_name}>
            {label}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <div cmdk-shortcuts="">
              <span>View user details</span>
            </div>
          </Command.Item>
        );
      })}
    </Command.Group>
  );
};
