import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const EDIT_DEVICE_NAME_SCHEMA = z.object({
  display_name: z.string(),
  device_id: z.string(),
});

export type EditDeviceNameSchema = z.infer<typeof EDIT_DEVICE_NAME_SCHEMA>;

export const editDeviceNamePromise = async (param: EditDeviceNameSchema) => {
  if (!EDIT_DEVICE_NAME_SCHEMA.safeParse(param).success) {
    // eslint-disable-next-line no-console
    console.error('Invalid Params');
    throw new Error('Invalid Params');
  }

  try {
    const response = await ky.post(`${ENV.FRONTEND_API_URL}/update_device_name`, { body: JSON.stringify(param) });

    if (response.ok) {
      return true;
    }

    return false;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in editDeviceNamePromise', error);
    throw new Error('Error in editDeviceNamePromise');
  }
};
