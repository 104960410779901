import styled from 'styled-components';
import { StyledDrawerProps } from '../Layout.styled';

export const StyledDrawerActions = styled.ul`
  /* margin-top: auto; */
  padding-top: 20px;

  svg {
    path {
      fill: ${(props) => props.theme.colors.grey1};
    }
  }

  border-top: 1px solid ${(props) => props.theme.colors.borderColor};
`;

export const StyledDarkModeSwitcher = styled.div<StyledDrawerProps>`
  transform: ${(props) => (props.$menuIsOpen ? 'translateX(12px)' : 'translateX(-4px)')};
  transition: transform
    ${(props) => `${props.theme.animation.duration.fast} ${props.theme.animation.cubicBezier.animateOutFast}`};
`;
