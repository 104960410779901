import * as React from 'react';
import { SuspenseFallbackComponent } from 'src/components/atoms';
import { ErrorBoundary } from 'src/components/ErrorBoundary';

export interface RouteSuspenseProps {
  children: React.ReactElement;
  id: string;
}

export const RouteSuspense: React.FunctionComponent<RouteSuspenseProps> = ({ children, id }) => {
  return (
    <ErrorBoundary componentId={`RouteSuspense-${id}`}>
      <React.Suspense fallback={<SuspenseFallbackComponent />}>{children}</React.Suspense>
    </ErrorBoundary>
  );
};
