import ky from 'ky';
import {
  REMOVE_DETECTION_ZONES_SCHEMA,
  RemoveDetectionZonesSchema,
} from 'src/hooks/api/use-edit-detection-zone/useRemoveDetectionZones';
import { ENV } from 'src/utils/env';
import { z } from 'zod';

export const REMOVE_DETECTION_ZONES_RESPONSE_SCHEMA = z.object({
  message: z.string(),
});

export type RemoveDetectionZonesResponseSchema = z.infer<typeof REMOVE_DETECTION_ZONES_RESPONSE_SCHEMA>;

export const removeDetectionZonesPromise = async (params: RemoveDetectionZonesSchema) => {
  const parsedParams = REMOVE_DETECTION_ZONES_SCHEMA.safeParse(params);

  if (!parsedParams.success) {
    throw new Error('invalid params');
  }

  const response = await ky.post(`${ENV.FRONTEND_API_URL}/edit_detection_zone`, {
    body: JSON.stringify({
      display_id: parsedParams.data.display_id,
    }),
  });

  const validatedResponse = REMOVE_DETECTION_ZONES_RESPONSE_SCHEMA.safeParse(await response.json());

  if (!validatedResponse.success) {
    // eslint-disable-next-line no-console
    console.error(
      '🚀 ~ file: removeDetectionZonePromise.ts:29 ~ createStorePromise ~ validatedResponse',
      validatedResponse
    );
    return false;
  }

  return validatedResponse.data.message?.toLowerCase()?.includes('successfully');
};
