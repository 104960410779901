import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const CLEAR_CACHE_SCHEMA = z.object({
  devices: z.array(z.string()),
  start_time: z.string(),
  end_time: z.string(),
});

export type ClearCacheSchema = z.infer<typeof CLEAR_CACHE_SCHEMA>;

const CLEAR_CACHE_RESPONSE_SCHEMA = z.object({
  success: z.boolean(),
});

export const clearCachePromise = async (params: ClearCacheSchema) => {
  const parsedParams = CLEAR_CACHE_SCHEMA.safeParse(params);

  if (!parsedParams.success) {
    // eslint-disable-next-line no-console
    console.error(parsedParams.error);
    return false;
  }

  const response = await ky
    .post(`${ENV.FRONTEND_API_URL}/clear_cache`, {
      body: JSON.stringify({
        ...params,
        devices: parsedParams.data.devices,
      }),
    })
    .json();

  const parsedResponse = CLEAR_CACHE_RESPONSE_SCHEMA.safeParse(response);

  if (!parsedResponse) {
    // eslint-disable-next-line no-console
    console.error(response);
    return false;
  }

  return parsedResponse;
};
