import * as React from 'react';

export const ChevronDown: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
      <path
        d="M10 12.562q-.188 0-.365-.072-.177-.073-.343-.219L5.521 8.5q-.292-.292-.271-.708.021-.417.292-.688.312-.312.708-.302.396.01.688.302L10 10.188l3.083-3.084q.292-.292.677-.292.386 0 .698.313.292.292.292.698 0 .406-.292.698l-3.75 3.75q-.166.146-.343.219-.177.072-.365.072Z"
        fill="#919EAB"
      />
    </svg>
  );
};
