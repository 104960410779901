import * as React from 'react';
import { COLORS } from 'src/constants';
import { useGetUserDetails } from 'src/hooks';
import { StyledProfileBar, StyledNameAndEmail, StyledName, StyledEmail } from './ProfileBar.styled';
import { Avatar } from '../../../atoms';
import { NavBarComponentsCommonProps } from '../../common';

export type ProfileBarProps = NavBarComponentsCommonProps;

export const ProfileBar: React.FunctionComponent<ProfileBarProps> = ({ menuIsOpen }) => {
  const { userDetails } = useGetUserDetails();
  const fullName = `${userDetails?.first_name || ''} ${userDetails?.last_name || ''}`.trim();

  return (
    <StyledProfileBar $menuIsOpen={menuIsOpen}>
      <Avatar fullName={fullName} />

      <StyledNameAndEmail>
        <StyledName>{fullName}</StyledName>
        <StyledEmail $color={COLORS.grey1}>{userDetails?.email}</StyledEmail>
      </StyledNameAndEmail>
    </StyledProfileBar>
  );
};
