import * as React from 'react';

export const VisionrLogo: React.FunctionComponent = () => {
  return (
    <svg width="154" height="43" viewBox="0 0 154 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.1802 11.0012H53.3032L59.8249 27.8016L66.0711 11.0012H69.2243L61.264 31.0305H58.1104L50.1802 11.0012Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.3672 11H74.0324V14.0099H71.3672V11ZM71.3672 16.5829H74.0324V31.0287H71.3672V16.5829Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.2259 26.7341H79.7871C79.9098 27.8286 80.3691 28.613 81.165 29.0872C81.9338 29.5542 82.8382 29.8109 83.7677 29.826H83.8596C84.7997 29.8355 85.7253 29.6181 86.5387 29.1968C87.3445 28.7775 87.7476 28.2393 87.7478 27.5824C87.8092 26.8161 87.4419 26.278 86.6458 25.9679C85.7366 25.6257 84.7935 25.3601 83.829 25.1745C83.6453 25.1382 83.4565 25.1017 83.2626 25.0651C83.0687 25.0285 82.8695 24.9829 82.665 24.9283C81.3918 24.674 80.1722 24.2398 79.0521 23.6421C77.9699 23.0586 77.4901 22.0006 77.613 20.4682C77.7355 19.2825 78.21 18.3569 79.0363 17.6913C79.8626 17.0257 81.2352 16.5925 83.154 16.3916C83.501 16.3916 83.8684 16.4007 84.2563 16.419C84.6443 16.4372 85.0424 16.4737 85.4506 16.5284C86.4772 16.6769 87.4457 17.0532 88.2674 17.6229C89.1246 18.2068 89.5941 19.2284 89.6758 20.6874H87.0133C86.8701 19.8848 86.4873 19.3102 85.8649 18.9636C85.2555 18.6206 84.5492 18.4403 83.829 18.4438H83.4922C82.6525 18.4934 81.8385 18.7232 81.1193 19.1137C80.3944 19.5062 80.0323 19.9577 80.0328 20.4682C79.9714 21.198 80.3388 21.7178 81.1351 22.0278C82.0558 22.3746 83.0085 22.6493 83.9824 22.8488C84.1864 22.8855 84.3906 22.9265 84.5949 22.9718C84.7992 23.0171 85.003 23.0581 85.2061 23.0949C86.4564 23.3674 87.6609 23.786 88.7887 24.3399C89.8702 24.8781 90.411 25.813 90.4113 27.1444V27.7464C90.3089 28.7313 89.717 29.6388 88.6355 30.4689C87.554 31.299 85.9722 31.7596 83.89 31.8508H83.7371C83.7089 31.8362 83.6777 31.8269 83.6453 31.8235H83.5534C83.2057 31.8051 82.8383 31.7778 82.4511 31.7413C82.0604 31.7043 81.672 31.6495 81.2876 31.5772C80.2283 31.3684 79.2443 30.9263 78.4248 30.2912C77.5569 29.6346 77.1232 28.4489 77.1235 26.7341H78.2259V26.7341Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.5347 11H96.2V14.0099H93.5347V11ZM93.5347 16.5829H96.2V31.0287H93.5347V16.5829Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.097 18.8266C102.138 17.2033 104.108 16.3916 107.006 16.3914C107.19 16.3914 107.373 16.3959 107.557 16.405C107.741 16.4147 107.924 16.4281 108.109 16.446C109.64 16.6467 111.048 17.3399 112.334 18.5256C113.62 19.7113 114.263 21.654 114.263 24.3538C114.257 24.8214 114.211 25.2879 114.125 25.7492C114.024 26.3112 113.854 26.8617 113.62 27.391C113.171 28.3759 112.436 29.2789 111.415 30.0999C110.394 30.9208 108.925 31.3678 107.006 31.4408C104.72 31.5502 102.904 31.044 101.556 29.9221C100.209 28.8002 99.5356 26.9441 99.5356 24.3539C99.5356 22.2925 100.056 20.4501 101.097 18.8266ZM103.731 28.2119C104.649 29.0696 105.638 29.4983 106.7 29.498H106.792C107.996 29.4801 109.093 28.9602 110.083 27.9384C111.073 26.9166 111.497 25.5028 111.354 23.6971C111.292 21.9459 110.787 20.628 109.839 19.7433C108.889 18.8587 107.843 18.4163 106.7 18.4163C105.536 18.4163 104.48 18.8905 103.531 19.8391C102.581 20.7876 102.138 22.2196 102.2 24.1349C102.301 25.9956 102.811 27.3545 103.731 28.2119Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.631 16.5827H120.263V18.5254C120.447 17.9234 121.049 17.3944 122.07 16.9384C123.099 16.4807 124.227 16.2286 125.376 16.1996H125.683C126.899 16.168 128.093 16.4949 129.081 17.13C130.082 17.7686 130.521 18.8814 130.398 20.4682V31.0301H127.765V20.906C127.765 20.067 127.469 19.4422 126.877 19.0316C126.292 18.6232 125.568 18.406 124.826 18.416C124.704 18.416 124.576 18.4206 124.443 18.4297C124.311 18.4387 124.182 18.4528 124.061 18.4706C123.1 18.6348 122.227 19.0817 121.442 19.8114C120.657 20.5411 120.264 21.5535 120.263 22.8487V31.0301H117.631V16.5827Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.45 11.001H146.809C148.381 11.001 149.718 11.457 150.821 12.369C151.923 13.2811 152.586 14.5489 152.811 16.1724C152.811 16.5008 152.79 16.8519 152.749 17.2259C152.706 17.6104 152.624 17.9906 152.505 18.3619C152.302 18.9043 151.991 19.4095 151.587 19.8531C151.157 20.3367 150.524 20.7607 149.687 21.1251C150.81 21.6176 151.529 22.2606 151.846 23.0541C152.161 23.8409 152.332 24.6679 152.351 25.5033V26.5703C152.328 27.3059 152.364 28.042 152.459 28.773C152.494 29.0956 152.609 29.4071 152.798 29.6843C152.986 29.9614 153.241 30.197 153.545 30.3737V31.0304H149.933C149.789 30.6655 149.682 30.2901 149.611 29.9084C149.538 29.5191 149.492 29.1262 149.473 28.7318C149.453 28.5499 149.437 28.3584 149.428 28.1573C149.417 27.9568 149.412 27.7653 149.412 27.5827V27.2817C149.388 26.2174 149.214 25.1601 148.892 24.135C148.586 23.2047 147.565 22.6301 145.829 22.4113H138.359V31.0304H135.45V11.001ZM146.075 20.0307C146.134 20.0487 146.196 20.058 146.258 20.0581H146.442C147.381 20.0581 148.172 19.7115 148.815 19.0182C149.435 18.3785 149.787 17.5659 149.81 16.7198V16.5556C149.789 15.8079 149.437 15.1102 148.754 14.4625C148.07 13.8152 146.931 13.4458 145.339 13.3542H138.359V20.0307H146.075Z"
        fill="url(#paint0_linear_510_527)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4766 2.93219L2.97235 18.2269C1.30255 19.8741 1.30255 22.5448 2.97235 24.192L18.4766 39.4867C20.1464 41.134 22.8536 41.134 24.5234 39.4867L40.0277 24.192C41.6975 22.5448 41.6975 19.8741 40.0277 18.2269L24.5234 2.93218C22.8536 1.28495 20.1464 1.28495 18.4766 2.93219ZM1.75613 17.0271C-0.585377 19.3369 -0.585375 23.082 1.75613 25.3918L17.2603 40.6865C19.6018 42.9964 23.3982 42.9964 25.7397 40.6865L41.2439 25.3918C43.5854 23.082 43.5854 19.3369 41.2439 17.0271L25.7397 1.7324C23.3982 -0.577466 19.6018 -0.577465 17.2603 1.7324L1.75613 17.0271Z"
        fill="url(#paint1_linear_510_527)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0484 24.1789C18.8954 24.1789 18.754 24.259 18.6769 24.3894L16.6703 27.7829C16.5922 27.9149 16.5922 28.0783 16.6703 28.2103L18.6769 31.6039C18.754 31.7342 18.8954 31.8143 19.0484 31.8143H23.0925C23.2455 31.8143 23.3869 31.7342 23.4639 31.6039L25.4706 28.2103C25.5487 28.0783 25.5487 27.9149 25.4706 27.7829L23.4639 24.3894C23.3869 24.259 23.2455 24.1789 23.0925 24.1789H19.0484ZM24.2049 23.1168C24.1278 22.9865 23.9864 22.9063 23.8335 22.9063H18.3074C18.1545 22.9063 18.0131 22.9865 17.936 23.1168L15.1768 27.7829C15.0987 27.9149 15.0987 28.0783 15.1768 28.2103L17.936 32.8764C18.0131 33.0067 18.1545 33.0869 18.3074 33.0869H23.8335C23.9864 33.0869 24.1278 33.0067 24.2049 32.8764L26.9641 28.2103C27.0422 28.0783 27.0422 27.9149 26.9641 27.7829L24.2049 23.1168Z"
        fill="url(#paint2_linear_510_527)"
      />
      <path
        d="M16.3404 27.6148C16.3404 27.3806 16.1479 27.1906 15.9104 27.1906H6.02044C5.54548 27.1906 5.16044 27.5705 5.16044 28.039V28.2935C5.16044 28.5278 5.35296 28.7177 5.59044 28.7177H15.9104C16.1479 28.7177 16.3404 28.5278 16.3404 28.2935V27.6148Z"
        fill="url(#paint3_linear_510_527)"
      />
      <path
        d="M26.2304 14.1256C26.2304 13.8914 26.423 13.7015 26.6604 13.7015H36.5504C37.0254 13.7015 37.4104 14.0813 37.4104 14.5498V14.8043C37.4104 15.0386 37.2179 15.2285 36.9804 15.2285H26.6604C26.423 15.2285 26.2304 15.0386 26.2304 14.8043V14.1256Z"
        fill="url(#paint4_linear_510_527)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0484 10.6048C18.8954 10.6048 18.754 10.685 18.6769 10.8153L16.6703 14.2088C16.5922 14.3409 16.5922 14.5042 16.6703 14.6363L18.6769 18.0298C18.754 18.1601 18.8954 18.2403 19.0484 18.2403H23.0925C23.2455 18.2403 23.3869 18.1601 23.4639 18.0298L25.4706 14.6363C25.5487 14.5042 25.5487 14.3409 25.4706 14.2088L23.4639 10.8153C23.3869 10.685 23.2455 10.6048 23.0925 10.6048H19.0484ZM24.2049 9.54273C24.1278 9.41241 23.9864 9.33228 23.8335 9.33228H18.3074C18.1545 9.33228 18.0131 9.41242 17.936 9.54273L15.1768 14.2088C15.0987 14.3409 15.0987 14.5042 15.1768 14.6363L17.936 19.3024C18.0131 19.4327 18.1545 19.5128 18.3074 19.5128H23.8335C23.9864 19.5128 24.1278 19.4327 24.2049 19.3024L26.9641 14.6363C27.0422 14.5042 27.0422 14.3409 26.9641 14.2088L24.2049 9.54273Z"
        fill="url(#paint5_linear_510_527)"
      />
      <path
        d="M15.4804 20.8702C15.4804 20.636 15.673 20.446 15.9104 20.446H26.2304C26.4679 20.446 26.6604 20.636 26.6604 20.8702V21.5489C26.6604 21.7832 26.4679 21.9731 26.2304 21.9731H15.9104C15.673 21.9731 15.4804 21.7832 15.4804 21.5489V20.8702Z"
        fill="url(#paint6_linear_510_527)"
      />
      <path
        d="M27.8276 17.6101C27.9034 17.4754 28.0473 17.3919 28.2035 17.3919H31.9974C32.1536 17.3919 32.2975 17.4754 32.3733 17.6101L34.2844 21.0036C34.3566 21.1317 34.3566 21.2875 34.2844 21.4156L32.3733 24.8091C32.2975 24.9438 32.1536 25.0273 31.9974 25.0273H28.2035C28.0473 25.0273 27.9034 24.9438 27.8276 24.8091L25.9165 21.4156C25.8443 21.2875 25.8443 21.1317 25.9165 21.0036L27.8276 17.6101Z"
        fill="url(#paint7_linear_510_527)"
      />
      <path
        d="M9.76757 17.6101C9.84341 17.4754 9.98729 17.3919 10.1435 17.3919H13.9374C14.0936 17.3919 14.2375 17.4754 14.3133 17.6101L16.2244 21.0036C16.2966 21.1317 16.2966 21.2875 16.2244 21.4156L14.3133 24.8091C14.2375 24.9438 14.0936 25.0273 13.9374 25.0273H10.1435C9.98729 25.0273 9.84341 24.9438 9.76757 24.8091L7.85646 21.4156C7.78431 21.2875 7.78431 21.1317 7.85646 21.0036L9.76757 17.6101Z"
        fill="url(#paint8_linear_510_527)"
      />
      <defs>
        <linearGradient id="paint0_linear_510_527" x1="141" y1="21" x2="154" y2="24" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5BC380" />
          <stop offset="1" stopColor="#39C747" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_510_527"
          x1="21.5"
          y1="0"
          x2="21.5"
          y2="42.4189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BC380" />
          <stop offset="1" stopColor="#39C747" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_510_527"
          x1="21.5"
          y1="0"
          x2="21.5"
          y2="42.4189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BC380" />
          <stop offset="1" stopColor="#39C747" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_510_527"
          x1="21.5"
          y1="0"
          x2="21.5"
          y2="42.4189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BC380" />
          <stop offset="1" stopColor="#39C747" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_510_527"
          x1="21.5"
          y1="0"
          x2="21.5"
          y2="42.4189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BC380" />
          <stop offset="1" stopColor="#39C747" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_510_527"
          x1="21.5"
          y1="0"
          x2="21.5"
          y2="42.4189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BC380" />
          <stop offset="1" stopColor="#39C747" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_510_527"
          x1="21.5"
          y1="0"
          x2="21.5"
          y2="42.4189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BC380" />
          <stop offset="1" stopColor="#39C747" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_510_527"
          x1="21.5"
          y1="0"
          x2="21.5"
          y2="42.4189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BC380" />
          <stop offset="1" stopColor="#39C747" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_510_527"
          x1="21.5"
          y1="0"
          x2="21.5"
          y2="42.4189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BC380" />
          <stop offset="1" stopColor="#39C747" />
        </linearGradient>
      </defs>
    </svg>
  );
};

VisionrLogo.defaultProps = {
  secondaryFill: '#0C1228',
};
