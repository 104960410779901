import * as React from 'react';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { App } from './App.tsx';

import './assets/fonts/Inter-Medium.ttf';
import './assets/fonts/Inter-SemiBold.ttf';
import './assets/fonts/Inter-Bold.ttf';

import './index.css';

// eslint-disable-next-line react-refresh/only-export-components
const CSP = [
  "default-src 'self'",
  "script-src 'self' 'unsafe-eval' 'unsafe-inline'",
  "style-src 'self' 'unsafe-inline'",
  "img-src 'self' data: *.amazonaws.com *.supabase.co",
  "font-src 'self' data:",
  "connect-src 'self' http://consensusadvertising.com localhost:9999 *.amazonaws.com",
  "frame-src 'self'",
  "media-src 'self' data: *.amazonaws.com",
].join('; ');

// check is dev environment
const isDev = import.meta.env.MODE === 'development';

if (isDev) {
  const meta = document.createElement('meta');
  meta.httpEquiv = 'Content-Security-Policy';
  meta.content = CSP;
  // document.getElementsByTagName('head')[0].appendChild(meta);
}

if (!isDev || window.location.hostname === 'management.consensusadvertising.com') {
  Sentry.init({
    dsn: 'https://e6e8231a4082796963f81bbc9041b287@o4506059322621952.ingest.us.sentry.io/4507327770460160',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
        mask: ['data-mask'],
        networkDetailAllowUrls: ['https://management.consensusadvertising.com/*'],
      }),
      Sentry.dedupeIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
