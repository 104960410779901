export const SYSTEMS_SHORTCUTS_ID_MAP = {
  TOGGLE_THEME: 'TOGGLE_THEME',
} as const;

export const SYSTEMS_SHORTCUTS_LABEL_MAP = {
  [SYSTEMS_SHORTCUTS_ID_MAP.TOGGLE_THEME]: 'uiLabel.toggleTheme',
} as const;

export const SYSTEMS_SHORTCUTS_MAP = {
  [SYSTEMS_SHORTCUTS_ID_MAP.TOGGLE_THEME]: 'd',
} as const;
