import * as React from 'react';
import { type TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import useTheme from '@mui/material//styles/useTheme';

export const ConfirmationDialogTransition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => {
    const theme = useTheme();
    return (
      <Slide
        ref={ref}
        direction="up"
        easing={{
          enter: theme.transitions.easing.easeOut,
        }}
        timeout={{
          enter: theme.transitions.duration.shortest,
        }}
        {...props}
      />
    );
  }
);
