import { type QueryClientConfig, keepPreviousData } from '@tanstack/react-query';

const STALE_TIME = 1000 * 60 * 60 * 1; // 1 hour;

export const QUERY_CLIENT_OPTIONS: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME,
      gcTime: STALE_TIME,
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      retry: 2, // retry 2 times
    },
  },
};
