import { z } from 'zod';
import ky from 'ky';
import { ENV } from 'src/utils/env';

export const LOG_ACTION_TYPE_MAP = {
  ZONE_EDITOR: 'ZONE_EDITOR',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  ORG_VIEWER_PAGE: 'ORG_VIEWER_PAGE',
  DEVICE_VIEWER_PAGE: 'DEVICE_VIEWER_PAGE',
  STORE_MANAGEMENT: 'STORE_MANAGEMENT',
  DEVICE: 'DEVICE',
  RETRY_USER_REPORT: 'RETRY_USER_REPORT',
  MAINTENANCE_MODE: 'maintenance_mode',
} as const;

export const logActionPromiseParamSchema = z.object({
  action: z.string(),
  action_from: z.string(),
  action_to: z.string().optional().nullable(),
  type: z.enum([
    'ZONE_EDITOR',
    'USER_MANAGEMENT',
    'ORG_VIEWER_PAGE',
    'DEVICE_VIEWER_PAGE',
    'STORE_MANAGEMENT',
    'DEVICE',
    'RETRY_USER_REPORT',
    'maintenance_mode',
  ]),
  type_metadata: z.string(),
});

export type LogActionPromiseParam = z.infer<typeof logActionPromiseParamSchema>;

export const logActionPromise = async (params: LogActionPromiseParam) => {
  const validParam = logActionPromiseParamSchema.safeParse(params);

  if (!validParam.success) {
    return;
  }

  await ky.post(`${ENV.FRONTEND_API_URL}/log_action`, {
    body: JSON.stringify(params),
  });
};
