import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Command } from 'cmdk';
import { useTranslation } from 'react-i18next';
import capitalize from '@mui/material/utils/capitalize';
import { useGetOrgsData } from 'src/hooks';
import { SuspenseFallbackComponent } from 'src/components/atoms';
import { UI_ROUTES } from 'src/constants/routes';
import { StyledLoadingState } from './CommandMenu.styled';

interface CommandMenuOrgViewerGroupProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CommandMenuOrgViewerGroup: React.FunctionComponent<CommandMenuOrgViewerGroupProps> = ({ setOpen }) => {
  const { t } = useTranslation();
  const { data: orgsData, isLoading } = useGetOrgsData();
  const navigate = useNavigate();

  const orgs = orgsData?.paginatedAllDevices?.orgs?.flat() || [];

  const onOrgSelect = (value: string) => {
    navigate(`${UI_ROUTES.ORG_BROWSER}/${value}`);
    setOpen(false);
  };

  return (
    <Command.Group heading={t('view.org')}>
      {isLoading ? (
        <StyledLoadingState>
          <SuspenseFallbackComponent size={30} />
        </StyledLoadingState>
      ) : null}
      {orgs.map((org) => {
        const orgLabel = capitalize(org?.name || '');
        return (
          <Command.Item key={org?.name} onSelect={onOrgSelect} value={org?.name}>
            {orgLabel}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <div cmdk-shortcuts="">
              <span>View org</span>
            </div>
          </Command.Item>
        );
      })}
    </Command.Group>
  );
};
