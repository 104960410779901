import * as React from 'react';

export const UserManagementIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        // eslint-disable-next-line no-inline-styles/no-inline-styles
        marginLeft: '-4px',
      }}
    >
      <path
        d="M19.5047 16.5029V18.5042C19.5047 19.0568 19.0567 19.5048 18.5041 19.5048H16.5028"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M16.5028 4.49536H18.5041C19.0567 4.49536 19.5047 4.94336 19.5047 5.49599V7.49724"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M4.49536 7.49724V5.49599C4.49536 4.94336 4.94336 4.49536 5.49599 4.49536H7.49724"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M7.49724 19.5048H5.49599C4.94336 19.5048 4.49536 19.0568 4.49536 18.5042V16.5029"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12.7505C13.5197 12.7505 14.7517 11.5185 14.7517 9.99879C14.7517 8.47906 13.5197 7.24707 12 7.24707C10.4803 7.24707 9.24829 8.47906 9.24829 9.99879C9.24829 11.5185 10.4803 12.7505 12 12.7505Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M16.246 16.753C15.6099 14.9535 13.9086 13.7505 12 13.7505C10.0914 13.7505 8.39014 14.9535 7.75403 16.753"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};
