import * as React from 'react';
import { DarkModeIcon, LightModeIcon } from 'src/assets/icons';
import { useGlobalStore } from 'src/hooks/use-app-wide-store';
import { StyledToggleSlot, StyledToggle, StyledSunIcon, StyledMoonIcon } from './DarkModeSwitcher.styled';

export const DarkModeSwitcher: React.FunctionComponent = () => {
  const isDarkMode = useGlobalStore((state) => state.darkMode);
  const toggleDarkMode = useGlobalStore((state) => state.toggleDarkMode);

  const handleToggleDarkMode = React.useCallback(() => {
    toggleDarkMode();
  }, [toggleDarkMode]);

  return (
    <StyledToggleSlot $darkMode={isDarkMode} onClick={handleToggleDarkMode} aria-label="dark theme switcher">
      <StyledSunIcon $darkMode={isDarkMode}>
        <LightModeIcon />
      </StyledSunIcon>
      <StyledToggle $darkMode={isDarkMode}>&nbsp;</StyledToggle>
      <StyledMoonIcon $darkMode={isDarkMode}>
        <DarkModeIcon />
      </StyledMoonIcon>
    </StyledToggleSlot>
  );
};
