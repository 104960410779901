import * as React from 'react';

export const ZoneEditorSandbox: React.FunctionComponent = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8361 10.6934V14.3068M9.24797 8.07921L15.752 9.38002M8.16402 15.7521V9.24806M15.752 15.6201L9.24797 16.9209M12 23C14.1756 23 16.3023 22.3549 18.1113 21.1462C19.9202 19.9375 21.3301 18.2195 22.1627 16.2095C22.9952 14.1995 23.2131 11.9878 22.7886 9.85401C22.3642 7.72022 21.3166 5.76021 19.7782 4.22183C18.2398 2.68345 16.2798 1.6358 14.146 1.21137C12.0122 0.786929 9.80047 1.00477 7.79048 1.83733C5.78049 2.66989 4.06253 4.07979 2.85383 5.88873C1.64514 7.69767 1 9.82441 1 12C1.00344 14.9163 2.16347 17.7122 4.22563 19.7744C6.28778 21.8365 9.08367 22.9966 12 23ZM12 3.00001C13.78 3.00001 15.5201 3.52785 17.0001 4.51678C18.4802 5.50571 19.6337 6.91132 20.3149 8.55585C20.9961 10.2004 21.1743 12.01 20.8271 13.7558C20.4798 15.5016 19.6226 17.1053 18.364 18.364C17.1053 19.6226 15.5016 20.4798 13.7558 20.8271C12.01 21.1743 10.2004 20.9961 8.55585 20.3149C6.91131 19.6337 5.50571 18.4802 4.51677 17.0001C3.52784 15.5201 3 13.78 3 12C3.00265 9.61387 3.95171 7.32622 5.63896 5.63897C7.32622 3.95171 9.61386 3.00265 12 3.00001ZM7.48659 7.07998H8.84139C9.06596 7.07998 9.248 7.26203 9.248 7.48659V8.84139C9.248 9.06596 9.06596 9.248 8.84139 9.248H7.48659C7.26203 9.248 7.07998 9.06596 7.07998 8.84139V7.48659C7.07998 7.26203 7.26203 7.07998 7.48659 7.07998ZM16.1586 8.52531H17.5134C17.738 8.52531 17.9201 8.70735 17.9201 8.93192V10.2867C17.9201 10.5113 17.738 10.6933 17.5134 10.6933H16.1586C15.9341 10.6933 15.752 10.5113 15.752 10.2867V8.93192C15.752 8.70735 15.9341 8.52531 16.1586 8.52531ZM16.1586 14.3068H17.5134C17.738 14.3068 17.9201 14.4889 17.9201 14.7134V16.0682C17.9201 16.2928 17.738 16.4748 17.5134 16.4748H16.1586C15.9341 16.4748 15.752 16.2928 15.752 16.0682V14.7134C15.752 14.4889 15.9341 14.3068 16.1586 14.3068ZM7.48659 15.7521H8.84139C9.06596 15.7521 9.248 15.9342 9.248 16.1587V17.5135C9.248 17.7381 9.06596 17.9201 8.84139 17.9201H7.48659C7.26203 17.9201 7.07998 17.7381 7.07998 17.5135V16.1587C7.07998 15.9342 7.26203 15.7521 7.48659 15.7521Z"
        stroke="currentColor"
      />
    </svg>
  );
};
