import * as React from 'react';

export const MenuToggleIcon: React.FunctionComponent = () => {
  return (
    <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.6 12.475C17.9333 12.475 18.2083 12.3667 18.425 12.15C18.6417 11.9334 18.75 11.6667 18.75 11.35C18.75 11.0167 18.6417 10.7417 18.425 10.525C18.2083 10.3084 17.9333 10.2 17.6 10.2H6.6C6.28333 10.2 6.01667 10.3084 5.8 10.525C5.58333 10.7417 5.475 11.0167 5.475 11.35C5.475 11.6667 5.58333 11.9334 5.8 12.15C6.01667 12.3667 6.28333 12.475 6.6 12.475H17.6ZM17.6 7.40002C17.9333 7.40002 18.2083 7.28736 18.425 7.06202C18.6417 6.83736 18.75 6.56669 18.75 6.25002C18.75 5.93336 18.6417 5.66269 18.425 5.43802C18.2083 5.21269 17.9333 5.10002 17.6 5.10002H9.6C9.28333 5.10002 9.01667 5.21269 8.8 5.43802C8.58333 5.66269 8.475 5.93336 8.475 6.25002C8.475 6.56669 8.58333 6.83736 8.8 7.06202C9.01667 7.28736 9.28333 7.40002 9.6 7.40002H17.6ZM17.6 2.30002C17.9333 2.30002 18.2083 2.19169 18.425 1.97502C18.6417 1.75836 18.75 1.48336 18.75 1.15002C18.75 0.833358 18.6417 0.566691 18.425 0.350024C18.2083 0.133358 17.9333 0.0250244 17.6 0.0250244H6.6C6.28333 0.0250244 6.01667 0.133358 5.8 0.350024C5.58333 0.566691 5.475 0.833358 5.475 1.15002C5.475 1.48336 5.58333 1.75836 5.8 1.97502C6.01667 2.19169 6.28333 2.30002 6.6 2.30002H17.6ZM3.7 6.25002L0.9 9.05002C0.683332 9.26669 0.574999 9.53336 0.574999 9.85003C0.574999 10.1667 0.683332 10.4334 0.9 10.65C1.11667 10.8667 1.38333 10.975 1.7 10.975C2.01667 10.975 2.28333 10.8667 2.5 10.65L6.1 7.05002C6.31667 6.81669 6.425 6.55002 6.425 6.25002C6.425 5.95002 6.31667 5.68336 6.1 5.45002L2.5 1.85002C2.28333 1.63336 2.01667 1.52502 1.7 1.52502C1.38333 1.52502 1.11667 1.63336 0.9 1.85002C0.683332 2.06669 0.574999 2.33336 0.574999 2.65002C0.574999 2.96669 0.683332 3.23336 0.9 3.45002L3.7 6.25002Z"
        fill="#919EAB"
      />
    </svg>
  );
};
