import ky from 'ky';
import { ENV } from 'src/utils/env';
import { listUsersSchema } from './types-and-schema';
import { paginateUsers } from './paginate-users';
import { getSearchAutoComplete } from './getSearchAutoComplete';

export interface ListUsersPromiseParams {
  pageNumber?: string | number;
  search?: string;
}

export const listUsersPromise = async ({ pageNumber, search }: ListUsersPromiseParams) => {
  const url = `${ENV.FRONTEND_API_URL}/list_users`;
  const response = await ky.post(url, { body: JSON.stringify({ pageNumber, search }) }).json();
  const result = listUsersSchema.safeParse(response);

  if (!result.success) {
    throw new Error(result.error.message);
  }

  const data = result.data?.data.map((user) => ({
    ...(user || {}),
    id: user.uuid,
  }));

  return {
    paginatedData: paginateUsers(data),
    autoCompleteData: getSearchAutoComplete(data),
    totalUsers: result.data?.metadata?.total_users || 0,
    total_pages: result?.data?.metadata?.total_pages || 1,
  };
};

export type ListUsersData = Awaited<ReturnType<typeof listUsersPromise>>;
export type ListUserItem = ListUsersData['paginatedData']['users'][0][0];
