import { z } from 'zod';
import { Prettify } from 'src/type-utils';

const DEVICE_SCHEMA = z.object({
  name: z.string(),
  id: z.string(),
  status_image_url: z.string(),
  status_image_time_string: z.string(),
  is_status_image_old: z.boolean(),
  events_in_last_hour: z.string(),
});

const ORG_SCHEMA = z.object({
  name: z.string(),
  device_count: z.number(),
  live_device_count: z.number(),
  devices: z.array(DEVICE_SCHEMA),
});

export const ORG_DATA_SCHEMA = z.object({
  orgs: z.array(ORG_SCHEMA),
});

export type OrgDataSchema = z.infer<typeof ORG_DATA_SCHEMA>;
type OrgDataSchemaOrg = OrgDataSchema['orgs'][0];
export type Device = Prettify<OrgDataSchema['orgs'][0]['devices'][0] & { org: string }>;
export interface Org extends OrgDataSchemaOrg {
  devices: Array<Device>;
}
export type Orgs = Prettify<Org>[];
