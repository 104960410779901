import styled from 'styled-components';
import { hexToRgba } from 'src/utils';

// dashed border
const getBorder = (stroke: string) =>
  `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='${stroke}' stroke-width='20' stroke-dasharray='2%2c 6' stroke-dashoffset='28' stroke-linecap='butt'/%3e%3c/svg%3e")`;

export const StyledAnglePicker = styled.div`
  position: relative;
  width: 190px;
  height: 190px;
  cursor: pointer;
  border-radius: 50%;
  user-select: none;
  background-image: ${(props) => getBorder(hexToRgba(props.theme.colors.grey1, 0.7))};
`;

export const StyledHandle = styled.div<{ $angle: number }>`
  position: absolute;
  width: 100%;
  height: 20px;
  cursor: grab;
  user-select: none;
  top: 50%;
  left: 50%;
  /** NOTE: since we inverted the angle for backwards compatibility, we need to flip the direction of the angle indicator too, so it matches the user's mousemove gesture */
  transform: translate(-50%, -50%) rotate(${(props) => 360 - props.$angle}deg);
  transform-origin: center;

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.textColor};

  svg {
    margin-left: 8px;
    transform: scale(0.9);
  }
`;

export const AngleIndicatorContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.colors.appBackground};
  color: ${(props) => props.theme.colors.primaryDark};
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  border-radius: 4px;
  user-select: none;
`;

export const AngleIndicatorText = styled.span`
  padding: 2px 6px;
`;
