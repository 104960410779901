import * as React from 'react';

export interface FilledCheckIconProps {
  fill?: string;
  size?: number;
}

export const FilledCheckIcon: React.FunctionComponent<FilledCheckIconProps> = ({ fill, size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 2.66669C8.65329 2.66669 2.66663 8.65335 2.66663 16C2.66663 23.3467 8.65329 29.3334 16 29.3334C23.3466 29.3334 29.3333 23.3467 29.3333 16C29.3333 8.65335 23.3466 2.66669 16 2.66669ZM22.3733 12.9334L14.8133 20.4934C14.6266 20.68 14.3733 20.7867 14.1066 20.7867C13.84 20.7867 13.5866 20.68 13.4 20.4934L9.62663 16.72C9.23996 16.3334 9.23996 15.6934 9.62663 15.3067C10.0133 14.92 10.6533 14.92 11.04 15.3067L14.1066 18.3734L20.96 11.52C21.3466 11.1334 21.9866 11.1334 22.3733 11.52C22.76 11.9067 22.76 12.5334 22.3733 12.9334Z"
        fill={fill}
      />
    </svg>
  );
};

FilledCheckIcon.defaultProps = {
  fill: 'black',
  size: 24,
};
