import * as React from 'react';
import { useGlobalStore } from '../use-app-wide-store';

const DARK_THEME_CLASS_NAME = 'dark-mode';

const enableDarkModeOnDocumentBody = (darkMode: boolean) => {
  try {
    const element = window.document.body;
    if (darkMode) {
      element.classList.add(DARK_THEME_CLASS_NAME);
    } else {
      element.classList.remove(DARK_THEME_CLASS_NAME);
    }
  } catch {
    // ignore
  }
};

/**
 * When scrolling with trackpad on macOS in dark mode, the scroll container scrolls the entire page and you could see the white background of the body.
 * This hook will make sure that a dark background is applied to the body when in dark mode
 */
export const useEnableDarkModeOnDocumentBody = () => {
  const darkMode = useGlobalStore((state) => state.darkMode);

  React.useEffect(
    () => {
      enableDarkModeOnDocumentBody(darkMode);
    },
    [darkMode] // Only re-call effect when value changes
  );
};
