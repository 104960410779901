import * as React from 'react';

import { SuspenseFallbackComponent as AtomSuspenseFallbackComponent } from '../SuspenseFallbackComponent';
import { StyledSuspenseFallback } from './DateRangePicker.styled';

export const SuspenseFallback: React.FunctionComponent = () => {
  return (
    <StyledSuspenseFallback>
      <AtomSuspenseFallbackComponent size={42} />
    </StyledSuspenseFallback>
  );
};
