import styled from 'styled-components';
import MuiSlider from '@mui/material/Slider';

export const StyledThresholdWrapper = styled.div`
  margin-top: 10px;
  width: 100%;

  div {
    display: flex;
    align-items: center;

    p {
      color: ${(props) => props.theme.colors.primaryDark};
      font-size: 18px;
      font-weight: 500;
      margin-left: 16px;
      border: 1px solid ${(props) => props.theme.colors.primaryDark};
      padding: 4px 8px;
      border-radius: 4px;
      background-color: ${(props) => props.theme.colors.appBackground};
    }
  }
`;

export const StyledSlider = styled(MuiSlider)`
  color: ${(props) => props.theme.colors.primaryDark};
  width: calc(100% - 68px);

  & .MuiSlider-valueLabel {
    color: ${(props) => props.theme.colors.white};
    font-size: 16px;
    background-color: ${(props) =>
      props.theme.isDarkTheme ? props.theme.colors.primaryDark : props.theme.colors.primaryDark};

    border: 1px solid ${(props) => props.theme.colors.borderColor};
    border-radius: 4px;

    &::before {
      background-color: ${(props) => props.theme.colors.primaryDark};
    }
  }
`;
