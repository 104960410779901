import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const UPDATE_DEVICE_CONFIDENCE_SCHEMA = z.object({
  scrubberConfigId: z.string(),
  min_female_confidence: z.number(),
  min_male_confidence: z.number(),
  gender_body_conf: z.number(),
});

export type UpdateDeviceConfidenceParams = z.infer<typeof UPDATE_DEVICE_CONFIDENCE_SCHEMA>;

const UPDATE_DEVICE_CONFIDENCE_RESPONSE_SCHEMA = z.object({
  data: z.string(),
  error: z.string().optional().nullable(),
});

export const updateDeviceConfidencePromise = async (params: UpdateDeviceConfidenceParams) => {
  const validateParams = UPDATE_DEVICE_CONFIDENCE_SCHEMA.safeParse(params);

  if (!validateParams.success) {
    // eslint-disable-next-line no-console
    console.error(validateParams.error);
    return false;
  }

  const response = await ky
    .post(`${ENV.FRONTEND_API_URL}/update_device_confidence`, {
      body: JSON.stringify(params),
    })
    .json();

  const res = UPDATE_DEVICE_CONFIDENCE_RESPONSE_SCHEMA.safeParse(response);

  if (!res.success) {
    return false;
  }

  return res.data.data?.toLowerCase()?.includes('success');
};
