import * as React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import noop from 'lodash/noop';
import { routeConfig } from 'src/route-config';
import { AppProvider } from 'src/providers/AppProvider';
import { SuspenseFallbackComponent } from 'src/components/atoms';
import { ErrorBoundaryComponent } from './components/ErrorBoundary';

const router = createBrowserRouter(routeConfig);

export const App = () => {
  return (
    <AppProvider>
      <ErrorBoundaryComponent componentId="App" onErrorCallback={noop} shouldRetry>
        <React.Suspense fallback={<SuspenseFallbackComponent />}>
          <RouterProvider router={router} />
        </React.Suspense>
      </ErrorBoundaryComponent>
    </AppProvider>
  );
};

export default App;
