import * as React from 'react';
import { getInitials } from 'src/utils/helpers/get-initials';
import { StyledAvatar } from './Avatar.styled';
import { P14 } from '../Typography';

export interface AvatarProps {
  fullName?: string;
}

export const Avatar: React.FunctionComponent<AvatarProps> = ({ fullName }) => {
  const initials = getInitials(fullName ?? '');

  return (
    <StyledAvatar>
      <P14>{initials}</P14>
    </StyledAvatar>
  );
};

Avatar.defaultProps = {
  fullName: '',
};
