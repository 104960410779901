import { config } from '@react-spring/web';

export const CUBIC_BEZIER = {
  animateOutFast: 'cubic-bezier(0.86, 0, 0.07, 1)',
};

export const ANIMATION_DURATION = {
  fast: '500ms',
};

/**
 * CUBIC_BEZIER translated to react-spring config
 */
export const ANIMATION_CONFIG_MAP = {
  animateOutFast: {
    ...config.default,
    duration: 500,
    easing: (tt: number) => (tt === 1 ? 1 : 1 - 2 ** (-10 * tt)),
    mass: 1,
    precision: 0.01,
    velocity: 0,
  },
};
