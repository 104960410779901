import { useQuery } from '@tanstack/react-query';
import { listUsersPromise } from 'src/utils/api/list-users';
import { QUERY_KEY_MAP } from 'src/constants';
import { useSearch } from 'src/hooks';

export const useListExternalUsers = () => {
  const [searchParams] = useSearch();
  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: [QUERY_KEY_MAP.useListExternalUsers, searchParams?.page, searchParams?.search],
    queryFn: () => listUsersPromise({ pageNumber: searchParams?.page ?? 1, search: searchParams?.search ?? '' }),
  });

  return { data, isLoading, isFetching, error };
};
