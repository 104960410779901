import styled from 'styled-components';
import MuiTab from '@mui/material/Tabs';
import MuiTabItem from '@mui/material/Tab';
import { hexToRgba } from 'src/utils';

export const StyledTab = styled(MuiTab)`
  &.MuiTabs-root {
    min-height: unset;

    padding: 4px;
    border-radius: 6px;
    background-color: ${(props) =>
      props.theme.isDarkTheme
        ? hexToRgba(props.theme.colors.appBackground, 0.5)
        : hexToRgba(props.theme.colors.grey3, 0.5)};
  }

  & .MuiTabs-indicator {
    bottom: unset;
    top: 0;
    height: 100%;
    border-radius: 4px;
    z-index: 1;
    background-color: ${(props) => props.theme.colors.appBackground};
    border: 1px solid ${(props) => props.theme.colors.borderColor};
  }

  & .MuiTabs-flexContainer {
    position: relative;
    z-index: 10;
  }
`;
export const StyledTabItem = styled(MuiTabItem)`
  &.MuiTab-root {
    padding: 8px 12px;
    min-width: unset;
    min-height: unset;
    border-radius: 4px;
    color: ${(props) => props.theme.colors.textColor};
    letter-spacing: unset;
    text-transform: unset;
    opacity: 0.5;
    transition: all 0.3s ease-out;

    &:hover {
      opacity: 1;
    }
  }

  &.Mui-selected {
    opacity: 1;
    color: ${(props) => props.theme.colors.primaryDark};
  }
`;
