import * as React from 'react';

export interface LightModeIconProps {
  fill?: string;
}

export const LightModeIcon: React.FunctionComponent<LightModeIconProps> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path
        d="M12 4.1q-.475 0-.812-.338-.338-.337-.338-.812v-1q0-.475.338-.8.337-.325.812-.325.475 0 .812.325.338.325.338.8v1q0 .475-.338.812-.337.338-.812.338Zm0 19.075q-.475 0-.812-.325-.338-.325-.338-.8v-1q0-.475.338-.813.337-.337.812-.337.475 0 .812.337.338.338.338.813v1q0 .475-.338.8-.337.325-.812.325Zm9.05-10.025q-.475 0-.812-.338-.338-.337-.338-.812 0-.475.338-.812.337-.338.812-.338h1q.475 0 .8.338.325.337.325.812 0 .475-.325.812-.325.338-.8.338Zm-19.1 0q-.475 0-.8-.338Q.825 12.475.825 12q0-.475.325-.812.325-.338.8-.338h1q.475 0 .813.338.337.337.337.812 0 .475-.337.812-.338.338-.813.338Zm15.975-7.075q-.3-.325-.3-.775t.3-.825l.35-.375q.325-.35.8-.35.475 0 .825.35t.35.838q0 .487-.35.812l-.375.35q-.325.3-.787.3-.463 0-.813-.325ZM4.1 19.9q-.35-.35-.363-.838-.012-.487.363-.812l.375-.35q.325-.3.788-.3.462 0 .812.325.325.325.313.787-.013.463-.313.813l-.35.375q-.325.35-.8.35-.475 0-.825-.35Zm14.15 0-.35-.375q-.3-.325-.3-.788 0-.462.325-.812.325-.325.787-.312.463.012.813.312l.375.35q.35.325.35.8 0 .475-.35.825t-.838.362q-.487.013-.812-.362ZM4.475 6.075l-.375-.35q-.35-.325-.35-.8 0-.475.35-.825t.838-.35q.487 0 .812.35l.35.375q.3.325.3.787 0 .463-.325.813-.325.325-.775.312-.45-.012-.825-.312ZM12 18q-2.5 0-4.25-1.75T6 12q0-2.5 1.75-4.25T12 6q2.5 0 4.25 1.75T18 12q0 2.5-1.75 4.25T12 18Zm0-2.275q1.55 0 2.638-1.088Q15.725 13.55 15.725 12t-1.087-2.638Q13.55 8.275 12 8.275q-1.55 0-2.637 1.087Q8.275 10.45 8.275 12q0 1.55 1.088 2.637Q10.45 15.725 12 15.725ZM12 12Z"
        fill={fill}
      />
    </svg>
  );
};

LightModeIcon.defaultProps = {
  fill: '#000',
};
