import * as React from 'react';

export interface ErrorIconProps {
  size?: number;
  fill?: string;
}

export const ErrorIcon: React.FunctionComponent<ErrorIconProps> = ({ size, fill }: ErrorIconProps) => {
  return (
    <svg width={size} height={size} aria-hidden="true" viewBox="0 0 24 24">
      <path
        d="M13.89 8.7 12 10.59 10.11 8.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 8.7 13.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l1.89 1.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l1.89-1.89c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.38-1.41 0zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
        fill={fill}
      />
    </svg>
  );
};

ErrorIcon.defaultProps = {
  size: 24,
  fill: '#919EAB',
};
