import styled, { keyframes, css } from 'styled-components';
import { hexToRgba } from 'src/utils';
import { P16 } from '../Typography';

export const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.appBackground};
  z-index: 100;
`;

const spin = keyframes`

    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
`;

const COMMON_BOX_CSS = css`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  left: 50%;
  top: 50%;
`;

export const StyledSpinner = styled.div`
  ${COMMON_BOX_CSS};
  border-radius: 50%;
  border: 4px solid ${({ theme }) => hexToRgba(theme.colors.primary, 0.2)};
  border-top: 4px solid ${({ theme }) => theme.colors.primaryDark};
  animation: ${spin} 2s linear infinite;
`;

export const StyledIcon = styled.div`
  ${COMMON_BOX_CSS};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);

  svg {
    transform: scale(1.8);
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

export const StyledText = styled(P16)`
  ${COMMON_BOX_CSS};
  width: 100%;
  text-align: center;
  transform: translate(-50%, 70%);
`;

export const StyledEmblem = styled.div`
  ${COMMON_BOX_CSS};
  width: 100%;
  text-align: center;
  transform: translate(-50%, -200%);

  svg {
    height: 60px;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const StyledUnauthenticatedIcon = styled(StyledIcon)`
  border: 4px solid ${({ theme }) => hexToRgba(theme.colors.red, 0.2)};
  border-radius: 50%;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.red};
    }
  }
`;
