import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { persist } from 'zustand/middleware';
import { STORAGE_KEYS } from 'src/constants';

export interface GlobalStore {
  darkMode: boolean;
  toggleDarkMode: () => void;

  prefersWideDashboardMenu: boolean;
  setPrefersWideDashboardMenu: (newValue: boolean) => void;
}

export const useGlobalStore = createWithEqualityFn(
  persist<GlobalStore>(
    (set) => ({
      /** Dark mode  */
      darkMode: true, // Default to dark mode
      toggleDarkMode: () => set((state) => ({ ...state, darkMode: !state.darkMode })),

      /** Used to check if the user prefers a wide dashboard menu
       * Also used as the toggle value for mobile dashboard menu
       */
      prefersWideDashboardMenu: false,
      setPrefersWideDashboardMenu: (newValue: boolean) =>
        set((state) => ({ ...state, prefersWideDashboardMenu: newValue })),
    }),
    {
      name: STORAGE_KEYS.VR_MT_GLOBAL_STORE,
      version: 0.0001, // bump this number to clear the cache every time you change the store
    }
  ),
  shallow
);
