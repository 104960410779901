import * as React from 'react';
import { bool } from 'prop-types';
import noop from 'lodash/noop';
import { FallbackUi } from './FallbackUi';

export interface ErrorBoundaryState {
  hasError: boolean;
}

export interface ErrorBoundaryComponentProps {
  children: React.ReactNode;
  shouldRetry: boolean;
  onErrorCallback: () => void;
  componentId: string;
}

export class ErrorBoundaryComponent extends React.Component<ErrorBoundaryComponentProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryComponentProps | Readonly<ErrorBoundaryComponentProps>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (
      error?.message?.toLowerCase().includes('dynamically imported module') ||
      error?.message?.toLowerCase().includes('loading module from')
    ) {
      // eslint-disable-next-line no-console
      console.error('ErrorBoundaryComponent: Dynamically imported module error', { error, errorInfo });
      window.location.reload();
      return;
    }

    // eslint-disable-next-line no-console
    console.error({ error, errorInfo, componentId: this.props.componentId });
    if (typeof this.props.onErrorCallback === 'function') {
      this.props.onErrorCallback();
    }
  }

  reset = () => {
    // try recover from error by remounting the component
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      // dont render fallback component if shouldRetry is false
      if (!this.props.shouldRetry) return null;

      return <FallbackUi handleTryAgain={this.reset} />;
    }

    return this.props.children;
  }
}

export interface ErrorBoundaryProps extends Partial<Omit<ErrorBoundaryComponentProps, 'reportError'>> {
  children: React.ReactNode;
  componentId: string;
}

/* Function component wrapper allowing us to get some values from hooks as class component cant consume hooks */
export const ErrorBoundary: React.FunctionComponent<ErrorBoundaryProps> = ({
  children,
  shouldRetry,
  onErrorCallback,
  componentId,
}) => {
  /**
   * TODO: implement error logs
   */

  return (
    <ErrorBoundaryComponent
      shouldRetry={shouldRetry as boolean}
      onErrorCallback={onErrorCallback ?? noop}
      componentId={componentId}
    >
      {children}
    </ErrorBoundaryComponent>
  );
};

ErrorBoundary.propTypes = {
  shouldRetry: bool,
};

ErrorBoundary.defaultProps = {
  shouldRetry: true,
};
