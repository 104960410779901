import * as React from 'react';
import { type MenuProps } from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';
import capitalize from '@mui/material/utils/capitalize';
import { UsePopoverResult } from 'src/hooks/use-popover';
import { StyledMenu, StyledMenuItem } from './OptionsMenu.styled';

export interface OptionMenuItem {
  /** A label displayed on the ui, should be internalised */
  label: string;
  /** A unique key to identify the selection option in onSelectOption callback */
  key?: string;
  /** If true, the option will be displayed in red color */
  danger?: boolean;
  /** If true, the option will be displayed in orange color */
  warning?: boolean;
}

export interface OptionsMenuProps extends UsePopoverResult, Omit<MenuProps, 'anchorEl'> {
  anchorOrigin?: MenuProps['anchorOrigin'];
  transformOrigin?: MenuProps['transformOrigin'];
  /** A list of option with
   * - label: the label to display on the ui, should be internalised
   * - key: a unique key to identify the selection option in onSelectOption callback
   * - danger: if true, the option will be displayed in red color
   */
  options: readonly OptionMenuItem[];
  /** A function attached to each option, when user selects an option, get the key of the selected option form the dataset of the event object and call a function based on that key */
  onSelectOption: (event: React.MouseEvent<HTMLElement>) => void;
  /** Minimum width of the dropdown component
   * @default 200px
   */
  minWidth?: string;
}

export const OptionsMenu: React.FunctionComponent<OptionsMenuProps> = ({
  id,
  handleClose,
  open,
  anchorEl,
  anchorOrigin,
  transformOrigin,
  options,
  onSelectOption,
  minWidth,
}) => {
  const { t } = useTranslation();
  return (
    <StyledMenu
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={handleClose}
      $minWidth={minWidth}
      disableRestoreFocus
      data-cy="options-menu"
    >
      {options.map(({ label, danger, warning, key }) => (
        <StyledMenuItem
          key={key}
          onClick={onSelectOption}
          $danger={danger}
          $warning={warning}
          data-key={key}
          data-cy="options-menu-item"
        >
          {capitalize(t(label) || '')}
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};

OptionsMenu.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  minWidth: '200px',
};
