import { type Orgs } from './types-and-schema';

export const getSearchAutoCompleteOptions = (orgs: Orgs) => {
  return orgs
    .map((org) => {
      const devicesOptions = org.devices.map((device) => ({
        label: device.name,
        value: device.name,
        id: device.id,
        org: org.name,
        status_image_url: device.status_image_url,
        is_status_image_old: device.is_status_image_old,
      }));

      return devicesOptions;
    })
    .flat();
};
