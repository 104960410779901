import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clamp from 'lodash/clamp';
import { StyledTab, StyledTabItem } from './Toggle.styled';

export interface ToggleItem {
  label: string;
  value: string;
}

export type ToggleOnChangeParams = ToggleItem;

export interface ToggleProps {
  tabs: Array<ToggleItem> | ReadonlyArray<ToggleItem>;
  onChange: (params: ToggleOnChangeParams) => void;
  /**
   * The index of the selected tab.
   */
  value: string;
  ariaLabel: string;
  className?: string;
}

export const Toggle: React.FunctionComponent<ToggleProps> = ({ tabs, onChange, value, ariaLabel, className }) => {
  const { t } = useTranslation();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    onChange(tabs[newValue]);
  };

  const tabValue = clamp(
    tabs.findIndex((tab) => tab.value === value),
    0,
    tabs.length - 1
  );

  return (
    <StyledTab value={tabValue} onChange={handleChange} aria-label={ariaLabel} className={className}>
      {tabs.map((tab) => (
        <StyledTabItem key={tab.value} label={t(tab.label)} />
      ))}
    </StyledTab>
  );
};

Toggle.defaultProps = {
  className: '',
};
