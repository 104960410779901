import styled from 'styled-components';
import { hexToRgba } from 'src/utils';
import { P14, P16 } from '../../../atoms';
import { StyledDrawerProps } from '../../Layout.styled';

export const StyledNameAndEmail = styled.div`
  justify-self: flex-start;
  margin-right: auto;
  margin-left: 13px;
  max-width: 137px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledName = styled(P16)`
  font-weight: 500;
  color: ${(props) => props.theme.colors.grey1};
`;

export const StyledEmail = styled(P14)`
  color: ${(props) => props.theme.colors.grey1};
  margin-top: -2px;
`;

export const StyledProfileBar = styled.div<StyledDrawerProps>`
  border-radius: 4px;
  width: 100%;
  height: 51px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-out;
  outline: none;
  border: none;
  background-color: ${(props) => props.theme.colors.transparent};
  padding: 32px 0px 32px 13px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  transform: ${(props) => (props.$menuIsOpen ? 'translateX(0)' : 'translateX(-8px)')};
  transition: transform
    ${(props) => `${props.theme.animation.duration.fast} ${props.theme.animation.cubicBezier.animateOutFast}`};
  ${StyledNameAndEmail},
  & .MuiIconButton {
    opacity: ${(props) => (props.$menuIsOpen ? 1 : 0)};
    transition: opacity
      ${(props) => `${props.theme.animation.duration.fast} ${props.theme.animation.cubicBezier.animateOutFast}`};
  }

  &:hover {
    background-color: ${(props) => hexToRgba(props.theme.colors.primary, 0.15)};

    ${StyledName} {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;
