import * as React from 'react';

export interface SortIconProps {
  className?: string;
}

export const SortIcon: React.FunctionComponent<SortIconProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="48"
      viewBox="0 0 24 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.0367 14.2436L13.7801 6.59649C12.9757 5.61337 11.5266 5.46846 10.5435 6.27284C10.4251 6.36969 10.3167 6.47812 10.2199 6.59649L3.96317 14.2436C3.1588 15.2267 3.3037 16.6757 4.28683 17.4801C4.69775 17.8163 5.21233 18 5.74327 18L18.2566 18C19.5269 18 20.5566 16.9703 20.5566 15.7C20.5566 15.1691 20.373 14.6545 20.0367 14.2436Z"
        fill="#52555A"
      />
      <path
        d="M20.0367 33.7564L13.7801 41.4035C12.9757 42.3866 11.5266 42.5315 10.5435 41.7272C10.4251 41.6303 10.3167 41.5219 10.2199 41.4035L3.96317 33.7564C3.1588 32.7733 3.3037 31.3243 4.28683 30.5199C4.69775 30.1837 5.21233 30 5.74327 30L18.2566 30C19.5269 30 20.5566 31.0297 20.5566 32.3C20.5566 32.8309 20.373 33.3455 20.0367 33.7564Z"
        fill="#52555A"
      />
    </svg>
  );
};

SortIcon.defaultProps = {
  className: undefined,
};
