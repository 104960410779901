import * as React from 'react';
import { IconButtonProps } from '@mui/material/IconButton/';
import { CloseIcon } from 'src/assets/icons';
import { COLORS } from 'src/constants';
import { IconButton } from '../IconButton';

export type DialogCloseButtonProps = IconButtonProps;

export const DialogCloseButton: React.FunctionComponent<DialogCloseButtonProps> = (props) => {
  return (
    <IconButton
      {...props}
      sx={{
        position: 'absolute',
        top: 20,
        right: 18,
      }}
      $iconTransform="translateX(0)"
    >
      <CloseIcon fill={COLORS.grey1} />
    </IconButton>
  );
};
