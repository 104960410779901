import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY_MAP } from 'src/constants';
import { getUserPromise } from 'src/utils';

export const useGetUserDetails = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: [QUERY_KEY_MAP.useGetUserDetails],
    queryFn: getUserPromise,
  });

  return {
    userDetails: data?.userDetails,
    isLoggedIn: data?.isLoggedIn,
    isLoading,
    error,
  };
};
