import * as React from 'react';
import styled from 'styled-components';

export interface StyledBackdropProps {
  $visible: boolean;
  $cssPosition?: string;
  $height?: string;
  $zIndex?: number;
}
export const StyledBackdrop = styled.div<StyledBackdropProps>`
  position: ${(props) => props.$cssPosition || 'fixed'};
  top: 0;
  left: 0;
  width: 100vw;
  height: ${(props) => props.$height || '100vh'};
  background-color: ${(props) => props.theme.colors.backdropColor};
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
  z-index: ${(props) => props.$zIndex || 1};
  /* background-color: red; */
`;

export interface BackdropProps {
  visible: boolean;
  onClick: () => void;
  $zIndex?: number;
}

export const Backdrop: React.FunctionComponent<BackdropProps> = ({ visible, onClick, $zIndex }) => {
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (typeof onClick === 'function') {
      onClick();
    }
  };
  return (
    <StyledBackdrop onClick={handleBackdropClick} $visible={visible} $zIndex={$zIndex}>
      &nbsp;
    </StyledBackdrop>
  );
};

Backdrop.defaultProps = {
  $zIndex: 1,
};
