export const ZONE_TYPE_MAP = {
  detection: 'detection',
  presence: 'presence',
  exclusion: 'exclusion',
} as const;

export type ZoneTypeMap = (typeof ZONE_TYPE_MAP)[keyof typeof ZONE_TYPE_MAP];

export const ZONE_TABS = [
  { label: 'uiLabel.detection.zone', value: ZONE_TYPE_MAP.detection },
  { label: 'uiLabel.presence.zone', value: ZONE_TYPE_MAP.presence },
  { label: 'uiLabel.exclusion.zone', value: ZONE_TYPE_MAP.exclusion },
] as const;
