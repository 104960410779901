import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const EDIT_DEVICE_DETAILS_SCHEMA = z.object({
  display_name: z.string().optional(),
  device_id: z.string(),
  location_id: z.string().optional(),
  organisations: z.string().array().optional(),
});

export type EditDeviceDetailsSchema = z.infer<typeof EDIT_DEVICE_DETAILS_SCHEMA>;

export const editDeviceDetailsPromise = async (param: EditDeviceDetailsSchema) => {
  if (!EDIT_DEVICE_DETAILS_SCHEMA.safeParse(param).success) {
    // eslint-disable-next-line no-console
    console.error('Invalid Params');
    throw new Error('Invalid Params');
  }

  try {
    const response = await ky.post(`${ENV.FRONTEND_API_URL}/update_device`, { body: JSON.stringify(param) });

    if (response.ok) {
      return true;
    }
    // eslint-disable-next-line no-console
    console.error('Non 200 response in editDeviceDetailsPromise', response.status);

    return false;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in editDeviceDetailsPromise', error);
    throw new Error('Error in editDeviceDetailsPromise');
  }
};
