import * as React from 'react';
import type { Breakpoint } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ConfirmIcon } from 'src/assets/icons';
import { Dialog, DialogContent } from '../Dialog';
import { P20, P16 } from '../Typography';
import { StyledWrapper, StyledIcon, StyledButton, StyledSecondaryButton } from './ConfirmationDialog.styled';
import { SNACKBAR_COLORS } from '../Snackbar/constant';
import { ConfirmationDialogTransition } from './ConfirmationDialog.transition';
import { CircularProgress } from '../Progress/CircularProgress';

export interface ConfirmationDialogProps {
  id: string;
  title?: string;
  actionButtonLabel?: string;
  warningText: string;
  color: 'success' | 'warning' | 'error' | 'info';
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  children?: React.ReactNode;
  maxWidth?: Breakpoint;
}
export const ConfirmationDialog: React.FunctionComponent<ConfirmationDialogProps> = ({
  id,
  color,
  title,
  actionButtonLabel,
  warningText,
  onClose,
  onConfirm,
  isLoading,
  children,
  maxWidth,
}) => {
  const { t } = useTranslation();
  const contextColor = SNACKBAR_COLORS[color as keyof typeof SNACKBAR_COLORS] || '';

  const handleConfirm = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e?.stopPropagation?.();
    onConfirm();
  };

  const handleCancel = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e?.stopPropagation?.();
    onClose();
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      open
      fullWidth
      aria-labelledby={id}
      onClose={onClose}
      TransitionComponent={ConfirmationDialogTransition}
    >
      <DialogContent>
        <StyledIcon $color={contextColor}>
          <ConfirmIcon />
        </StyledIcon>
        <P20 $fontWeight={600}>{t(title || 'uiLabel.confirmationDialog.title')}</P20>
        {children}

        <P16 $margin="16px 0 40px 0">{t(warningText)}</P16>

        <StyledWrapper>
          <StyledSecondaryButton size="small" color="secondary" onClick={handleCancel}>
            {t('uiLabel.confirmationDialog.cancel')}
          </StyledSecondaryButton>

          <StyledButton $color={contextColor} size="small" onClick={handleConfirm}>
            {isLoading ? (
              <CircularProgress $color="#fff" size={16} />
            ) : (
              t(actionButtonLabel || 'uiLabel.confirmationDialog.confirm')
            )}
          </StyledButton>
        </StyledWrapper>
      </DialogContent>
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  actionButtonLabel: 'uiLabel.confirmationDialog.confirm',
  title: 'uiLabel.confirmationDialog.title',
  isLoading: false,
  maxWidth: 'xs',
  children: null,
};
