import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';
import { generateVRID } from 'src/utils';
import { BoundingBoxConfig } from 'src/types';

const EXCLUSION_ZONE_SCHEMA = z.array(z.array(z.number()));

const GET_EXCLUSION_ZONE_SCHEMA = z.object({
  zones: z.array(EXCLUSION_ZONE_SCHEMA),
  message: z.string().optional().or(z.null()),
});

export type GetExclusionZoneSchema = z.infer<typeof GET_EXCLUSION_ZONE_SCHEMA>;

export type ExclusionZoneSchema = z.infer<typeof EXCLUSION_ZONE_SCHEMA>;

export const convertApiExclusionZoneToBoundingBoxConfig = (zone: ExclusionZoneSchema) => {
  const id = generateVRID();
  return {
    ...zone,
    id: id,
    name: id,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    points: zone || [],
    isDrawing: false,
    isPrevious: true,
    direction_angle_threshold: 0,
    direction_angle: 0,
    detection_area: false,
    dzone_direction: 0,
    min_time: 0,
    epos_filters: [],
    category: 'NC',
  };
};

export const getExclusionZonePromise = async (
  display_id: string
): Promise<Array<Omit<BoundingBoxConfig, 'stageWidth' | 'stageHeight' | 'fill'>>> => {
  const response = await ky
    .post(`${ENV.FRONTEND_API_URL}/get_exclusion_zone`, { body: JSON.stringify({ display_id }) })
    .json();

  const result = GET_EXCLUSION_ZONE_SCHEMA.safeParse(response);

  if (!result.success) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isEmpty = (response as string)?.toLowerCase?.() === 'no exclusion zones.';

    if (isEmpty) return [];

    throw new Error('Invalid response');
  }

  return result.data.zones.map(convertApiExclusionZoneToBoundingBoxConfig);
};
