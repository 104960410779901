import { z } from 'zod';

export const MANAGEMENT_STORES_SCHEMA = z.array(
  z.object({
    uuid: z.string(),
    store_name: z.string(),
    ui_label: z.string(),
    created_time: z.string(),
    override_time: z.string().nullable(),
    devices: z.array(z.object({ uuid: z.string(), device_key: z.string(), device_type: z.string() })),
    weather_location: z.object({ label: z.string(), lat: z.number().or(z.string()), lon: z.number().or(z.string()) }),
  })
);

export type ManagementStoresSchema = z.infer<typeof MANAGEMENT_STORES_SCHEMA>;
