import ky from 'ky';
import { z } from 'zod';
import { ENV } from 'src/utils/env';

const EDIT_EXCLUSION_ZONE_SCHEMA = z.enum(['Exclusion zone successfully edited.']);

export type EditExclusionZoneSchema = z.infer<typeof EDIT_EXCLUSION_ZONE_SCHEMA>;

export interface EditExclusionZonePromiseParam {
  display_id: string;
  zones: Array<Array<Array<number>>>;
}

export const editExclusionZonePromise = async (param: EditExclusionZonePromiseParam) => {
  const response = await ky.post(`${ENV.FRONTEND_API_URL}/edit_exclusion_zone`, { body: JSON.stringify(param) }).json();

  const result = EDIT_EXCLUSION_ZONE_SCHEMA.safeParse(response);

  if (!result.success) {
    throw new Error('Invalid response');
  }

  return true;
};
