import styled from 'styled-components';
import MuiDialogActions from '@mui/material/DialogActions';

export const DialogActions = styled(MuiDialogActions)`
  &.MuiDialogActions-root {
    padding: 20px 24px;

    border-top: 1px solid ${(props) => props.theme.colors.borderColor};
    & > :not(:first-child) {
      margin-left: 16px;
    }
  }
`;
