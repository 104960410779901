import * as React from 'react';

export const LockedIcon: React.FunctionComponent = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6.49658" y="10.499" width="11.0069" height="9.00562" rx="1.5" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M8.4978 10.4991V7.99755C8.4978 6.06334 10.0658 4.49536 12 4.49536C13.9342 4.49536 15.5022 6.06334 15.5022 7.99755V10.4991"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M12 14.5017C11.8619 14.5017 11.7499 14.6137 11.7499 14.7519C11.7499 14.89 11.8619 15.002 12 15.002C12.1382 15.002 12.2502 14.89 12.2502 14.7519C12.2504 14.6855 12.2241 14.6217 12.1771 14.5748C12.1302 14.5278 12.0664 14.5015 12 14.5017"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
