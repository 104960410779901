import * as React from 'react';
import { ChevronDown } from 'src/assets/icons';

interface DropdownIconProps {
  className: string;
}
export const DropdownIcon: React.FunctionComponent<DropdownIconProps> = (props) => {
  return (
    <div {...props}>
      <ChevronDown />
    </div>
  );
};
