export const CheckIcon = ({ size = 24 }: { size?: number }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 12.3744L9.3513 17.7199L9.77885 16.9723C12.0713 12.9638 15.2438 9.52807 19.0572 6.92405L20 6.28027"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
CheckIcon.defaultProps = {
  size: 24,
};
