import { IParseOptions, defaultDecoder } from 'qs';
import { recursivelyDecodeUriComponent } from './recursive-uri-decoder';

export const keywords = {
  true: true,
  false: false,
  null: null,
  undefined: undefined,
} as const;

type Keywords = keyof typeof keywords;

type KeywordsValues = (typeof keywords)[Keywords] | string | number;

export const decoder = (str: string, _: defaultDecoder /*defaultDecoder*/, charset: string): KeywordsValues => {
  const strWithoutPlus = str.replace(/\+/g, ' ');

  if (charset === 'iso-8859-1') {
    return recursivelyDecodeUriComponent(strWithoutPlus);
  }

  if (/^(\d+|\d*\.\d+)$/.test(str)) {
    // if int starts with 0 return as string
    if (/^0\d+/.test(str)) {
      return str;
    }

    return parseFloat(str);
  }

  if (keywords[str as unknown as Keywords]) {
    return keywords[str as unknown as Keywords];
  }

  // utf-8
  return recursivelyDecodeUriComponent(strWithoutPlus);
};

/** @type IParseOptions */
export const PARSE_OPTIONS = { decoder, ignoreQueryPrefix: true } as IParseOptions;
/** @type IStringifyOptions */
export const STRINGIFY_OPTIONS = {
  /** Turn on encode as there are weird characters in  product description */
  encode: true,
};
