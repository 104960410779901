import ky from 'ky';
import { ENV } from 'src/utils/env';
import { ORG_DATA_SCHEMA } from './types-and-schema';
import { transformDataFn } from './transform-data-fn';

export const getOrgsPromise = async () => {
  const response = await ky.get(`${ENV.FRONTEND_API_URL}/orgs`).json();

  const validatedResponse = ORG_DATA_SCHEMA.safeParse(response);

  if (!validatedResponse.success) {
    throw new Error('Failed to fetch orgs');
  }

  return transformDataFn(
    validatedResponse.data.orgs.map((org) => ({
      ...org,
      devices: org.devices
        .map((device) => ({
          ...device,
          org: org.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    }))
  );
};
