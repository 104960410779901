import * as React from 'react';

export interface LockIconProps {
  fill?: string;
}

export const LockIcon: React.FunctionComponent<LockIconProps> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="22" width="24">
      <path
        d="M6.075 22.175q-.95 0-1.612-.662Q3.8 20.85 3.8 19.9v-9.85q0-.925.663-1.6.662-.675 1.612-.675H6.9V5.95q0-2.125 1.487-3.613Q9.875.85 12 .85t3.613 1.487Q17.1 3.825 17.1 5.95v1.825h.825q.95 0 1.613.675.662.675.662 1.6v9.85q0 .95-.662 1.613-.663.662-1.613.662Zm0-2.275h11.85v-9.85H6.075v9.85ZM12 16.975q.825 0 1.413-.588Q14 15.8 14 14.975t-.587-1.413q-.588-.587-1.413-.587-.825 0-1.412.587Q10 14.15 10 14.975q0 .825.588 1.412.587.588 1.412.588Zm-2.825-9.2h5.65V5.95q0-1.175-.825-2t-2-.825q-1.175 0-2 .825t-.825 2ZM6.075 19.9v-9.85 9.85Z"
        fill={fill}
      />
    </svg>
  );
};

LockIcon.defaultProps = {
  fill: '#000000',
};
