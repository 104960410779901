import * as React from 'react';
import { StyledAutoCompletePaper } from './AutoCompleteInput.styled';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AutoCompletePaper = (props: any) => {
  const { className, children } = props as {
    className: string;
    children:
      | Array<React.ReactNode>
      | {
          props: {
            children: Array<React.ReactNode>;
          };
        };
  };

  const childrenArray = Array.isArray(children) ? children : children?.props?.children;

  const isEmpty = childrenArray?.every?.((child) => child === null);

  if (isEmpty) {
    return null;
  }

  return <StyledAutoCompletePaper className={className}>{children as React.ReactNode}</StyledAutoCompletePaper>;
};
