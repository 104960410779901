import styled, { css } from 'styled-components';

export const StyledDropZone = styled.div``;

const ACTIVE_CSS = css`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%231EA660FF' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;

export const StyledDropArea = styled.div<{
  $isFocused: boolean;
  $disabled: boolean;
}>`
  width: 100%;
  height: 150px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23919EABFF' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 45px;
    margin-bottom: 14px;
  }

  &:hover {
    ${(props) => !props.$disabled && ACTIVE_CSS};
  }

  ${(props) => props.$isFocused && ACTIVE_CSS};
`;
