import { SnackbarProviderProps } from 'notistack';
import { Snackbar } from 'src/components/atoms';

// in this directory to prevent circular dependency
export const SNACKBAR_PROVIDE_PROPS: Omit<SnackbarProviderProps, 'children'> = {
  maxSnack: 5,
  autoHideDuration: 5000, // 5s

  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  preventDuplicate: true,
  dense: true,
  Components: {
    default: Snackbar,
    error: Snackbar,
    info: Snackbar,
    success: Snackbar,
    warning: Snackbar,
  },
};
