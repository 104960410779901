import debounce from 'lodash/debounce';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import type { NoteData } from 'src/types';

const useInstallerNoteStore = createWithEqualityFn<{
  notes: NoteData | null;
}>(
  () => ({
    notes: null,
  }),
  shallow
);

export const useGetLocalInstallerNotes = () => useInstallerNoteStore((state) => state.notes);

export const updateInstallerNoteFn = debounce((notes?: NoteData) => useInstallerNoteStore.setState({ notes }), 500);

export const clearInstallerLocalNotes = () => useInstallerNoteStore.setState({ notes: null });
