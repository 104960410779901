/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unknown-property */
import { Command } from 'cmdk';
import { Backdrop } from 'src/components/atoms/Backdrop';
import { VisionrEmblem } from 'src/assets/icons';
import { CommandMenuPagesGroup } from './CommandMenuPagesGroup';
import { CommandDialog } from './CommandMenu.styled';
import { useCommandMenu } from './hooks/useCommandMenu';
import { CommandMenuSystemsGroup } from './CommandMenuSystemsGroup';
import { CommandMenuOrgViewerGroup } from './CommandMenuOrgViewerGroup';
import { ViewUserDetailsGroup } from './ViewUserDetailsGroup';

export const CommandMenu = () => {
  const { t, open, setOpen, onPageSelect, onSystemsShortcutSelect } = useCommandMenu();
  return (
    <>
      <CommandDialog open={open} onOpenChange={setOpen} id="global-command-menu">
        <Command.Input autoFocus placeholder={t('placeholder.cmdk.search')} />
        <div cmdk-divider="" />
        <Command.List>
          <Command.Empty>{t('cmdk.emptyState')}.</Command.Empty>

          <CommandMenuPagesGroup onPageSelect={onPageSelect} />

          <CommandMenuSystemsGroup onSelect={onSystemsShortcutSelect} />

          <CommandMenuOrgViewerGroup setOpen={setOpen} />

          <ViewUserDetailsGroup setOpen={setOpen} />
        </Command.List>

        <div cmdk-footer="">
          <VisionrEmblem size={22} />
        </div>
      </CommandDialog>

      <Backdrop visible={open} onClick={() => setOpen(false)} $zIndex={9999} />
    </>
  );
};
