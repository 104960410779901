export const BOX_COLORS = {
  new: 'rgba(56, 176, 0, 0.7)',
  new_selected: 'rgba(112, 224, 0, 0.7)',
  new_border: 'rgba(56, 176, 0, 0.7)',
  previous: 'rgba(232, 93, 4, 0.7)',
  previous_selected: 'rgba(244, 140, 6, 0.7)',
  previous_border: 'rgba(232, 93, 4, 0.7)',
  rollback: 'rgba(141, 48, 255, 0.7)',
  rollback_selected: 'rgba(95, 28, 176, 0.7)',
  rollback_border: 'rgba(255, 0, 0, 0.7)',
} as const;
