import styled, { type DefaultTheme, css } from 'styled-components';
import MuiFormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import { hexToRgba } from 'src/utils/helpers';
import { SNACKBAR_COLORS } from '../../Snackbar/constant';

export const FormControl = styled(MuiFormControl)``;

export interface SelectInputProps {
  $isDirty?: boolean;
}
export const Select = styled(MuiSelect)<SelectInputProps>`
  &.MuiOutlinedInput-root {
    color: ${(props) => props.theme.colors.textColor};
    box-shadow: none;
    transition:
      background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    svg {
      path {
        fill: ${(props) => props.theme.colors.grey1};
      }
    }

    &:hover {
      background-color: ${(props) => hexToRgba(props.theme.colors.primary, 0.15)};

      .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.colors.primary};
      }
    }

    &.MuiInputBase-adornedStart {
      .MuiSelect-select {
        padding-left: 28px;
      }
    }
  }

  .MuiSelect-select {
    padding-right: 36px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;

    svg {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
  }

  &.Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }

  // small input
  &.MuiInputBase-sizeSmall {
    height: 42px;

    & .MuiSelect-select {
      padding: 10px 16px;
      padding-right: 36px;
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => (props?.$isDirty ? SNACKBAR_COLORS.warning : props.theme.colors.borderColor)};
    border-width: 1px;
  }

  .MuiSelect-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    right: 16px;
    transform: translateY(-2px) rotate(0deg);
    transition: all
      ${(props) => `${props.theme.animation.duration.fast} ${props.theme.animation.cubicBezier.animateOutFast}`};

    &.MuiSelect-iconOpen {
      transform: translateY(-3px) rotate(180deg);
    }
  }

  & .renderValue {
    margin-right: 16px;
    display: flex;
    align-items: center;
  }

  & .renderValue__tag {
    margin-right: 4px;
    margin-left: 4px;
    background-color: ${(props) => hexToRgba(props.theme.colors.primaryHover, 0.15)};
    border-radius: 6px;
    padding: 0px 4px;
    font-size: 12px;
    color: ${(props) => props.theme.colors.primaryDark};
  }
`;
export interface SelectAdornmentWrapperProps {
  $left?: string;
  $right?: string;
  $iconFill?: string;
}
export const SelectAdornmentWrapper = styled.div<SelectAdornmentWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  position: absolute;
  left: ${(props) => props.$left};
  right: ${(props) => props.$right};

  ${(props) =>
    props.$iconFill &&
    css`
      svg path {
        fill: ${props.$iconFill} !important;
      }
    `}
`;

/** Menu is rendered in portal and cant be styled with styled-components */
export const MENU_SX = (theme: DefaultTheme) => ({
  '& .MuiPaper-root': {
    borderRadius: '4px',
    marginTop: '6px',
    backgroundColor: `${theme.colors.appBackground}`,
    boxShadow: `${theme.boxShadow.large}`,
    padding: '0 8px',
    border: `1px solid ${theme.colors.borderColor}`,
    maxHeight: '300px',
  },
});
