import * as React from 'react';

export const EposUploadIcon: React.FunctionComponent = () => {
  return (
    <svg width="22" height="20" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33124 6.33154C1.33124 5.77926 1.77895 5.33154 2.33124 5.33154H15.6742C16.2264 5.33154 16.6742 5.77926 16.6742 6.33154V13.6707C16.6742 14.223 16.2264 14.6707 15.6742 14.6707H2.33124C1.77895 14.6707 1.33124 14.223 1.33124 13.6707V6.33154Z"
        stroke="currentColor"
      />
      <path
        d="M2.66272 3.99741C2.66272 3.62899 2.96138 3.33032 3.3298 3.33032H14.6702C15.0386 3.33032 15.3373 3.62899 15.3373 3.99741"
        stroke="currentColor"
      />
      <path
        d="M3.99689 1.99618C3.99689 1.62776 4.29555 1.3291 4.66397 1.3291H13.3361C13.7045 1.3291 14.0031 1.62776 14.0031 1.99618"
        stroke="currentColor"
      />
      <path d="M1.33124 9.0005L5.0002 5.33154" stroke="currentColor" />
      <path d="M16.6741 9.0005L13.0052 5.33154" stroke="currentColor" />
      <path d="M1.33124 11.0017L5.0002 14.6707" stroke="currentColor" />
      <path d="M16.6741 11.0017L13.0052 14.6707" stroke="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0027 12.3361C10.2922 12.3361 11.3375 11.2908 11.3375 10.0013C11.3375 8.71183 10.2922 7.6665 9.0027 7.6665C7.71323 7.6665 6.66791 8.71183 6.66791 10.0013C6.66791 11.2908 7.71323 12.3361 9.0027 12.3361Z"
        stroke="currentColor"
      />
      <path d="M3.99957 10.0012H4.66666" stroke="currentColor" />
      <path d="M13.3387 10.0012H14.0058" stroke="currentColor" />
    </svg>
  );
};
