import * as React from 'react';

export interface UnLockedIconProps {
  fill?: string;
}

export const UnLockedIcon: React.FunctionComponent<UnLockedIconProps> = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="22" width="24">
      <path
        d="M6.075 22.175q-.95 0-1.612-.662Q3.8 20.85 3.8 19.9v-9.85q0-.95.663-1.612.662-.663 1.612-.663h8.75V5.95q0-1.2-.825-2.012-.825-.813-2-.813-.975 0-1.688.55-.712.55-.987 1.4-.175.4-.475.637-.3.238-.65.238-.575 0-.913-.388-.337-.387-.187-.912Q7.5 3 8.85 1.925 10.2.85 12 .85q2.125 0 3.613 1.475Q17.1 3.8 17.1 5.95v1.825h.825q.95 0 1.613.663.662.662.662 1.612v9.85q0 .95-.662 1.613-.663.662-1.613.662Zm0-2.275h11.85v-9.85H6.075v9.85ZM12 16.975q.825 0 1.413-.588Q14 15.8 14 14.975t-.587-1.413q-.588-.587-1.413-.587-.825 0-1.412.587Q10 14.15 10 14.975q0 .825.588 1.412.587.588 1.412.588ZM6.075 10.05v9.85-9.85Z"
        fill={fill}
      />
    </svg>
  );
};

UnLockedIcon.defaultProps = {
  fill: '#000000',
};
