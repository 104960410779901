import * as React from 'react';
import { StyledDarkModeSwitcher, StyledDrawerActions } from './Actions.styled';
import { DarkModeSwitcher } from '../../atoms';
import { NavBarComponentsCommonProps } from '../common';
import { StyledListItem } from '../Links/Links.styled';
import { ProfileBar } from './ProfileBar';

export type NavBarComponentsCommonPropsProps = NavBarComponentsCommonProps;

export const NavBarActions: React.FunctionComponent<NavBarComponentsCommonPropsProps> = ({
  menuIsOpen,
  toggleMenu,
}) => {
  return (
    <StyledDrawerActions>
      <StyledListItem>
        <StyledDarkModeSwitcher $menuIsOpen={menuIsOpen}>
          <DarkModeSwitcher />
        </StyledDarkModeSwitcher>
      </StyledListItem>

      <StyledListItem $empty>&nbsp;</StyledListItem>
      <StyledListItem>
        <ProfileBar menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
      </StyledListItem>
    </StyledDrawerActions>
  );
};
