import { useGetUserDetails } from '../use-get-user-details';

export const useIsLoggedIn = () => {
  const { isLoggedIn, isLoading } = useGetUserDetails();

  const checkIsLoggedIn = !isLoading && isLoggedIn;

  return {
    isLoggedIn: checkIsLoggedIn,
    isAuthenticating: isLoading,
  };
};
