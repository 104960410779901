import * as React from 'react';

export const ConfirmIcon: React.FunctionComponent = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.47468 5.45798C4.19086 5.45798 3.96062 5.2209 4.00565 4.94068C4.27871 3.24141 5.57505 2 7.82403 2C10.074 2 11.5911 3.36066 11.5911 5.21531C11.5911 6.55864 10.9263 7.5033 9.8013 8.18796C8.70187 8.84662 8.38653 9.30595 8.38653 10.1986V10.2273C8.38653 10.5034 8.16267 10.7273 7.88653 10.7273H7.11622C6.84247 10.7273 6.61962 10.5071 6.61626 10.2334L6.6138 10.0339C6.57119 8.81195 7.09108 8.03196 8.25869 7.3213C9.28994 6.68863 9.65642 6.18597 9.65642 5.29331C9.65642 4.31398 8.89789 3.59466 7.73028 3.59466C6.72137 3.59466 6.01976 4.12397 5.79223 4.99661C5.72583 5.25126 5.51448 5.45798 5.25132 5.45798H4.47468ZM7.49602 14C8.11821 14 8.59108 13.5264 8.59108 12.9091C8.59108 12.2918 8.11821 11.8182 7.49602 11.8182C6.89042 11.8182 6.40926 12.2918 6.40926 12.9091C6.40926 13.5264 6.89042 14 7.49602 14Z"
        fill="currentColor"
      />
    </svg>
  );
};
