import { useTheme } from 'styled-components';
import Autocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { COLORS } from 'src/constants';
import { CircularProgress } from '../../Progress/CircularProgress';
import { TextInputProps } from '../text-input';
import { StyledTextFields } from '../common';
import { AutoCompletePaper } from './AutoCompletePaper';
import { SNACKBAR_COLORS } from '../../Snackbar/constant';
import { P16 } from '../../Typography/P16';

export interface AutoCompleteInputProps<TOption>
  extends Pick<TextInputProps, 'type' | 'placeholder' | 'value'>,
    Pick<
      MuiAutocompleteProps<TOption, false, false, true>,
      'options' | 'onInputChange' | 'open' | 'loading' | 'isOptionEqualToValue'
    > {
  id: string;
  /**
   * Array<string> or Array<{label: string, ...}>
   */
  options: Array<TOption>;
  /**
   * If you want to group the autocomplete options, you can use the groupBy prop.
   */
  groupByFn?: (t: TOption) => string;
  /**
   * Autocomplete Default is (option: TOption | string) => (typeof option === 'string' ? option : option?.label)
   */
  getOptionLabelFn?: (t: TOption | string) => string;
  isDirty?: boolean;
  greyLabel?: boolean;
  label?: string;
  defaultValue?: string;
}

export const AutoCompleteInput = <TOption,>({
  options,
  id,
  groupByFn,
  getOptionLabelFn,
  placeholder,
  onInputChange,
  value,
  isDirty,
  greyLabel,
  label,
  loading,
  isOptionEqualToValue,
  defaultValue,
}: AutoCompleteInputProps<TOption>) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div>
      {isEmpty(label) ? null : (
        <P16
          as="label"
          $margin="0 0 6px 0"
          $display="block"
          $fontWeight={500}
          {...(greyLabel
            ? {
                $color: COLORS.grey1,
              }
            : {})}
          {...(isDirty
            ? {
                $color: SNACKBAR_COLORS.warning,
              }
            : {})}
        >
          {t(label as string)}
        </P16>
      )}
      <Autocomplete
        data-cy="autocomplete-input"
        freeSolo
        id={id}
        options={options}
        groupBy={groupByFn}
        getOptionLabel={getOptionLabelFn}
        onInputChange={onInputChange}
        value={value}
        loading={loading}
        isOptionEqualToValue={isOptionEqualToValue}
        defaultValue={defaultValue}
        renderInput={(params) => {
          return (
            <StyledTextFields
              {...params}
              type="text"
              placeholder={t(placeholder)}
              size="small"
              $isDirty={isDirty}
              InputProps={{
                ...(params.InputProps || {}),
                endAdornment: (
                  <>
                    {loading ? <CircularProgress $color={theme.colors.textColor} size={16} thickness={6} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          );
        }}
        renderGroup={(params) => {
          return (
            <div key={params.key}>
              <P16 className="autocomplete-group">{params.group}</P16>
              <P16 className="autocomplete-group-item">{params.children}</P16>
            </div>
          );
        }}
        PaperComponent={AutoCompletePaper}
      />
    </div>
  );
};

AutoCompleteInput.defaultProps = {
  getOptionLabelFn: undefined,
  groupByFn: undefined,
  isDirty: false,
  greyLabel: false,
  label: '',
  defaultValue: '',
};
