import styled from 'styled-components';
import MuiDialog from '@mui/material/Dialog';

export interface StyledDialogProps {
  $backdropColor?: string;
}

export const Dialog = styled(MuiDialog)<StyledDialogProps>`
  & .MuiBackdrop-root {
    background-color: ${(props) => props.$backdropColor || props.theme.colors.backdropColor};
  }
  & .MuiDialog-paper {
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.appBackground};
    box-shadow: ${(props) => props.theme.boxShadow.small};
    border: 1px solid ${(props) => props.theme.colors.borderColor};
  }
`;
