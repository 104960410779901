import styled from 'styled-components';
import { hexToRgba } from 'src/utils';

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  flex-shrink: 0;
  position: relative;
  width: 18px;
  height: 18px;
  border: 1px solid ${({ theme }) => hexToRgba(theme.colors.grey2, 0.4)};
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.appBackground};
  transition: background 175ms ease-out;
  transform: scale(1.1);
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 2px;
    left: 5px;
    width: 6px;
    height: 10px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.primaryDark};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    border-color: ${({ theme }) => theme.colors.primaryDark};
    box-shadow: 0 0 2px 1px ${({ theme }) => hexToRgba(theme.colors.primaryDark, 0.2)};
    &::before {
      opacity: 1;
    }
  }
`;
