import * as React from 'react';

export interface ChevronRightIconProps {
  size?: number;
  fill?: string;
}

export const ChevronRightIcon: React.FunctionComponent<ChevronRightIconProps> = ({ size, fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} width={size}>
      <path
        d="M13.175 17.375q-.325-.325-.312-.813.012-.487.337-.812l2.6-2.6H4.975q-.475 0-.813-.338-.337-.337-.337-.812 0-.475.337-.812.338-.338.813-.338H15.8l-2.625-2.625q-.325-.325-.325-.8 0-.475.325-.8.325-.35.8-.35.475 0 .825.35l4.575 4.575q.15.175.225.375.075.2.075.425 0 .225-.075.425-.075.2-.225.375l-4.6 4.6q-.325.325-.8.325-.475 0-.8-.35Z"
        fill={fill}
      />
    </svg>
  );
};

ChevronRightIcon.defaultProps = {
  size: 24,
  fill: '#919EAB',
};
