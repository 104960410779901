import styled, { css } from 'styled-components';

const COMMON_CSS = css`
  position: absolute;
  font-size: 12px !important;
  font-weight: 600;
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.textColor};
`;

export const StyledNorth = styled.div`
  ${COMMON_CSS};
  top: 6px;
  left: 50%;
  transform: translateX(-50%);

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: ${(props) => props.theme.colors.textColor} transparent transparent transparent;
    transform: rotate(180deg);
    top: -2px;
  }
`;
export const StyledSouth = styled.div`
  ${COMMON_CSS};
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
`;
export const StyledEast = styled.div`
  ${COMMON_CSS};
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
`;
export const StyledWest = styled.div`
  ${COMMON_CSS};
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
`;
