import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, ROUTE_ICONS, UI_ROUTES } from 'src/constants/routes';
import { UseMenuToggleResult } from '../hooks';
import { StyledList, StyledListItem, StyledNavLink, StyledNavLinkLabel, StyledNavLinkIcon } from './Links.styled';
import { NavBarComponentsCommonProps } from '../common';

interface IDashboardLinksFormat {
  path: (typeof UI_ROUTES)[Routes];
  label: string;
  search?: string;
}

interface DashboardLinks {
  dashboardLinks: IDashboardLinksFormat[];
}

export type LinksProps = DashboardLinks &
  Omit<NavBarComponentsCommonProps, 'toggleMenu'> &
  Pick<UseMenuToggleResult, 'handleCloseMobileMenu'>;

export const Links: React.FunctionComponent<LinksProps> = ({ menuIsOpen, dashboardLinks, handleCloseMobileMenu }) => {
  const { t } = useTranslation();

  return (
    <StyledList>
      {dashboardLinks.map(({ path, search, label }) => {
        const Icon = ROUTE_ICONS[path];
        return (
          <StyledListItem key={`${path}${label}`}>
            <StyledNavLink
              to={{
                pathname: path,
                search: search || '',
              }}
              onClick={handleCloseMobileMenu}
            >
              <StyledNavLinkIcon>
                <Icon />
              </StyledNavLinkIcon>
              <StyledNavLinkLabel $menuIsOpen={menuIsOpen}>{t(label)}</StyledNavLinkLabel>
            </StyledNavLink>
          </StyledListItem>
        );
      })}
    </StyledList>
  );
};
